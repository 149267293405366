import { observable, configure } from 'mobx';
import { TableStoreBase, TableData } from './BaseTableStore';

import {
    getManufacturers,
    createManufacturer,
    updateManufacturer,
    removeManufacturer,
} from '../services/manufacturers';
import { ColumnProps } from '../typings/table';
configure({ enforceActions: 'observed' });

export interface ManufacturerProps {
    [key: string]: any;
    name: string;
    is_own: boolean;
    is_manufacture_default: boolean;
}

export interface ManufacturersDataProps extends TableData {
    items?: ManufacturerProps[];
}

export class ManufacturersStore extends TableStoreBase {
    constructor() {
        super('manufacturers');

        this.getData = getManufacturers;
        this.removeItem = removeManufacturer;
        this.updateItem = updateManufacturer;
        this.createItem = createManufacturer;
    }

    protected CAN_NOT_REMOVE =
        'Нельзя удалить некоторых производителей, так как они не пустые или участвовавали в транзакциях.';

    @observable data: ManufacturersDataProps = {};

    @observable columns: ColumnProps[] = [
        { field: 'name', title: 'Наименование', sortable: true, width: '100%' },
        {
            field: 'is_own',
            title: '',
            type: 'boolean',
            dataParams: {
                trueProps: {
                    borderRadius: 6,
                    backgroundColor: 'rgba(10, 30, 230, .1)',
                    border: '0',
                    color: '#23d',
                    fontWeight: 600,
                },
                trueValue: 'Свой',
                falseValue: '',
            },
        },
        {
            field: 'is_manufacture_default',
            title: '',
            type: 'boolean',
            dataParams: {
                trueProps: {
                    borderRadius: 6,
                    backgroundColor: 'rgba(10, 230, 30, .1)',
                    border: '0',
                    color: '#2d3',
                    fontWeight: 600,
                },
                trueValue: 'По-умолчанию при производстве',
                falseValue: '',
            },
        },
    ];

    @observable pageLimit: number = 1;
}

export const manufacturersStore = new ManufacturersStore();

import React, { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Alert, Tabs, Tab, Box, Chip } from '@mui/material';

import { TableItem } from '../../../typings/table';

import { getProducts } from '../../../services/products';

import { InputText } from '../../../components/Inputs/Text';
import { InputPhone } from '../../../components/Inputs/Phone';
import { InputNumber } from '../../../components/Inputs';
import { DividerWithTitle } from '../../../components/DividerWithTitle';
import { FormikValues, useFormikContext } from 'formik';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    currentTab: number;
}

// Обёртку над полями ввода надо выносить из родительского компонента. Иначе у полей теряется фокус!
const TabPanel = (props: TabPanelProps) => {
    const { children, currentTab, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={currentTab !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {currentTab === index && <Box p={3}>{children}</Box>}
        </div>
    );
};

const useStyles = makeStyles(() => ({
    chip: {
        margin: 2,
    },
}));

export const EditForm: FC = () => {
    const classes = useStyles();
    const [currentTab, setCurrentTab] = useState(0);
    const [products, setProducts] = useState([]);
    const [error, setError] = useState('');

    const { values, isValid } = useFormikContext<FormikValues>();

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
        setError('');
    };

    useEffect(() => {
        setError('');
    }, []);

    useEffect(() => {
        if (!isValid) {
            setError('Некорректные данные в полях формы');
        } else if (error === 'Некорректные данные в полях формы') {
            setError('');
        }
    }, [isValid, error]);

    useEffect(() => {
        getProducts({ filterData: { suppliers: { id: [values.id] } } }).then((data) => {
            if (data.rows) {
                setProducts(data.rows);
            }
        });
    }, [setProducts, values.id]);

    return (
        <form>
            <Tabs variant="fullWidth" value={currentTab} onChange={handleChangeTab} aria-label="nav tabs example">
                <Tab label="Общее" />
                <Tab label="Контакты" />
            </Tabs>
            <TabPanel currentTab={currentTab} index={0}>
                <InputText name="name" label="Название" fullWidth required />
                <InputNumber name="inn" label="ИНН" fullWidth maxLength={12} placeholder="123456789012" />
                <DividerWithTitle>Дополнительно</DividerWithTitle>
                <InputText name="comments" rows={3} multiline label="Комментарий" fullWidth />
            </TabPanel>
            <TabPanel currentTab={currentTab} index={1}>
                <InputPhone name="phone" label="Контактный телефон" fullWidth />
                <InputText name="email" label="Почта" fullWidth />
                <DividerWithTitle>Контакное лицо</DividerWithTitle>
                <InputText name="last_name" label="Фамилия" fullWidth />
                <InputText name="first_name" label="Имя" fullWidth />
                <InputText name="patronymic_name" label="Отчество" fullWidth />
            </TabPanel>
            <TabPanel currentTab={currentTab} index={2}>
                {products.length
                    ? products.map((product: TableItem) => (
                          <React.Fragment key={product.id}>
                              <Link
                                  target="_blank"
                                  to={`/dashboard/product/${product.id}`}
                                  onClick={(e) => e.stopPropagation()}
                              >
                                  <Chip
                                      size="medium"
                                      variant="outlined"
                                      key={product.id}
                                      label={product.name}
                                      className={classes.chip}
                                  />
                              </Link>
                          </React.Fragment>
                      ))
                    : 'У данного поставщика нет информации по продуктам'}
            </TabPanel>
            {error && (
                <Alert severity="error" style={{ margin: '4px 0' }}>
                    {error}
                </Alert>
            )}
        </form>
    );
};

import React from 'react';
import { FC } from 'react';

import { observer } from 'mobx-react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { InputNumber } from '../../../components/Inputs';
import { getLocalNumber } from '../../../utils/getLocalNumber';
import { appStore } from '../../../store/AppStore';
import { CellConfigurator } from '../../../components/ModuleTable/Cell';
import { groupByCategories } from '../../../services/inventories';
import { InventoryProductItem } from '../../../store/InventoriesStore';

interface BalancesListProps {
    isCompleted: boolean;
    balances: any[];
}

export const BalancesList: FC<BalancesListProps> = observer(({ balances, isCompleted }) => {
    const categories = groupByCategories(balances);

    return (
        <React.Fragment>
            {balances.length <= 0 ? (
                <Typography variant="body1">Список позиций инвентаризации пуст.</Typography>
            ) : (
                <TableContainer>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Продукт</TableCell>
                                <TableCell>Дата поступления</TableCell>
                                <TableCell>Дата производства</TableCell>
                                <TableCell>Лот</TableCell>
                                <TableCell>Производитель</TableCell>
                                <TableCell>Себестоимость единицы</TableCell>
                                <TableCell>Количество</TableCell>
                                <TableCell>Фактическое количество</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.from(categories.keys()).map((categoryId: number) => {
                                const category = categories.get(categoryId);
                                return (
                                    <React.Fragment key={categoryId}>
                                        <TableRow>
                                            <TableCell colSpan={8} component="th">
                                                <b>{category?.name}</b>
                                            </TableCell>
                                        </TableRow>
                                        <ProductsList products={category!.products} isCompleted={isCompleted} />
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </React.Fragment>
    );
});

interface ProductsListProps {
    isCompleted: boolean;
    products: Map<number, InventoryProductItem>;
}

const ProductsList: FC<ProductsListProps> = observer(({ products, isCompleted }) => {
    return (
        <React.Fragment>
            {Array.from(products.keys()).map((productId) => {
                let balanceRowCount = 0;
                let balancesMeasureName: string = '';
                const product = products.get(productId);
                const balances = product!.balances;

                const balancesSum = balances.reduce(
                    (sum: number, balance: any) => sum + Number(balance.initial_balance),
                    0,
                );

                const balancesInventorySum = balances.reduce(
                    (sum: number, balance: any) =>
                        sum + (isNaN(Number(balance.inventory_balance)) ? 0 : Number(balance.inventory_balance)),
                    0,
                );

                return balances.map((balance: any) => {
                    balancesMeasureName = balance.measure_name;
                    balanceRowCount++;
                    const fieldName = `balances.${balance.index}.inventory_balance`;
                    return (
                        <React.Fragment key={balance.index}>
                            <TableRow>
                                {balanceRowCount === 1 ? (
                                    <TableCell rowSpan={balances.length + 1}>
                                        {product!.name} [{product!.sku}]
                                    </TableCell>
                                ) : null}
                                <CellConfigurator value={balance.balance_created_at} type="date" item={balance} />
                                <CellConfigurator value={balance.balance_manufactured_at} type="date" item={balance} />
                                <TableCell>{balance.balance_lot}</TableCell>
                                <TableCell>{balance.manufacturer_name}</TableCell>
                                <CellConfigurator
                                    value={balance.balance_mcost}
                                    type="withDashAndPrice"
                                    item={balance}
                                />
                                <TableCell>
                                    {getLocalNumber(balance.initial_balance, {
                                        isFullDigits: appStore.isFullDigits,
                                    })}{' '}
                                    {balance.measure_name}
                                </TableCell>
                                <TableCell>
                                    {!isCompleted ? (
                                        <InputNumber textFieldSize="small" label={''} name={fieldName} />
                                    ) : (
                                        getLocalNumber(balance.inventory_balance, {
                                            isFullDigits: appStore.isFullDigits,
                                        })
                                    )}
                                </TableCell>
                            </TableRow>
                            {balanceRowCount === balances.length ? (
                                <TableRow key={balance.index}>
                                    <TableCell colSpan={5}></TableCell>
                                    <TableCell>
                                        <b>
                                            {getLocalNumber(balancesSum, {
                                                isFullDigits: appStore.isFullDigits,
                                            })}{' '}
                                            {balancesMeasureName}
                                        </b>
                                    </TableCell>
                                    <TableCell>
                                        <b>
                                            {getLocalNumber(balancesInventorySum, {
                                                isFullDigits: appStore.isFullDigits,
                                            })}{' '}
                                            {balancesMeasureName}
                                        </b>
                                    </TableCell>
                                </TableRow>
                            ) : null}
                        </React.Fragment>
                    );
                });
            })}
        </React.Fragment>
    );
});

import { getAuthDataFromLocalStorage } from './getAuthDataFromLocalStorage';

export const getAuthHeader = (): Record<string, string> => {
    const currentUser = getAuthDataFromLocalStorage();

    if (currentUser && currentUser.key) {
        return { Authorization: `Bearer ${currentUser.key}` };
    } else {
        return {};
    }
};

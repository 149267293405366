import { ServiceBase } from './serviceBase';

const { getData, create, update, remove } = new ServiceBase({ path: 'manufacturers' });

export {
    getData as getManufacturers,
    create as createManufacturer,
    update as updateManufacturer,
    remove as removeManufacturer,
};

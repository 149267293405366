import { observable, configure } from 'mobx';
import { TableStoreBase, TableData } from './BaseTableStore';

import { createProject, removeProject, updateProject, getProjectProducts, getProject } from '../services/projects';
import { ColumnProps } from '../typings/table';

configure({ enforceActions: 'observed' });

export interface ProjectProps {
    [key: string]: any;
    id: number | string;
    number: string;
    name: string;
    description: string;
    developer_id: number;
    developer_first_name: string;
    developer_last_name: string;
    developer_patronymic_name: string;
    project_product_number: string;
    product_name: string;
    product_comments: string;
}

export interface IProjects extends TableData {
    items?: ProjectProps[];
}

export class ProjectsStore extends TableStoreBase {
    constructor() {
        super('Projects');
        this.getData = getProjectProducts;
        this.getItem = getProject;
        this.removeItem = removeProject;
        this.updateItem = updateProject;
        this.createItem = createProject;
    }

    @observable columns: ColumnProps[] = [
        { field: 'number', title: 'Номер проекта', type: 'withPad', padSize: 2, sortable: true },
        { field: 'name', title: 'Название', sortable: true },
        { field: 'description', title: 'Описание проекта', sortable: true },
        { field: 'developer_last_name', title: 'Разработчик', sortable: true, type: 'developer' },
        { field: 'project_product_number', title: 'Номер продукта', type: 'withPad', padSize: 2, sortable: true },
        { field: 'product_name', title: 'Название продукта', sortable: true },
        { field: 'project_product_description', title: 'Описание продукта', sortable: true, multiline: true },
    ];

    @observable data: IProjects = {};
}

export const projectsStore = new ProjectsStore();

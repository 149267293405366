import { FC, useState, useCallback, ChangeEvent, useEffect } from 'react';

import { Alert, Button, CardMedia, Tabs, Tab, Box } from '@mui/material';

import { ComponentsForm } from './ComponentsForm';
import { SuppliersForm } from './SuppliersForm';

import { InputText, SelectWithDictionary } from '../../../components/Inputs';

import { getImageUrl } from '../../../utils/getImageUrl';
import { FormikValues, useFormikContext } from 'formik';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

// Обёртку над полями ввода надо выносить из родительского компонента. Иначе у полей теряется фокус!
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

export const EditForm: FC = () => {
    const { values, setFieldValue, isValid } = useFormikContext<FormikValues>();
    const { measure_quantity, measure_name } = values;
    const [photoUrl, setPhotoUrl] = useState(values.photo);
    const [error, setError] = useState('');
    const [currentTab, setCurrentTab] = useState(0);
    // const [currentMeasure, setCurrentMeasure] = useState('');

    const triggerOnChangePhoto = useCallback(
        (value: any) => {
            setPhotoUrl(value.data);
            setFieldValue('photo', { data: value.data, name: value.name }, false);
        },
        [setFieldValue],
    );

    const onUploadPhoto = useCallback(
        (event: ChangeEvent) => {
            // @ts-ignore
            const file = event.target.files[0];

            setError('');

            if (file) {
                let fr = new FileReader();
                fr.onload = (e) => {
                    triggerOnChangePhoto({
                        name: file.name,
                        size: file.size,
                        type: file.type,
                        // @ts-ignore
                        data: e.target.result,
                    });
                };
                fr.onerror = () => {
                    setError('Ошибка при загрузке файла');
                    triggerOnChangePhoto(null);
                };
                fr.readAsDataURL(file);
            }
        },
        [triggerOnChangePhoto],
    );

    const removePhoto = useCallback(() => {
        setPhotoUrl('');

        setFieldValue('photo', null, false);
    }, [setFieldValue]);

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
    };

    useEffect(() => {
        setCurrentTab(0);
        setError('');
    }, []);

    useEffect(() => {
        if (!isValid) {
            setError('Некорректные данные в полях формы');
        } else if (error === 'Некорректные данные в полях формы') {
            setError('');
        }
    }, [isValid, error]);

    useEffect(() => {
        if (values.measure_id !== 3) {
            setFieldValue('measure_quantity', 1000);
        } else {
            setFieldValue('measure_quantity', 1);
        }
    }, [values.measure_id, setFieldValue]);

    const selectedMeasureCallback = useCallback(
        (selected: any) => {
            // setCurrentMeasure(selected?.name || '');
            setFieldValue('measure_name', selected?.name || '');
        },
        [/*setCurrentMeasure, */ setFieldValue],
    );

    return (
        <form style={{ width: 550 }}>
            <Tabs variant="fullWidth" value={currentTab} onChange={handleChangeTab} aria-label="nav tabs example">
                <Tab label="Общее" />
                <Tab label="Поставщики" />
                <Tab label="Состав" />
            </Tabs>
            <TabPanel value={currentTab} index={0}>
                {photoUrl ? (
                    <CardMedia
                        style={{ margin: '8px auto' }}
                        component="img"
                        alt="Фотография продукта"
                        image={getImageUrl(photoUrl)}
                        title="Фотография продукта"
                    />
                ) : null}
                <Button variant="contained" component="label" style={{ margin: '8px 0' }}>
                    Загрузить фотографию
                    <input id="photo" name="photo" type="file" accept="image/*" hidden onChange={onUploadPhoto} />
                </Button>
                {photoUrl ? (
                    <Button variant="contained" style={{ margin: '8px 4px' }} onClick={removePhoto}>
                        Удалить фотографию
                    </Button>
                ) : null}
                <InputText name="sku" label="УИП" fullWidth required />
                <InputText name="name" label="Название" fullWidth required />
                <SelectWithDictionary name="category_id" type="categories" label="Категория" required fullWidth />
                <SelectWithDictionary
                    name="measure_id"
                    type="measures"
                    label="Единица учета"
                    selectedCallback={selectedMeasureCallback}
                    required
                    fullWidth
                />
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
                <SuppliersForm />
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
                <Alert
                    severity="info"
                    style={{ margin: '8px 0 4px' }}
                >{`Рецепт продукта на ${measure_quantity} ${measure_name}`}</Alert>
                <ComponentsForm />
            </TabPanel>
            {error && (
                <Alert severity="error" style={{ margin: '4px 0' }}>
                    {error}
                </Alert>
            )}
        </form>
    );
};

import { FilterParamsProps } from '../typings/table';

export const getDataRequestParams = ({ page, limit, order, orderDir, filterData }: FilterParamsProps) => {
    return {
        offset: page && limit ? page * limit : 0,
        limit: limit ? limit : undefined,
        order,
        orderDir,
        filterData,
    };
};

import { FC } from 'react';
import * as yup from 'yup';

import { PageWithTable } from '../../components/Page/withTable/PageWithTable';
import { EditForm } from './EditForm';

import { getShortName } from '../../utils/getShortName';
import { employeesStore } from '../../store/EmployeesStore';

interface EmployeesPageProps {
    title?: string;
}

const initialValues = {
    photo: null,
    email: '',
    phone: '',
    first_name: '',
    patronymic_name: '',
    last_name: '',
    job: '',
};

const validationSchema = yup.object({
    first_name: yup.string().required('Имя обязателено'),
    last_name: yup.string().required('Фамилия обязательна'),
    patronymic_name: yup.string(),
    job: yup.string().required('Должность обязательна'),
    phone: yup.string().required('Телефон обязателен'),
    email: yup.string().email('Некорректно указана почта').required('Почта обязательна'),
});

export const EmployeesPage: FC<EmployeesPageProps> = ({ title }) => {
    return (
        <>
            <PageWithTable
                title={title}
                getModalTitle={(item?) =>
                    getShortName(`${item?.last_name} ${item?.first_name} ${item?.patronymic_name}`)
                }
                store={employeesStore}
                EditForm={EditForm}
                validationSchema={validationSchema}
                initialValues={initialValues}
                disableColumnsPanel
            />
        </>
    );
};

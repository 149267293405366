import { FC } from 'react';

import { FormSelectWithDataProps, SelectWithData } from './SelectWithData';

export const StocksManualSelect: FC<FormSelectWithDataProps> = ({
    filterParams = { is_manufacture: false },
    label = 'Склад',
    emptyText = 'Нет доступных складов',
    ...props
}) => <SelectWithData filterParams={filterParams} label={label} emptyText={emptyText} {...props} />;

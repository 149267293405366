import { FC } from 'react';
import _ from 'lodash';
import { TextField, TextFieldProps } from '@mui/material';
import { FormikValues, useFormikContext } from 'formik';

type InputTextProps = TextFieldProps & {
    name: string;
    label: string;
};

export const InputText: FC<InputTextProps> = ({ name, label, value, onChange, error: errorValue, ...props }) => {
    const { values, errors, handleChange } = useFormikContext<FormikValues>();

    const currentValue = _.get(values, name);
    const currentError = _.get(errors, name);

    return (
        <TextField
            name={name}
            id={name}
            label={label}
            value={currentValue || ''}
            error={Boolean(currentError)}
            helperText={currentError ? String(currentError) : undefined}
            onChange={handleChange}
            {...props}
        />
    );
};

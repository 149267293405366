import { PARAMS_PROP } from '../const/localStorage';

export const getCommonParamsLocalStorage = () => {
    let data = {};
    let localStorageData = localStorage.getItem(PARAMS_PROP);

    if (localStorageData) {
        try {
            data = JSON.parse(localStorageData);
        } catch {}
    }

    return data;
};

export const updateCommonParamsLocalStorage = (type: string, data: any) => {
    const params = getCommonParamsLocalStorage();

    // @ts-ignore
    localStorage.setItem(PARAMS_PROP, JSON.stringify({ ...params, [type]: { ...params[type], ...data } }));
};

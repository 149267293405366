import { FC, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import {
    FormControl,
    FormHelperText,
    Chip,
    CircularProgress,
    IconButton,
    Tooltip,
    Autocomplete as AutocompleteMaterial,
    AutocompleteProps,
    TextField,
} from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { FormikValues, useFormikContext } from 'formik';

interface ItemProps {
    id: any;
    value?: any;
    name: string;
}

interface MyAutocompleteProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
    myProp?: string;
}

function MyAutocomplete<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
>(props: MyAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
    return <AutocompleteMaterial {...props} />;
}

const useStyles = makeStyles(() => ({
    menuPaper: {
        maxHeight: 300,
    },
    formControl: {},
    formControlWrapper: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        maxHeight: 200,
    },
    autocomplete: {
        '& > .MuiFormControl-root': {
            margin: 0,
        },
    },
    loading: {
        margin: 8,
    },
    refreshButton: {
        height: 30,
        width: 30,
        margin: '0 8px',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
}));

export interface FormSelectProps {
    name: string;
    label?: React.ReactNode;
    isLoading?: boolean;
    isLoaded?: boolean;
    items?: ItemProps[];
    errorData?: string;
    onRefresh?: () => void;
    withoutRefresh?: boolean;
    disableEmpty?: boolean;
    menuItem?: any;
    emptyText?: string;
    required?: boolean;
    fullWidth?: boolean;
    multiple?: boolean;
    style?: any;
    disabled?: boolean;
}

export const AutocompleteMultiple: FC<FormSelectProps> = ({
    name,
    label,
    items = [],
    fullWidth,
    required,
    isLoading,
    isLoaded,
    errorData,
    onRefresh,
    withoutRefresh,
    menuItem,
    emptyText = 'Пусто',
    disableEmpty,
    disabled,
    ...props
}) => {
    const classes = useStyles();

    const { values, errors, setFieldValue } = useFormikContext<FormikValues>();

    const currentError = _.get(errors, name);
    const currentValues = useMemo(() => _.get(values, name) || [], [values, name]);

    const autocmpleteValues = useMemo(
        () => items.filter((item) => currentValues.indexOf(item.id) > -1),
        [items, currentValues],
    );

    return (
        <FormControl
            variant="outlined"
            fullWidth={fullWidth}
            error={Boolean(currentError)}
            className={classes.formControl}
            {...props}
        >
            <div className={classes.formControlWrapper}>
                <MyAutocomplete
                    id={name}
                    disabled={disabled}
                    className={classes.autocomplete}
                    options={items}
                    filterSelectedOptions
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                    fullWidth
                    loading={isLoading}
                    multiple
                    value={autocmpleteValues}
                    onChange={(_, newValue) => {
                        let val = newValue;

                        if (!Array.isArray(newValue)) {
                            val = newValue ? [newValue] : [];
                        }

                        setFieldValue(
                            name,
                            (val as ItemProps[]).map((item) => item.id),
                        );
                    }}
                    style={{
                        margin: 0,
                    }}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                name={name}
                                label={label}
                                error={Boolean(currentError)}
                                required={required}
                                InputProps={{
                                    ...params.InputProps,
                                    style: {
                                        margin: 0,
                                    },
                                    endAdornment: (
                                        <>
                                            {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        );
                    }}
                    renderOption={(props, item) => {
                        if (menuItem) {
                            return <li {...props}>{menuItem(item)}</li>;
                        }

                        return <li {...props}>{item.name}</li>;
                    }}
                    renderTags={(options, getTagProps) => {
                        return options.map((value: ItemProps, index: number) => {
                            return (
                                <Chip variant="outlined" size="small" label={value?.name} {...getTagProps({ index })} />
                            );
                        });
                    }}
                />
                {!withoutRefresh && onRefresh ? (
                    <Tooltip title="Обновить список" arrow placement="bottom">
                        <IconButton size="small" className={classes.refreshButton} onClick={onRefresh}>
                            <Refresh />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </div>
            {Boolean(currentError) && <FormHelperText error>{String(currentError)}</FormHelperText>}
        </FormControl>
    );
};

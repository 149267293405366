import { FC } from 'react';
import { TableCell } from '@mui/material';

import { Transaction } from '../../Format';

interface CellWithTransactionProps {
    type: number;
}

export const CellWithTransaction: FC<CellWithTransactionProps> = ({ type }) => {
    return (
        <TableCell>
            <Transaction type={type} />
        </TableCell>
    );
};

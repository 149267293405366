import { FC } from 'react';
import _ from 'lodash';

import { Checkbox as CheckboxMaterial, CheckboxProps, FormControlLabel, FormHelperText } from '@mui/material';
import { FormikValues, useFormikContext } from 'formik';

interface FormCheckboxProps extends CheckboxProps {
    name: string;
    label?: string;
    error?: string;
}

export const Checkbox: FC<FormCheckboxProps> = ({ name, label, value, onChange, ...props }) => {
    const { values, errors, handleChange } = useFormikContext<FormikValues>();

    const currentValue = value === undefined ? _.get(values, name) : value;
    const currentError = _.get(errors, name);

    return (
        <>
            <FormControlLabel
                control={
                    <CheckboxMaterial
                        checked={Boolean(currentValue)}
                        onChange={onChange || handleChange}
                        name={name}
                        color="primary"
                        {...props}
                    />
                }
                label={label}
            />
            {currentError ? <FormHelperText error>{String(currentError)}</FormHelperText> : null}
        </>
    );
};

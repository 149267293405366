import { observable, action, configure } from 'mobx';

import { getNavState } from '../utils/getNavState';
import { setNavState } from '../utils/setNavState';

configure({ enforceActions: 'observed' });

export class AppStore {
    @observable isNavOpen = true;
    @observable isFullDigits = false;

    constructor() {
        const navState = getNavState();

        if (typeof navState === 'undefined') {
            setNavState(true);
        } else {
            this.isNavOpen = navState;
        }
    }

    @action toggleFullDigits = (): void => {
        this.isFullDigits = !this.isFullDigits;
    };

    @action toggleNavigation = (): void => {
        setNavState(!this.isNavOpen);

        this.isNavOpen = !this.isNavOpen;
    };
}

export const appStore = new AppStore();

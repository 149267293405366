import { request } from './request';
import { FilterParamsProps } from '../typings/table';
import { getDataRequestParams } from '../utils/getDataRequestParams';

class DictionariesService {
    path = 'dictionaries';

    getDictionaries = (params: FilterParamsProps) => {
        const requestParams = getDataRequestParams(params);

        return request(this.path, requestParams, 'POST');
    };
}

const { getDictionaries } = new DictionariesService();

export { getDictionaries };

import { FC, useMemo, useCallback, useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import { FormikValues, FieldArray, useFormikContext } from 'formik';
import { Button, Paper, List, ListItem, ListItemText, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ButtonWithRemove } from '../../../components/Button/withRemove/ButtonWithRemove';
import { DividerWithTitle } from '../../../components/DividerWithTitle';
import { InputNumber } from '../../../components/Inputs/Number';
import { SelectWithData, SelectWithDictionary } from '../../../components/Inputs';
import { getLocalNumber } from '../../../utils/getLocalNumber';

interface ComponentFromProps {
    index: number;
}

const useStyles = makeStyles(() => ({
    contentBase: {
        margin: '12px 0',
        borderRadius: 6,
        backgroundColor: '#f5f5f5',
    },
    contentWithItems: {
        padding: 12,
        border: '1px solid rgb(200, 200, 200)',
    },
    itemCard: {
        padding: '24px 12px',
        margin: '12px 0',
    },
    list: {
        padding: 0,
    },
    listItem: {
        paddingTop: 4,
        paddingBottom: 4,
    },
    listTitle: {
        fontWeight: 'bold',
        marginRight: 4,
    },
}));

const ComponentEditForm: FC<ComponentFromProps> = ({ index }) => {
    const [currentMeasure, setCurrentMeasure] = useState('');
    const { setFieldValue, values } = useFormikContext<FormikValues>();
    const currentCategoryId = values.components[index].component_category_id;
    const filterParams = useMemo(
        () => ({
            category_id: currentCategoryId,
        }),
        [currentCategoryId],
    );

    const { measure_quantity, components, measure_name } = values;

    const selectedCategoryCallback = useCallback(
        (selected: any) => {
            if (!selected) {
                setFieldValue(`components.${index}.component_id`, '');
            }
        },
        [setFieldValue, index],
    );

    const selectedCallback = useCallback(
        (selected: any) => {
            if (selected?.category_id) {
                setFieldValue(`components.${index}.component_category_id`, selected?.category_id);
            }

            setCurrentMeasure(selected?.measure_name || '');
        },
        [setFieldValue, index],
    );

    useEffect(() => {
        setFieldValue(
            `components.${index}.quantity`,
            measure_quantity && components[index].quantity_temp
                ? components[index].quantity_temp / measure_quantity
                : 0,
        );
    }, [components, measure_quantity, index, setFieldValue]);

    return (
        <>
            <SelectWithDictionary
                name={`components.${index}.component_category_id`}
                type="categories"
                label="Категория"
                selectedCallback={selectedCategoryCallback}
                fullWidth
            />
            <SelectWithData
                name={`components.${index}.component_id`}
                type="products"
                label="Продукт"
                filterParams={filterParams}
                selectedCallback={selectedCallback}
                fullWidth
            />
            <InputNumber
                name={`components.${index}.quantity_temp`}
                label="Количество"
                measure={currentMeasure}
                isShort
                fullWidth
                required
            />
            {components[index].quantity ? (
                <Typography variant="body2">
                    <em>{`(на 1 ${measure_name} продукта – ${components[index].quantity} ${currentMeasure})`}</em>
                </Typography>
            ) : null}
        </>
    );
};

const ComponentForm: FC<ComponentFromProps> = ({ index }) => {
    const classes = useStyles();
    const { setFieldValue, values } = useFormikContext<FormikValues>();

    const { measure_quantity, components } = values;

    const onChangeState = useCallback(() => {
        setFieldValue(`components.${index}.isEdit`, true);

        setFieldValue(
            `components.${index}.quantity_temp`,
            measure_quantity && components[index].quantity ? components[index].quantity * measure_quantity : 0,
        );
    }, [setFieldValue, index, measure_quantity, components]);

    return (
        <List className={classes.list}>
            <ListItem className={classes.listItem}>
                <ListItemText>{components[index].component_name || '–'}</ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
                <span className={classes.listTitle}>Количество:</span>
                <ListItemText>
                    {getLocalNumber(components[index].quantity * measure_quantity)}{' '}
                    {components[index].component_measure_name}
                </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Button size="small" variant="outlined" onClick={onChangeState}>
                    Редактировать
                </Button>
            </ListItem>
        </List>
    );
};

export const ComponentsForm: FC = () => {
    const contentRef = useRef<HTMLDivElement>(null);
    const classes = useStyles();
    const { values } = useFormikContext<FormikValues>();
    const components = values?.components || [];

    return (
        <FieldArray name="components">
            {({ remove, push }) => {
                return (
                    <>
                        <div
                            className={clsx(components.length ? classes.contentWithItems : '', classes.contentBase)}
                            ref={contentRef}
                        >
                            {components.map((component: any, index: number) => {
                                return (
                                    <Paper
                                        elevation={3}
                                        key={component.id || `component-${index}`}
                                        className={classes.itemCard}
                                    >
                                        <DividerWithTitle>
                                            {index + 1}{' '}
                                            {component.isEdit ? (
                                                <ButtonWithRemove buttonText="" onRemove={() => remove(index)} />
                                            ) : null}
                                        </DividerWithTitle>
                                        {component.isEdit ? (
                                            <ComponentEditForm index={index} />
                                        ) : (
                                            <ComponentForm index={index} />
                                        )}
                                    </Paper>
                                );
                            })}
                        </div>
                        <Button
                            variant="contained"
                            onClick={() => {
                                push({ isEdit: true });
                                setTimeout(
                                    () => contentRef?.current?.scrollTo({ top: contentRef?.current?.scrollHeight }),
                                    100,
                                );
                            }}
                        >
                            Добавить новый элемент в состав
                        </Button>
                    </>
                );
            }}
        </FieldArray>
    );
};

import { observable, configure } from 'mobx';
import { TableStoreBase, TableData } from './BaseTableStore';

import { getSuppliers, createSupplier, updateSupplier, removeSuppliers } from '../services/suppliers';
import { ColumnProps } from '../typings/table';

configure({ enforceActions: 'observed' });

export interface SupplierProps {
    [key: string]: any;
    id: number | string;
    is_disable: boolean;
    created_at: string;
    email: string;
    login: string;
    name: string;
}

export interface SuppliersDataProps extends TableData {
    items?: SupplierProps[];
}

export class SuppliersStore extends TableStoreBase {
    constructor() {
        super('suppliers');

        this.itemPageLink = '/dashboard/supplier';

        this.filterParams.order = 'name';
        this.filterParams.orderDir = 'asc';

        this.getData = getSuppliers;
        this.removeItem = removeSuppliers;
        this.updateItem = updateSupplier;
        this.createItem = createSupplier;
    }

    @observable columns: ColumnProps[] = [
        { field: 'name', title: 'Название', sortable: true },
        { field: 'last_name', title: 'Фамилия', sortable: true },
        { field: 'first_name', title: 'Имя' },
        { field: 'patronymic_name', title: 'Отчество' },
        { field: 'inn', title: 'ИНН', sortable: true },
        { field: 'phone', title: 'Телефон' },
        { field: 'email', title: 'E-mail', sortable: true },
    ];

    @observable data: SuppliersDataProps = {};

    @observable pageLimit: number = 1;
}

export const suppliersStore = new SuppliersStore();

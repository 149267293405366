import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { List, ListItem, Typography, Card } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

import { Page, PageProps } from '../Page';
import { appStore } from '../../../store/AppStore';
import { getBalance } from '../../../services/transactions';
import { getLocalNumber } from '../../../utils/getLocalNumber';

const useStyles = makeStyles(() => ({
    pageContainer: {
        overflow: 'auto',
    },
    table: {
        backgroundColor: '#fff',
    },
    cardLink: {
        textDecoration: 'none',
        '&:hover > div': {
            boxShadow: '0 0 4px 4px rgba(40,40,40,.2)',
        },
    },
    card: {
        margin: 12,
        overflow: 'visible',
    },
    listItem: {
        paddingTop: 4,
        paddingBottom: 4,
    },
    listTitle: {
        fontWeight: 'bold',
        marginRight: 4,
    },
    lastCell: {
        borderBottom: '1px solid #333',
    },
    pageSubtitle: {
        margin: '12px',
    },
}));

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

/**
 * Состояние баланса
 */
export const PageWithBalance: FC<PageProps> = observer((props) => {
    const classes = useStyles();
    const { isFullDigits } = appStore;
    const query = useQuery();

    const balanceId = query.get('balance') || '';

    const [currentBalance, setCurrentBalance] = useState<any>(null);

    useEffect(() => {
        getBalance(balanceId).then((data) => {
            if (data.data) {
                // TODO: сделать нормальное получение конкретного баланса
                setCurrentBalance(data.data);
            }
        });
    }, [balanceId]);

    if (!currentBalance) {
        return <h4>Загрузка данных...</h4>;
    }

    const { parents } = currentBalance;

    const { stock_name: parentStockName, balance: parentStockBalance, quantity: parentStockQuantity } = parents[0];

    return (
        <Page {...props} title={`${currentBalance.product_name}`} withPaper={false}>
            <div className={classes.pageContainer}>
                <Card className={classes.card}>
                    <List>
                        <ListItem>
                            <Typography variant="body1">
                                <span className={classes.listTitle}>Количество:</span>{' '}
                                {`${getLocalNumber(currentBalance.quantity, { isFullDigits })} ${
                                    currentBalance.measure_name
                                }`}
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <span className={classes.listTitle}>Склад:</span> {currentBalance.stock_name || '–'}
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <span className={classes.listTitle}>Дата записи на текущем складе:</span>{' '}
                                {currentBalance.created_at
                                    ? moment(currentBalance.created_at).format('DD.MM.YYYY')
                                    : '–'}
                            </Typography>
                        </ListItem>
                    </List>
                </Card>
                <Typography variant="h5" className={classes.pageSubtitle}>
                    Партия
                </Typography>
                <Card className={classes.card}>
                    <List>
                        <ListItem>
                            <Typography variant="body1">
                                <span className={classes.listTitle}>Склад партии:</span> {parentStockName || '–'}
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1" variantMapping={{ body1: 'div' }}>
                                <span className={classes.listTitle}>Остаток партии на складе:</span>{' '}
                                {parentStockBalance
                                    ? `${getLocalNumber(parentStockBalance, { isFullDigits })} ${
                                          currentBalance.measure_name
                                      }`
                                    : '–'}
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1" variantMapping={{ body1: 'div' }}>
                                <span className={classes.listTitle}>Исходное количество партии на складе:</span>{' '}
                                {parentStockBalance
                                    ? `${getLocalNumber(parentStockQuantity, { isFullDigits })} ${
                                          currentBalance.measure_name
                                      }`
                                    : '–'}
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1" variantMapping={{ body1: 'div' }}>
                                <span className={classes.listTitle}>Поставщик:</span>{' '}
                                {currentBalance.supplier_name || '–'}
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <span className={classes.listTitle}>Номер счёта:</span> {currentBalance.invoice || '–'}
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <span className={classes.listTitle}>Дата счёта:</span>{' '}
                                {currentBalance.invoice_on
                                    ? moment(currentBalance.invoice_on).format('DD.MM.YYYY')
                                    : '–'}
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1" variantMapping={{ body1: 'div' }}>
                                <span className={classes.listTitle}>Себестоимость единицы:</span>{' '}
                                {currentBalance.mcost || '0'}
                                {' р.'}
                            </Typography>
                        </ListItem>
                    </List>
                </Card>
            </div>
        </Page>
    );
});

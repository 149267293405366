import { FC, useEffect, useState } from 'react';

import { useParams, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import {
    List,
    ListItem,
    Card,
    Typography,
    Table,
    TableContainer,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

import { Page, PageProps } from '../Page';

import { ManufactureStatus } from '../../Format';
import { CellConfigurator } from '../../ModuleTable/Cell';

import { getManufactures } from '../../../services/manufacture';
import { TableItem, ColumnProps } from '../../../typings/table';
import { getShortName } from '../../../utils/getShortName';
import { getLocalNumber } from '../../../utils/getLocalNumber';
import { appStore } from '../../../store/AppStore';

const useStyles = makeStyles(() => ({
    page: {
        height: '100%',
        margin: '0 12px',
        overflow: 'auto',
    },
    tableContainer: {
        paddingRight: 24,
        boxSizing: 'border-box',
    },
    row: {
        textDecoration: 'none',
        '&:hover': {
            background: '#f5f5f5',
        },
    },
    table: {
        backgroundColor: '#fff',
    },
    cardLink: {
        textDecoration: 'none',
        '&:hover > div': {
            boxShadow: '0 0 4px 4px rgba(40,40,40,.2)',
        },
    },
    card: {
        margin: '12px 0',
    },
    listItem: {
        paddingTop: 4,
        paddingBottom: 4,
    },
    listTitle: {
        fontWeight: 'bold',
        marginRight: 4,
    },
    pageSubtitle: {
        margin: '12px',
    },
}));

const columns = [
    { field: 'product_sku', title: 'УИП' },
    { field: 'product_name', title: 'Продукт' },
    { field: 'quantity', title: 'Количество' },
    { field: 'lot', title: 'Лот' },
    { field: 'manufacturer_name', title: 'Производитель' },
    { field: 'description', title: 'Описание' },
    { field: 'balance_mcost', title: 'Себестоимость единицы' },
];

export const PageWithManufacture: FC<PageProps> = observer((props) => {
    const classes = useStyles();
    const { isFullDigits } = appStore;
    // @ts-ignore
    const { id } = useParams();

    const [current, setCurrent] = useState<TableItem>({} as TableItem);

    const items = current.components || [];
    const produced = current.produced?.filter((item: TableItem) => Number(item.quantity) > 0) || [];
    const writeOff = current.produced?.filter((item: TableItem) => Number(item.quantity) < 0) || [];
    const isContractorStock = items.find((component: any) => component.stock_is_contractor);
    const stockName = items?.[0]?.stock_name;

    useEffect(() => {
        getManufactures({ filterData: { id: id } }).then((data) => {
            if (data.rows?.[0]) {
                setCurrent(data.rows[0]);
            }
        });
    }, [id]);

    const fullItemMcost = items.reduce(
        (sum: number, item: any) => (sum += Number(item.balance_mcost * item.quantity) || 0),
        0,
    );

    if (!current.id) {
        return <Typography>Загрузка данных...</Typography>;
    }

    return (
        <Page
            {...props}
            title={`Производство: ${current.product_sku} – ${current.product_name}, ${getLocalNumber(current.quantity, {
                isFullDigits,
            })} ${current.measure_name} – ${
                current.completed_at ? `${moment(current.completed_at).format('DD.MM.YYYY')}` : 'Неизвестная дата'
            }`}
            withPaper={false}
        >
            <div className={classes.page}>
                <Card className={classes.card}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <List>
                                <ListItem>
                                    <Typography variant="body1" variantMapping={{ body1: 'div' }}>
                                        <span className={classes.listTitle}>Статус:</span>{' '}
                                        <ManufactureStatus type={current.status_id} status={current.status_name} />
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <span className={classes.listTitle}>Категория:</span>{' '}
                                        {current.category_name || '–'}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <span className={classes.listTitle}>УИП:</span> {current.product_sku || '–'}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <span className={classes.listTitle}>Продукт:</span>{' '}
                                        {current.product_name || '–'}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <span className={classes.listTitle}>Лот:</span> {current.lot || '–'}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <span className={classes.listTitle}>Количество:</span>{' '}
                                        {`${getLocalNumber(current.quantity, { isFullDigits })} ${
                                            current.measure_name
                                        }`}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <span className={classes.listTitle}>Ответственный:</span>{' '}
                                        {current.employee_id
                                            ? getShortName(
                                                  `${current.employee_last_name} ${current.employee_first_name} ${current.employee_patronymic_name}`,
                                              )
                                            : '–'}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <span className={classes.listTitle}>Комментарий:</span>{' '}
                                        {current.comments || '–'}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <span className={classes.listTitle}>Автор записи:</span>{' '}
                                        {current.created_by_name || '–'}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <span className={classes.listTitle}>Дата начала:</span>
                                        {current.created_at
                                            ? `${moment(current.created_at).format('DD.MM.YYYY')}`
                                            : '–'}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <span className={classes.listTitle}>Дата окончания:</span>
                                        {current.completed_at
                                            ? `${moment(current.completed_at).format('DD.MM.YYYY')}`
                                            : '–'}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <span className={classes.listTitle}>Склад производства:</span>{' '}
                                        {stockName || '–'} {isContractorStock ? ' (Подрядчик)' : ''}
                                    </Typography>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={6}>
                            <List>
                                {current.manufacture_price && (
                                    <ListItem>
                                        <Typography variant="body1">
                                            <span className={classes.listTitle}>Стоимость производства:</span>{' '}
                                            {getLocalNumber(+current.manufacture_price, { isFullDigits })} {'р.'}
                                        </Typography>
                                    </ListItem>
                                )}
                                {
                                    <ListItem>
                                        <Typography variant="body1">
                                            <span className={classes.listTitle}>Стоимость произведенной партии:</span>{' '}
                                            {getLocalNumber(+(current.manufacture_price || 0) + fullItemMcost, {
                                                isFullDigits,
                                            }) || '–'}{' '}
                                            {'р.'}
                                        </Typography>
                                    </ListItem>
                                }
                                {
                                    <ListItem>
                                        <Typography variant="body1">
                                            <span className={classes.listTitle}>Стоимость произведенной единицы:</span>{' '}
                                            {getLocalNumber(
                                                (+(current.manufacture_price || 0) + fullItemMcost) / current.quantity,
                                                { isFullDigits },
                                            ) || '–'}{' '}
                                            {'р.'}
                                        </Typography>
                                    </ListItem>
                                }
                            </List>
                            {Boolean(produced?.length) && (
                                <>
                                    <Typography variant="h5" className={classes.pageSubtitle}>
                                        Склады отгрузки:
                                    </Typography>
                                    <TableContainer className={classes.tableContainer}>
                                        <Table stickyHeader className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell width="100%">
                                                        <span className={classes.listTitle}>Название</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className={classes.listTitle}>Количество</span>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {produced.map((item: TableItem) => {
                                                    return (
                                                        <>
                                                            <TableRow>
                                                                <TableCell>{item.stock_name}</TableCell>
                                                                <TableCell>{`${getLocalNumber(item.quantity, {
                                                                    isFullDigits,
                                                                })} ${item.measure_name}`}</TableCell>
                                                            </TableRow>
                                                        </>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                            )}
                            {Boolean(writeOff?.length) && (
                                <>
                                    <Typography variant="h5" className={classes.pageSubtitle}>
                                        Списание:
                                    </Typography>
                                    <TableContainer className={classes.tableContainer}>
                                        <Table stickyHeader className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell width="100%">
                                                        <span className={classes.listTitle}>Комментарий</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className={classes.listTitle}>Количество</span>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {writeOff.map((item: TableItem) => {
                                                    return (
                                                        <>
                                                            <TableRow>
                                                                <TableCell>{item.comments}</TableCell>
                                                                <TableCell>{`${getLocalNumber(item.quantity, {
                                                                    isFullDigits,
                                                                })} ${item.measure_name}`}</TableCell>
                                                            </TableRow>
                                                        </>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Card>
                {!!items?.length && (
                    <>
                        <Typography variant="h5" className={classes.pageSubtitle}>
                            Компоненты:
                        </Typography>
                        <TableContainer className={classes.card}>
                            <Table stickyHeader className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column: ColumnProps) => {
                                            if (column.hidden) {
                                                return null;
                                            }

                                            return (
                                                <TableCell align={column.align}>
                                                    <span className={classes.listTitle}>{column.title}</span>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items.map((item: TableItem) => {
                                        return (
                                            <TableRow
                                                key={item.id}
                                                component={Link}
                                                to={`/dashboard/balanceDetail?balance=${item.balance_id}`}
                                                target="_blank"
                                                className={classes.row}
                                            >
                                                <TableCell>{item.product_sku}</TableCell>
                                                <TableCell>{item.product_name}</TableCell>
                                                <CellConfigurator value={item.quantity} type="quantity" item={item} />
                                                <TableCell>
                                                    {item.balance_lot ||
                                                        (item.balance_manufactured_at &&
                                                            moment(item.balance_manufactured_at).format(
                                                                'DD.MM.YYYY',
                                                            )) ||
                                                        '–'}
                                                </TableCell>
                                                <TableCell>{item.manufacturer_name || '–'}</TableCell>
                                                <TableCell>{item.balance_description || '–'}</TableCell>
                                                <CellConfigurator
                                                    value={item.balance_mcost}
                                                    type="withDashAndPrice"
                                                    item={item}
                                                />
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}

                <br />
            </div>
        </Page>
    );
});

import { FC, useCallback, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { Field, FormikValues, FieldArray, useFormikContext } from 'formik';
import { Button, Paper, List, ListItem, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ButtonWithRemove } from '../../../components/Button/withRemove/ButtonWithRemove';
import { DividerWithTitle } from '../../../components/DividerWithTitle';
import { InputText } from '../../../components/Inputs/Text';
import { InputNumber } from '../../../components/Inputs/Number';
import { Checkbox } from '../../../components/Inputs';
import { SelectWithData } from '../../../components/Inputs';

interface SupplierFormProps {
    index: number;
    isMain?: boolean;
}

const useStyles = makeStyles(() => ({
    contentBase: {
        margin: '12px 0',
        borderRadius: 6,
        backgroundColor: '#f5f5f5',
    },
    contentWithItems: {
        padding: 12,
        border: '1px solid rgb(200, 200, 200)',
    },
    listTitle: {
        fontWeight: 'bold',
        marginRight: 4,
    },
}));

const SupplierEditForm: FC<SupplierFormProps> = ({ index, isMain }) => {
    const { setFieldValue, values } = useFormikContext<FormikValues>();
    const onChangeMain = useCallback(
        (e: any) => {
            const suppliers = values.suppliers;

            suppliers.forEach((supplier: any, _index: number) => {
                setFieldValue(`suppliers.${_index}.main`, e.target.checked && _index === index);
            });

            setFieldValue('supplier_id', e.target.checked ? suppliers[index].supplier_id : null);
        },
        [setFieldValue, values, index],
    );

    useEffect(() => {
        if (isMain) {
            setFieldValue(`suppliers.${index}.main`, true);
        }
    }, [isMain, setFieldValue, index]);

    return (
        <div>
            <SelectWithData name={`suppliers.${index}.supplier_id`} type="suppliers" label="Поставщик" fullWidth />
            <Field name={`suppliers.${index}.sku`}>
                {({ field, meta }: { form: any; field: any; meta: any }) => (
                    <InputText
                        name={`suppliers.${index}.sku`}
                        label="Артикул поставщика"
                        fullWidth
                        value={field.value}
                        error={meta.error}
                    />
                )}
            </Field>
            <Field name={`suppliers.${index}.delivery_days`}>
                {({ field, meta }: { form: any; field: any; meta: any }) => (
                    <InputNumber
                        name={`suppliers.${index}.delivery_days`}
                        label="Срок поставки (дней)"
                        fullWidth
                        value={field.value}
                        error={meta.error}
                    />
                )}
            </Field>
            <Checkbox name={`suppliers.${index}.main`} label="Главный" onChange={onChangeMain} />
        </div>
    );
};

const SupplierForm: FC<SupplierFormProps> = ({ index }) => {
    const classes = useStyles();

    const { values, setFieldValue } = useFormikContext<FormikValues>();

    const onChangeState = useCallback(() => {
        setFieldValue(`suppliers.${index}.isEdit`, true);
    }, [setFieldValue, index]);

    return (
        <List>
            <ListItem>
                <span className={classes.listTitle}>Название:</span>
                <ListItemText>{values.suppliers[index].supplier_name}</ListItemText>
            </ListItem>
            <ListItem>
                <span className={classes.listTitle}>Артикул:</span>
                <ListItemText>{values.suppliers[index].sku || '–'}</ListItemText>
            </ListItem>
            <ListItem>
                <span className={classes.listTitle}>Срок поставки (дней):</span>
                <ListItemText>{values.suppliers[index].delivery_days || '–'}</ListItemText>
            </ListItem>
            <ListItem>
                <Button size="small" variant="outlined" onClick={onChangeState}>
                    Редактировать
                </Button>
            </ListItem>
        </List>
    );
};

export const SuppliersForm: FC = () => {
    const contentRef = useRef<HTMLDivElement>(null);
    const classes = useStyles();
    const { values } = useFormikContext<FormikValues>();
    const suppliers = values?.suppliers || [];

    return (
        <FieldArray name="suppliers">
            {({ remove, push }) => {
                return (
                    <>
                        <div
                            className={clsx(suppliers.length ? classes.contentWithItems : '', classes.contentBase)}
                            ref={contentRef}
                        >
                            {suppliers.map((supplier: any, index: number) => {
                                const isMain = values.supplier_id && supplier.supplier_id === values.supplier_id;

                                return (
                                    <Paper
                                        elevation={3}
                                        key={supplier.id || `suppliers-${index}`}
                                        style={{
                                            padding: '24px 12px',
                                            margin: '18px 0',
                                            boxShadow: isMain
                                                ? '0 0 8px 6px rgba(33, 150, 240, .4), 0px 3px 3px -2px rgba(0, 0, 0, .2), 0px 3px 4px 0px rgba(0, 0, 0, .14), 0px 1px 8px 0px rgba(0, 0, 0, .12)'
                                                : '0px 3px 3px -2px rgba(0, 0, 0, .2), 0px 3px 4px 0px rgba(0, 0, 0, .14), 0px 1px 8px 0px rgba(0, 0, 0, .12)',
                                        }}
                                    >
                                        <DividerWithTitle>
                                            {index + 1} {!supplier.isEdit && isMain ? '(Основной)' : ''}
                                            {supplier.isEdit ? (
                                                <ButtonWithRemove buttonText="" onRemove={() => remove(index)} />
                                            ) : null}
                                        </DividerWithTitle>
                                        {supplier.isEdit ? (
                                            <SupplierEditForm index={index} isMain={isMain} />
                                        ) : (
                                            <SupplierForm index={index} />
                                        )}
                                    </Paper>
                                );
                            })}
                        </div>
                        <Button
                            variant="contained"
                            onClick={() => {
                                push({ isEdit: true });
                                setTimeout(
                                    () => contentRef?.current?.scrollTo({ top: contentRef?.current?.scrollHeight }),
                                    100,
                                );
                            }}
                        >
                            Указать поставщика продукта
                        </Button>
                        <div style={{ margin: 12 }} />
                        <Button href="suppliers?create" target="_blank">
                            Создать нового поставщика
                        </Button>
                    </>
                );
            }}
        </FieldArray>
    );
};

import { FC, useMemo } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { Button, Tooltip, Stack } from '@mui/material';
import { Search, TableChartOutlined } from '@mui/icons-material';

import { ButtonWithRemove } from '../../../Button/withRemove/ButtonWithRemove';

const useStyles = makeStyles((theme: Theme) => ({
    filterChanged: {
        backgroundColor: '#eea849',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#f46b45',
        },
    },
}));

interface ButtonsPanelProps {
    withRemoveButton?: boolean;
    withFilter?: boolean;
    withAdd?: boolean;
    handleOpenEditDialog: (data?: any) => void;
    handleRemove: () => void;
    handleFilter: () => void;
    handleColumns?: () => void;
    removeDisabled: boolean;
    filterChanged: boolean;
    filterOpen: boolean;
    withColumnsPanel: boolean;
}

export const ButtonsPanel: FC<ButtonsPanelProps> = ({
    withRemoveButton = true,
    handleOpenEditDialog,
    removeDisabled = false,
    handleRemove,
    handleFilter,
    handleColumns,
    withFilter,
    withAdd,
    filterChanged,
    filterOpen,
    withColumnsPanel,
}) => {
    const classes = useStyles();

    const filterTooltipProps: { open?: boolean } = useMemo(
        () => ({
            open: !filterOpen && filterChanged,
        }),
        [filterOpen, filterChanged],
    );

    return (
        <div>
            <Stack direction="row" spacing={1}>
                {withColumnsPanel ? (
                    <Tooltip title="Настройка столбцов" arrow placement="bottom">
                        <Button variant="contained" color="inherit" onClick={handleColumns}>
                            <TableChartOutlined />
                        </Button>
                    </Tooltip>
                ) : null}
                {withFilter ? (
                    <Tooltip
                        title={filterChanged ? 'Включён фильтр' : 'Фильтр'}
                        arrow
                        placement="top"
                        {...filterTooltipProps}
                    >
                        <Button
                            className={clsx({ [classes.filterChanged]: filterChanged })}
                            variant="contained"
                            color="inherit"
                            onClick={handleFilter}
                        >
                            <Search />
                        </Button>
                    </Tooltip>
                ) : null}
                {withAdd ? (
                    <Button variant="contained" color="primary" onClick={handleOpenEditDialog}>
                        Добавить
                    </Button>
                ) : null}
                {withRemoveButton ? <ButtonWithRemove onRemove={handleRemove} disabled={removeDisabled} /> : null}
            </Stack>
        </div>
    );
};

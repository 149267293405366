import { FC, PropsWithChildren } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    border: {
        borderBottom: '1px solid lightgray',
        width: '100%',
    },
    content: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        fontSize: 18,
        color: 'gray',
        whiteSpace: 'nowrap',
    },
}));

export const DividerWithTitle: FC<PropsWithChildren> = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.border} />
            <span className={classes.content}>{children}</span>
            <div className={classes.border} />
        </div>
    );
};

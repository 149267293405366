import { FC, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import {
    FormControl,
    FormHelperText,
    CircularProgress,
    IconButton,
    Tooltip,
    Autocomplete as AutocompleteMaterial,
    AutocompleteProps,
    TextField,
} from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { FormikValues, useFormikContext } from 'formik';

interface ItemProps {
    id: any;
    value?: any;
    name: string;
}

interface MyAutocompleteProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
    myProp?: string;
}

function MyAutocomplete<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
>(props: MyAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
    return <AutocompleteMaterial {...props} />;
}

const useStyles = makeStyles(() => ({
    menuPaper: {
        maxHeight: 300,
    },
    formControl: {},
    formControlWrapper: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        maxHeight: 200,
    },
    autocomplete: {
        '& > .MuiFormControl-root': {
            margin: 0,
        },
    },
    loading: {
        margin: 8,
    },
    refreshButton: {
        height: 30,
        width: 30,
        margin: '0 8px',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
}));

export interface FormSelectProps {
    name: string;
    label?: React.ReactNode;
    isLoading?: boolean;
    isLoaded?: boolean;
    items?: ItemProps[];
    errorData?: string;
    onRefresh?: () => void;
    withoutRefresh?: boolean;
    disableEmpty?: boolean;
    menuItem?: any;
    emptyText?: string;
    required?: boolean;
    disabled?: boolean;
    fullWidth?: boolean;
    multiple?: boolean;
    style?: any;
}

export const Autocomplete: FC<FormSelectProps> = ({
    name,
    label,
    items = [],
    fullWidth,
    required,
    disabled,
    isLoading,
    onRefresh,
    withoutRefresh,
    menuItem,
    style,
}) => {
    const classes = useStyles();

    const { values, errors, setFieldValue } = useFormikContext<FormikValues>();

    const currentValue = isLoading ? '' : _.get(values, name);
    const currentError = _.get(errors, name);

    const currentItem = items.find((item) => String(item.id) === String(currentValue));

    const [value, setValue] = useState<ItemProps | null>(null);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (isLoading) {
            setValue(null);
            setInputValue('');
        } else {
            setValue(currentItem || null);
            setInputValue(currentItem?.name || '');
        }
    }, [items, currentItem, isLoading]);

    return (
        <FormControl
            variant="outlined"
            fullWidth={fullWidth}
            error={Boolean(currentError)}
            className={classes.formControl}
            style={style}
        >
            <div className={classes.formControlWrapper}>
                <MyAutocomplete
                    id={name}
                    disabled={disabled}
                    className={classes.autocomplete}
                    options={items}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                    fullWidth
                    loading={isLoading}
                    value={value}
                    onChange={(_, newValue) => {
                        setValue(newValue as ItemProps);
                        setFieldValue(name, (newValue as ItemProps)?.id);
                    }}
                    style={{
                        margin: 0,
                    }}
                    inputValue={inputValue}
                    onInputChange={(_, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                name={name}
                                label={label}
                                error={Boolean(currentError)}
                                required={required}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <>
                                            {isLoading ? (
                                                <span style={{ opacity: 0.6, paddingLeft: 8, paddingRight: 8 }}>
                                                    Загружается...
                                                </span>
                                            ) : null}
                                            {isLoading ? (
                                                <CircularProgress color="inherit" size={20} style={{ opacity: 0.6 }} />
                                            ) : null}
                                            {params.InputProps.startAdornment}
                                        </>
                                    ),
                                }}
                            />
                        );
                    }}
                    renderOption={(renderProps, item) => (
                        <li {...renderProps} key={item?.id}>
                            {menuItem ? menuItem(item) : item.name}
                        </li>
                    )}
                />
                {!withoutRefresh && onRefresh ? (
                    <Tooltip title="Обновить список" arrow placement="bottom">
                        <IconButton size="small" className={classes.refreshButton} onClick={onRefresh}>
                            <Refresh />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </div>
            {Boolean(currentError) && <FormHelperText error>{String(currentError)}</FormHelperText>}
        </FormControl>
    );
};

import React, { FC, useMemo, useCallback, useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';

import { Alert, Table, TableContainer, TableBody, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { ModuleTableHead } from './Head/ModuleTable-Head';
import { TableBaseContent } from './TableBaseContent';

import { DirectionType, ColumnProps, TableItem } from '../../typings/table';
import { getErrorMessage } from '../../utils/getErrorMessage';

interface TableProps {
    columns: any;
    data?: TableItem[];
    selected?: string[];
    orderBy?: string;
    orderDirection?: DirectionType;
    canSelect?: boolean;
    withEditButton?: boolean;
    justOpenItem?: boolean;
    changeOrder?: (column: ColumnProps) => void;
    updateSelected?: (ids: string[]) => void;
    changeSelected?: (id: string) => void;
    handleEdit?: (data: any) => void;
    onRowClick?: (data: any) => void;
    onCellClick?: (data: any, field?: string) => void;
    getRecordKey?: (record: Record<string, any>) => string;
    loading?: boolean;
    page?: number;
    errors?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
    loader: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255,255,255,.7)',
        padding: 12,
        pointerEvents: 'none',
        boxSizing: 'border-box',
    },
    tableHelper: {
        display: 'flex',
        padding: theme.spacing(2),
    },
    tableContainer: {
        width: 'auto',
        height: '100%',
        maxWidth: '100%',
        // backgroundColor: '#f9f9f9',
        position: 'relative',
    },
    tableBody: {
        backgroundColor: '#fff',
    },
}));

export const ModuleTable: FC<TableProps> = observer(
    ({
        data = [],
        columns,
        orderDirection,
        orderBy,
        changeOrder,
        updateSelected = () => {},
        handleEdit,
        onRowClick,
        onCellClick,
        selected = [],
        changeSelected = () => {},
        getRecordKey,
        canSelect,
        withEditButton,
        justOpenItem,
        loading,
        page = 0,
        errors,
    }) => {
        const classes = useStyles();

        const onSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
                const newSelecteds = data.map((n) => String(n.id));

                updateSelected(newSelecteds);
            } else {
                updateSelected([]);
            }
        };

        const isAllSelected = useMemo(
            () => Boolean(selected.length) && selected.length === data.length,
            [selected, data],
        );

        const isSelected = useCallback((id: string) => selected.indexOf(id) !== -1, [selected]);

        const [showLoader, setShowLoader] = useState(false);

        const timerRef = useRef<number>();

        const progressLoading = showLoader || loading;

        const filteredColumns = columns.filter((column: ColumnProps) => {
            return !column.hidden;
        });

        useEffect(
            () => () => {
                clearTimeout(timerRef.current);
            },
            [],
        );

        const loader = useMemo(() => {
            if (data.length <= 0 || progressLoading) {
                // показываем лоадер минимум определенное время
                if (loading && !showLoader) {
                    setShowLoader(true);

                    timerRef.current = window.setTimeout(() => {
                        setShowLoader(false);
                    }, 700);
                }

                const message = errors ? (
                    <Alert severity="error" style={{ margin: 'auto' }}>
                        {getErrorMessage(errors)}
                    </Alert>
                ) : (
                    <Alert severity="info" style={{ margin: 'auto' }}>
                        {page > 0 ? 'Данных больше нет' : 'Данные отсутствуют'}
                    </Alert>
                );

                return (
                    <div className={classes.loader}>
                        {progressLoading ? <CircularProgress size={60} thickness={3} /> : null}
                        {!progressLoading ? <div className={classes.tableHelper}>{message}</div> : null}
                    </div>
                );
            }

            return null;
        }, [data, loading, page, classes, progressLoading, errors, showLoader]);

        return (
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader size="small" sx={{ width: 'auto' }}>
                    <ModuleTableHead
                        columns={filteredColumns}
                        orderBy={orderBy}
                        direction={orderDirection}
                        changeOrder={changeOrder}
                        onSelectAll={onSelectAll}
                        isAllSelected={isAllSelected}
                        canSelect={canSelect}
                        withEditButton={withEditButton}
                    />
                    <TableBody className={classes.tableBody}>
                        {Boolean(data?.length) ? (
                            <TableBaseContent
                                data={data}
                                columns={filteredColumns}
                                isSelected={isSelected}
                                changeSelected={changeSelected}
                                onRowClick={onRowClick}
                                onCellClick={onCellClick}
                                handleEdit={handleEdit}
                                canSelect={canSelect}
                                withEditButton={withEditButton}
                                justOpenItem={justOpenItem}
                                getRecordKey={getRecordKey}
                            />
                        ) : null}
                    </TableBody>
                </Table>
                {loader}
            </TableContainer>
        );
    },
);

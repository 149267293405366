import { noop } from 'lodash';
import { observable, action, configure, runInAction } from 'mobx';
import _ from 'lodash';

import { ColumnProps, FilterParamsProps, TableItem, FilterStateProps, ColumnsPanelState } from '../typings/table';
import { TableDataProps } from '../typings/api';
import { updateCommonParamsLocalStorage, getCommonParamsLocalStorage } from '../utils/commonParamsLocalStorage';

configure({ enforceActions: 'observed' });

export interface TableData {
    items?: Array<Record<string, any>>;
    errors?: any;
}

const params: Record<string, any> = getCommonParamsLocalStorage();

export class TableStoreBase {
    protected getData: (params: FilterParamsProps) => Promise<any> = async () => noop;

    protected getItem: (id: string) => Promise<any> = async () => undefined;

    protected createItem: (data?: any) => Promise<any> = async () => noop;

    protected updateItem: (id: string, data?: any) => Promise<any> = async () => noop;

    protected removeItem: (ids: string[]) => Promise<any> = async () => noop;

    public tableName: string | undefined;

    constructor(tableName: string = '') {
        this.tableName = tableName;

        runInAction(() => {
            // @ts-ignore
            if (!tableName || !params[tableName]) {
                return;
            }

            const tableParams = params[tableName];

            if (tableParams.limit) {
                this.filterParams.limit = tableParams.limit;
            }

            if (tableParams.orderDir) {
                this.filterParams.orderDir = tableParams.orderDir;
            }

            if (tableParams.order) {
                this.filterParams.order = tableParams.order;
            }

            if (!_.isEmpty(tableParams.filterData)) {
                this.filterParams.filterData = tableParams.filterData;
            }

            if (tableParams.isFilterChanged) {
                this.filterState.isChanged = tableParams.isFilterChanged;
            }

            if (tableParams.columnsState) {
                this.columns = this.columns.map((column) => {
                    column.hidden =
                        tableParams.columnsState.find((columnState: any) => columnState.field === column.field)
                            ?.hidden || false;

                    return column;
                });
            }
        });
    }

    public initialFilterData: Record<string, any> = {};

    public initialFilterParams: FilterParamsProps = {
        limit: 100,
        page: 0,
        filterData: this.initialFilterData,
    };

    public getLink?: (data: TableItem) => string | void;
    public getCellLink?: (data: TableItem, field?: string) => string | void;

    public itemPageLink: string = '';
    public primaryKeyField: string = 'id';

    protected CAN_NOT_REMOVE = 'Нельзя удалить некоторые записи, так как на них завязаны другие.';

    @observable messageInfo: string = '';

    @observable data: TableData = {};

    @observable selected: string[] = [];

    @observable filterParams: FilterParamsProps = this.initialFilterParams;

    @observable filterState: FilterStateProps = {
        isOpen: false,
        isChanged: !_.isEqual(this.filterParams.filterData, this.initialFilterData),
    };

    @observable columnsPanelState: ColumnsPanelState = {
        isOpen: false,
    };

    @observable loading: boolean = false;

    @observable columns: ColumnProps[] = [];

    @action setLoading = (loading: boolean) => {
        this.loading = loading;
    };

    @action toggleColumn = (fieldName: string, columns = this.columns) => {
        if (!columns.length) {
            return;
        }

        const currentIndex = columns.findIndex((column: ColumnProps) => column.field === fieldName);

        if (currentIndex === -1) {
            return;
        }

        columns[currentIndex].hidden = !columns[currentIndex].hidden;

        updateCommonParamsLocalStorage(`${this.tableName}`, {
            columnsState: columns.map((column) => ({
                field: column.field,
                hidden: column.hidden,
            })),
        });
    };

    @action showMessageInfo = (str: string) => {
        if (this.messageInfo && this.messageInfo !== str) {
            this.hideMessageInfo();

            setTimeout(() => (this.messageInfo = str), 1000);

            return;
        }

        this.messageInfo = str;

        setTimeout(() => this.hideMessageInfo(), 5000);
    };

    @action hideMessageInfo = () => {
        this.messageInfo = '';
    };

    @action toggleOpenFilter = () => {
        this.filterState.isOpen = !this.filterState.isOpen;
    };

    @action toggleColumnsPanel = () => {
        this.columnsPanelState.isOpen = !this.columnsPanelState.isOpen;
    };

    @action clearFilter = () => {
        this.filterState.isChanged = false;
        this.updateFilterParams({ filterData: this.initialFilterData });
    };

    @action changeSelected = (id: string) => {
        const selectedIndex = this.selected.indexOf(id);

        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = this.selected.concat(id);
        } else {
            newSelected = this.selected.filter((item) => item !== id);
        }

        this.updateSelected(newSelected);
    };

    @action generateModalTitle?: (item?: TableItem) => string = () => '';

    @action updateSelected = (ids: string[] = []) => {
        this.selected = ids;
    };

    @action updateFilterParams = (newParams: FilterParamsProps, isFilterSubmit?: boolean) => {
        this.filterParams = {
            ...this.filterParams,
            ...newParams,
        };

        if (isFilterSubmit) {
            this.filterParams.page = 0;
        }

        this.filterState.isChanged = !_.isEqual(this.filterParams.filterData, this.initialFilterData);

        // TODO: не хранить всё, а удалять то, что не бьётся с дефолтным состоянием
        updateCommonParamsLocalStorage(`${this.tableName}`, {
            limit: this.filterParams.limit,
            filterData: this.filterParams.filterData,
            order: this.filterParams.order,
            orderDir: this.filterParams.orderDir,
            isFilterChanged: this.filterState.isChanged,
        });

        this.loadData();
    };

    @action loadData = async () => {
        this.setLoading(true);
        this.data.errors = null;
        this.updateSelected([]);

        try {
            const data = await this.getData(this.filterParams);
            if (data && (data as TableDataProps).rows) {
                runInAction(() => {
                    this.data.items = (data as TableDataProps).rows;
                });
            }
            if (data && (data as TableDataProps).errors) {
                runInAction(() => {
                    this.data.errors = (data as TableDataProps).errors;
                });
            }
        } finally {
            this.setLoading(false);
        }
    };

    @action loadItem = async (itemId: string) => {
        this.setLoading(true);
        try {
            return await this.getItem(itemId);
        } finally {
            this.setLoading(false);
        }
    };

    @action update = (id: string, data: TableData) => {
        if (id) {
            return this.updateItem(id, data);
        }

        return this.createItem(data);
    };

    @action remove = (ids: string[]) => {
        return this.removeItem(ids);
    };

    @action removeSelected = () => {
        this.remove(this.selected).then((data: any) => {
            if (data?.deleteErrorIds.length > 0) {
                // ошибки при удалении
                this.showMessageInfo(this.CAN_NOT_REMOVE);
            }

            runInAction(() => {
                this.selected = [];

                this.loadData();
            });
        });
    };

    @action toggleOrderDirection = (): void => {
        if (!this.filterParams.orderDir) {
            this.filterParams.orderDir = 'asc';
        } else if (this.filterParams.orderDir === 'asc') {
            this.filterParams.orderDir = 'desc';
        } else if (this.filterParams.orderDir === 'desc') {
            this.filterParams.orderDir = undefined;
            this.filterParams.order = undefined;
        }

        updateCommonParamsLocalStorage(`${this.tableName}`, {
            order: this.filterParams.order,
            orderDir: this.filterParams.orderDir,
        });
    };

    @action changeOrder = (column: ColumnProps) => {
        if (
            (column.sort_field && column.sort_field === this.filterParams.order) ||
            column.field === this.filterParams.order
        ) {
            this.toggleOrderDirection();
        } else {
            this.filterParams.order = column.sort_field || column.field;
            this.filterParams.orderDir = 'asc';
        }

        updateCommonParamsLocalStorage(`${this.tableName}`, {
            order: this.filterParams.order,
            orderDir: this.filterParams.orderDir,
        });

        this.loadData();
    };

    initialColumns: ColumnProps[] = [];
}

import { FC } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import moment from 'moment';

import { BalanceProps } from '../../../store/BalanceStore';
import { getLocalNumber } from '../../../utils/getLocalNumber';
import { appStore } from '../../../store/AppStore';

interface BalanceMenuItemProps {
    item: BalanceProps;
}

const useStyles = makeStyles(() => ({
    listItem: {
        display: 'block',
        paddingTop: 4,
        paddingBottom: 4,
    },
    listTitle: {
        fontWeight: 'bold',
        marginRight: 4,
    },
}));

export const BalanceMenuItem: FC<BalanceMenuItemProps> = observer(({ item }) => {
    const isFullDigits = appStore.isFullDigits;
    const classes = useStyles();

    return (
        <>
            <Typography className={classes.listItem} variant="body1">
                <span className={classes.listTitle}>Продукт:</span> {item.product_name}
            </Typography>

            <Typography className={classes.listItem} variant="body1">
                <span className={classes.listTitle}>Производитель:</span> {item.manufacturer_name || '–'}
            </Typography>
            {!item.manufacturer_is_own && (
                <Typography className={classes.listItem} variant="body1">
                    <span className={classes.listTitle}>Описание:</span> {item.description || '–'}
                </Typography>
            )}
            <Typography className={classes.listItem} variant="body1">
                <span className={classes.listTitle}>Лот:</span>
                {item.lot || '–'}
            </Typography>
            <Typography className={classes.listItem} variant="body1">
                <span className={classes.listTitle}>Остаток:</span>
                {`${getLocalNumber(item.balance, { isFullDigits })} ${item.measure_name}`}
            </Typography>
            <Typography className={classes.listItem} variant="body1">
                <span className={classes.listTitle}>Дата поступления:</span>
                {moment(item.created_at).format('DD.MM.YYYY')}
            </Typography>
            <Typography className={classes.listItem} variant="body1">
                <span className={classes.listTitle}>Склад:</span> {`${item.stock_name}`}
                {item.isContractor ? ' (Склад поставщика)' : ''}
            </Typography>
        </>
    );
});

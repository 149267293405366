import { FC } from 'react';

import { Box, Paper, Typography, Container } from '@mui/material';

import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { Copyright } from '../../components/Copyright';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        height: '100vh',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        color: theme.palette.secondary.main,
    },
}));

export const NotFound: FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container maxWidth="xs">
                <Paper className={classes.paper}>
                    <Typography className={classes.title} component="h1" variant="h1">
                        404
                    </Typography>
                    <Typography component="h4">Страница не найдена</Typography>
                </Paper>
                <Box mt={8} style={{ textAlign: 'center' }}>
                    <Copyright />
                </Box>
            </Container>
        </div>
    );
};

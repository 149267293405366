import { ServiceBase } from './serviceBase';

const { getData, create, update, remove, get } = new ServiceBase({ path: 'suppliers' });

export {
    getData as getSuppliers,
    create as createSupplier,
    update as updateSupplier,
    remove as removeSuppliers,
    get as getSupplier,
};

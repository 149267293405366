import { FC } from 'react';
import * as yup from 'yup';
import { Grid } from '@mui/material';

import { PageWithTable } from '../../components/Page/withTable/PageWithTable';
import { SelectWithDictionary, InputDate, InputText } from '../../components/Inputs';

import { ViewForm } from './ViewForm';
import { EditForm } from './Forms';
import { getTransactionTitle } from './utils/getTransactionTitle';
import { PageProps } from '../../components/Page';
import { transactionsStore } from '../../store/TransactionsStore';
import { TRAN_INPUT_ID, TRAN_MERGE_ID, TRAN_MOVE_ID, TRAN_OUTPUT_ID, TRAN_SALE_ID } from '../../const/transactions';

interface TransactionsPageProps extends PageProps {
    title?: string;
}

function isBalanceTransaction(id: number) {
    return id === TRAN_SALE_ID || id === TRAN_OUTPUT_ID || id === TRAN_MOVE_ID;
}

const validationSchema = yup.object({
    tran_id: yup.number().required('Нужно выбрать тип транзакции'),
    created_at: yup.date(),
    stock_id: yup.number().when('tran_id', {
        is: (value: number) => value === TRAN_INPUT_ID || value === TRAN_MOVE_ID,
        then: yup.number().required('Нужно указать склад'),
    }),
    product_id: yup.number(),
    stock_id_from: yup.number(),
    comments: yup.string().nullable(),
    invoice: yup.string().when('tran_id', {
        is: TRAN_SALE_ID,
        then: yup.string().required('Нужно указать номер накладной'),
        otherwise: yup.string().nullable(),
    }),
    invoice_on: yup.date().nullable(),
    supplier_id: yup.string().when('tran_id', {
        is: TRAN_INPUT_ID,
        then: yup.string().required('Нужно выбрать поставщика'),
    }),
    buyer_id: yup.string().when('tran_id', {
        is: TRAN_SALE_ID,
        then: yup.string().required('Нужно выбрать покупателя'),
    }),
    shipment_on: yup.date().when('tran_id', {
        is: TRAN_SALE_ID,
        then: yup.date().required('Нужно указать дату отгрузки'),
    }),
    items: yup
        .array()
        .when('tran_id', {
            is: TRAN_INPUT_ID,
            then: yup.array().of(
                yup.object().shape({
                    product_id: yup.string().required('Нужно выбрать продукт'),
                    lot: yup.string().required('Нужно заполнить'),
                    manufactured_at: yup.date(),
                    description: yup.string().required('Нужно заполнить'),
                    quantity: yup.number().required('Нужно указать количество'),
                    mcost: yup.number().required('Нужно указать себестоимость').moreThan(0, 'Некорректное значение'),
                }),
            ),
        })
        .when(['tran_id', 'product_id'], {
            is: (value: number, productId: number) => isBalanceTransaction(value) && !productId,
            then: yup.array().of(
                yup.object().shape({
                    balance_id: yup.string().required('Нужно выбрать партию'),
                    quantity: yup.number().required('Нужно указать количество'),
                }),
            ),
        })
        .when(['tran_id', 'product_id'], {
            is: (value: number, productId: number) => isBalanceTransaction(value) && productId,
            then: yup.array().of(
                yup.object().shape({
                    balance_id: yup.string().required('Нужно выбрать партию'),
                    quantity: yup.number().required('Нужно указать количество'),
                }),
            ),
        })
        .when(['tran_id'], {
            is: TRAN_MERGE_ID,
            then: yup.array().of(
                yup.object().shape({
                    balance_id: yup.string().required('Нужно выбрать партию'),
                }),
            ),
        }),
});

const initialValues = {
    tran_id: TRAN_INPUT_ID,
    stock_id: '',
    stock_id_from: '',
    supplier_id: '',
    buyer_id: '',
    created_at: new Date(),
    invoice_on: new Date(),
    shipment_on: new Date(),
    invoice: '',
    comments: '',
    items: [],
};

const FilterForm: FC = () => (
    <>
        <Grid container alignItems="center">
            <SelectWithDictionary name="tran_id" type="trans" label="Транзакция" enableSystem style={{ width: 250 }} />
            <InputText name="products.sku" label="УИП" style={{ margin: '0 12px' }} />
            <InputText name="filter" label="Продукт" />
        </Grid>
        <InputDate name="created_at_from" label="От" startOfDay />
        <InputDate name="created_at_to" label="До" endOfDay />
    </>
);

export const TransactionsPage: FC<TransactionsPageProps> = ({ title, ...props }) => {
    return (
        <>
            <PageWithTable
                initialValues={initialValues}
                validationSchema={validationSchema}
                title={title}
                store={transactionsStore}
                ViewTemplate={ViewForm}
                FilterForm={FilterForm}
                withRemove={false}
                EditForm={EditForm}
                getModalTitle={getTransactionTitle}
                isOpenDetail
                {...props}
            />
        </>
    );
};

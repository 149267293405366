import { AUTH_PROP } from '../const/localStorage';

import { getAuthDataFromLocalStorage } from '../utils/getAuthDataFromLocalStorage';
import { request } from './request';

export const logout = () => {
    localStorage.removeItem(AUTH_PROP);
};

const login = (login: string, password: string) => {
    return request('login', { login, password }).then((data) => {
        // @ts-ignore
        if (data && !data.errors && data.key) {
            localStorage.setItem(AUTH_PROP, JSON.stringify(data));
        }

        return data;
    });
};

export const authenticationApi = {
    login,
    logout,
    get currentUser() {
        return getAuthDataFromLocalStorage();
    },
};

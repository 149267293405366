import { observable, configure } from 'mobx';

import { TableStoreBase, TableData } from './BaseTableStore';
import {
    getTransactions,
    addTransaction as addTransactionApi,
    getBalances as getBalancesApi,
} from '../services/transactions';
import { ColumnProps } from '../typings/table';

configure({ enforceActions: 'observed' });

export interface TransactionProps {
    [key: string]: any;
    /** ИН поставки на баланс */
    balance_id: number;
    /** Себестоимость */
    balance_mcost: string;
    /** Комментарий к транзакции */
    comments: null;
    /** Дата проведения транзакции */
    created_at: Date;
    /** ИН того, кем проведена транзакция */
    created_by_id: number;
    /** ФИО того, кем проведена транзакция */
    created_by_name: string;
    /** ИН */
    id: number;
    /** ИН склада откуда пришло */
    from_stock_id: number;
    /** Название склада откуда пришло */
    from_stock_name: number;
    /** Название продукта */
    product_name: string;
    /** Измененное кол-во */
    quantity: number;
    /** ИН склада */
    stock_id: number;
    /** Название склада */
    stock_name: string;
    /** ИН типа транзакции */
    tran_id: number;
    /** тип транзакции */
    tran_name: string;
}

export interface ITransactions extends TableData {
    items?: TransactionProps[];
}

export class TransactionsStore extends TableStoreBase {
    constructor() {
        super('transactions');

        this.itemPageLink = '/dashboard/transaction';

        this.getData = getTransactions;
        this.createItem = addTransactionApi;
    }

    getBalances(data: any) {
        return getBalancesApi(data);
    }

    @observable columns: ColumnProps[] = [
        { field: 'tran_id', title: 'Тип', sortable: true, type: 'transaction' },
        { field: 'created_at', title: 'Дата', sortable: true, type: 'datetime' },
        { field: 'stock_name', title: 'Склад' },
        { field: 'product_name', title: 'Продукт' },
        { field: 'product_sku', title: 'УИП' },
        { field: 'balance_mcost', title: 'Себестоимость единицы', type: 'withDashAndPrice' },
        { field: 'quantity', title: 'Кол-во', type: 'quantityTransaction' },
        { field: 'created_by_name', title: 'Автор', type: 'shortname' },
    ];

    @observable data: ITransactions = {};
}

export const transactionsStore = new TransactionsStore();

import { FC, useState, useCallback, ChangeEvent, useMemo, useEffect } from 'react';
import { Alert, Button, CardMedia, Step, Stepper, StepContent, StepLabel } from '@mui/material';

import { InputText } from '../../../components/Inputs/Text';
import { InputPhone } from '../../../components/Inputs/Phone';

import { getAccounts } from '../../../services/admin';
import { getEmployees } from '../../../services/employess';

import { FormProps } from '../../../typings/table';
import { getErrorMessage } from '../../../utils/getErrorMessage';
import { FormikValues, useFormikContext } from 'formik';

export const EditForm: FC<FormProps> = ({ isEdit, isOpen }) => {
    const { values, errors, setFieldTouched, setFieldValue, setErrors } = useFormikContext<FormikValues>();
    const [photoUrl, setPhotoUrl] = useState(values.photo);
    const [error, setError] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [activeStep, setActiveStep] = useState(isEdit ? 1 : 0);
    const initialEmailMessage = 'Электронная почта';
    const [emailMessageResult, setEmailMessageResult] = useState(isEdit ? values.email : initialEmailMessage);

    useEffect(() => {
        setError('');
    }, [isOpen]);

    const handleNext = async () => {
        setError('');
        let hasEmplyeeWithThisEmail = false;
        setFieldTouched('email', true);

        if (!errors.email && values.email) {
            setDisabled(true);

            await getEmployees({ filterData: { email: values.email } }).then((data) => {
                const foundEmployee = data?.rows?.[0];
                hasEmplyeeWithThisEmail = Boolean(foundEmployee && foundEmployee.id !== values.id);
            });

            getAccounts({ filterData: { email: values.email } })
                .then((data) => {
                    const accountData = data?.rows?.[0];

                    if (hasEmplyeeWithThisEmail) {
                        setError('Сотрудник с такой почтой уже существует');
                    } else if (accountData) {
                        setEmailMessageResult(accountData.email);
                        setActiveStep(1);

                        setFieldValue('first_name', accountData.first_name, false);
                        setFieldValue('last_name', accountData.last_name, false);
                        setFieldValue('patronymic_name', accountData.patronymic_name, false);

                        setErrors({});
                    } else if (!data.errors) {
                        setEmailMessageResult(values.email);
                        setActiveStep(1);

                        setErrors({});
                    } else if (data.errors) {
                        setError(getErrorMessage(data.errors));
                    }
                })
                .finally(() => setDisabled(false));
        }
    };

    const handleBack = () => {
        setEmailMessageResult(initialEmailMessage);

        setError('');

        setActiveStep(0);
    };

    const triggerOnChangePhoto = useCallback(
        (value: any) => {
            setPhotoUrl(value.data);

            setFieldValue('photo', { data: value.data, name: value.name }, false);
        },
        [setFieldValue],
    );

    const onUploadPhoto = useCallback(
        (event: ChangeEvent) => {
            // @ts-ignore
            const file = event.target.files[0];

            setError('');

            if (file) {
                let fr = new FileReader();
                fr.onload = (e) => {
                    triggerOnChangePhoto({
                        name: file.name,
                        size: file.size,
                        type: file.type,
                        // @ts-ignore
                        data: e.target.result,
                    });
                };
                fr.onerror = () => {
                    setError('Ошибка при загрузке файла');
                    triggerOnChangePhoto(null);
                };
                fr.readAsDataURL(file);
            }
        },
        [triggerOnChangePhoto],
    );

    const removePhoto = useCallback(() => {
        setPhotoUrl('');
        setFieldValue('photo', null, false);
    }, [setFieldValue]);

    const photoUrlGood = useMemo(() => {
        if (typeof photoUrl !== 'string') {
            return '';
        }

        if (photoUrl.includes('data:')) {
            return photoUrl;
        }

        return `/${photoUrl}`;
    }, [photoUrl]);

    return (
        <form style={{ width: 550 }}>
            <Stepper orientation="vertical" activeStep={activeStep}>
                <Step key="email">
                    <StepLabel>
                        {emailMessageResult}{' '}
                        {activeStep === 1 ? (
                            <Button size="small" variant="contained" color="primary" onClick={handleBack}>
                                Изменить почту
                            </Button>
                        ) : null}
                    </StepLabel>
                    <StepContent
                        style={{
                            opacity: activeStep ? 0 : 1,
                            transition: 'all .5s ease-out',
                        }}
                        transitionDuration={500}
                        TransitionProps={{ style: { transitionDelay: '200ms' } }}
                    >
                        <InputText name="email" disabled={disabled} label="Почта" fullWidth required />
                        <Button size="small" disabled={disabled} variant="contained" onClick={handleNext}>
                            Поиск по пользователям
                        </Button>
                    </StepContent>
                </Step>
                <Step key="data">
                    <StepLabel>Данные сотрудника</StepLabel>
                    <StepContent
                        style={{
                            opacity: activeStep ? 1 : 0,
                            transition: 'all .5s ease-out',
                        }}
                        transitionDuration={500}
                        TransitionProps={{ style: { transitionDelay: '200ms' } }}
                    >
                        {photoUrl ? (
                            <CardMedia
                                style={{ margin: '8px auto' }}
                                component="img"
                                alt="Фотография сотрудника"
                                image={photoUrlGood}
                                title="Фотография сотрудника"
                            />
                        ) : null}
                        <Button variant="contained" component="label" style={{ margin: '8px 0' }}>
                            Загрузить фотографию
                            <input
                                id="photo"
                                name="photo"
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={onUploadPhoto}
                            />
                        </Button>
                        {photoUrl ? (
                            <Button variant="contained" style={{ margin: '8px 4px' }} onClick={removePhoto}>
                                Удалить фотографию
                            </Button>
                        ) : null}
                        <InputText name="job" label="Должность" fullWidth required />
                        <InputText name="last_name" label="Фамилия" fullWidth required />
                        <InputText name="first_name" label="Имя" fullWidth required />
                        <InputText name="patronymic_name" label="Отчество" fullWidth />
                        <InputPhone name="phone" label="Контактный телефон" fullWidth required />
                    </StepContent>
                </Step>
            </Stepper>
            {error && (
                <Alert severity="error" style={{ margin: '4px 0' }}>
                    {error}
                </Alert>
            )}
        </form>
    );
};

import { observable, configure, runInAction, action } from 'mobx';
import _ from 'lodash';

import { TableStoreBase, TableData } from './BaseTableStore';

import { BalanceItemProps, BalanceProps, AlignType, FilterParamsProps, ColumnProps } from '../typings/table';
import { getBalances } from '../services/transactions';

configure({ enforceActions: 'observed' });

const groupProduct = (arr: [] = []) => {
    return arr.reduce((acc: any, x: BalanceProps) => {
        const sum: {
            measure_name: string;
            product_name: string;
            category_name: string;
            category_id: string;
            product_sku: string;
            product_id: number;
            quantity: number;
            balance: number;
            records: BalanceItemProps[];
        } = {
            product_name: x.product_name,
            product_sku: x.product_sku,
            category_name: x.category_name,
            category_id: x.category_id,
            quantity: Number(x.quantity),
            balance: Number(x.balance),
            product_id: x.product_id,
            measure_name: x.measure_name,
            records: [],
        };

        const item: BalanceItemProps = {
            supplier_id: x.supplier_id,
            supplier_name: x.supplier_name,
            balance: Number(x.balance),
            mcost: x.mcost,
            measure_name: x.measure_name,
            product_id: x.product_id,
            product_name: x.product_name,
            category_name: x.category_name,
            category_id: x.category_id,
            quantity: Number(x.quantity),
            created_at: x.created_at,
            manufactured_at: x.manufactured_at,
            invoice: x.invoice,
            invoice_on: x.invoice_on,
            stock_name: x.stock_name,
            id: x.id,
        };

        const founded = acc.findIndex((acc_item: BalanceItemProps) => acc_item.product_id === sum.product_id);

        if (acc[founded]) {
            acc[founded].balance += item.balance;
            acc[founded].records.push(item);
        } else {
            sum.records.push(item);
            acc.push(sum);
        }

        return acc;
    }, []);
};

const groupCategory = (arr: [] = []) => {
    return arr.reduce((acc: any, item: any) => {
        const sum: {
            category_name: string;
            category_id: string;
            records: BalanceItemProps[];
        } = {
            category_name: item.category_name,
            category_id: item.category_id,
            records: [],
        };

        const founded = acc.findIndex((acc_item: any) => acc_item.category_id === sum.category_id);

        if (acc[founded]) {
            acc[founded].records.push(item);
        } else {
            sum.records.push(item);

            acc.push(sum);
        }

        return acc;
    }, []);
};

export class StockDetailsStore extends TableStoreBase {
    @action updateStockFilterParams = (id: string, newParams: FilterParamsProps) => {
        this.filterParams = {
            ...this.filterParams,
            ...newParams,
        };

        this.filterState.isChanged = !_.isEqual(this.filterParams.filterData, this.initialFilterData);

        this.getStock(id, this.filterParams.filterData);
    };

    getStock = async (id: string, filterData = {}) => {
        runInAction(() => {
            this.loading = true;
        });

        try {
            const data = await getBalances({
                filterData: { stock_id: id, in_stock: true, ...filterData },
                orderDir: 'asc',
                order: 'category_name',
            });

            if (data.rows) {
                runInAction(() => {
                    this.current = data.rows[0];
                    this.data.items = groupCategory(groupProduct(data.rows));
                });
            }
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    getProduct = async (id?: string, filterData = {}) => {
        runInAction(() => {
            this.loading = true;
        });

        try {
            const data = await getBalances({
                filterData: { stock_id: id, in_stock: true, ...filterData },
                orderDir: 'asc',
                order: 'category_name',
            });

            if (data.rows) {
                runInAction(() => {
                    this.current = data.rows[0];
                    this.data.items = groupProduct(data.rows);
                });
            }
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    @observable current: Record<string, any> | null = null;

    @observable data: TableData = {};

    @observable stockColumns: ColumnProps[] = [
        { field: 'category_name', title: 'Категория', hidden: false },
        { field: 'product_sku', title: 'УИП', hidden: false },
        { field: 'product_name', title: 'Продукт', hidden: false, disable: true },
        {
            field: 'balance',
            title: 'Количество',
            align: 'right' as AlignType,
            hidden: false,
            type: 'number',
            disable: true,
        },
    ];

    @observable productColumns: ColumnProps[] = [
        { field: 'stock_name', title: 'Склад', hidden: false, disable: false },
        { field: 'created_at', title: 'Дата поступления', hidden: false, disable: true },
        { field: 'manufactured_at', title: 'Дата производства', hidden: false },
        { field: 'supplier_name', title: 'Поставщик', hidden: false },
        { field: 'invoice', title: 'Номер счёта', hidden: false },
        { field: 'invoice_on', title: 'Дата счёта', hidden: false },
        {
            field: 'mcost',
            title: 'Себестоимость единицы',
            hidden: false,
            type: 'withDash',
            align: 'right' as AlignType,
        },
        {
            field: 'balance',
            title: 'Количество',
            align: 'right' as AlignType,
            hidden: false,
            type: 'number',
            disable: true,
        },
    ];
}

export const stockDetailsStore = new StockDetailsStore();

import React, { useCallback, useEffect, useState } from 'react';
import { FC } from 'react';

import moment from 'moment';
import { observer } from 'mobx-react';
import { Button, Box, MenuItem, Typography } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';

import { Dialog, DialogProps } from '../../../components/Dialog/Dialog';
import { SelectWithData } from '../../../components/Inputs';
import { BalanceProps } from '../../../store/BalanceStore';
import { getBalances } from '../../../services/transactions';
import { getLocalNumber } from '../../../utils/getLocalNumber';
import { appStore } from '../../../store/AppStore';

interface AddBalanceDialogProps extends DialogProps {
    stockId: number;
    balancesIds: number[];
    onSelect: (balance: any) => void;
    onClose: () => void;
}

export const AddBalanceDialog: FC<AddBalanceDialogProps> = observer(
    ({ stockId, open, balancesIds, onSelect = (balance: any) => null, onClose = () => null }) => {
        const formik = useFormik({
            initialValues: { category_id: '', product_id: '', balance_id: '' },
            onSubmit: (values) => {
                console.log(values);
            },
        });

        const { values, setFieldValue } = formik;

        const categoryId = values?.category_id;
        const productId = values?.product_id;

        const [balancesList, setBalances] = useState<BalanceProps[]>([]);
        const balances = balancesList.filter((b: BalanceProps) => balancesIds.indexOf(Number(b.id)) === -1);

        useEffect(() => {
            setBalances([]);
            if (productId) {
                getBalances({
                    filterData: { in_stock: false, stock_id: stockId, product_id: productId },
                    order: 'balance_at',
                    orderDir: 'desc',
                }).then((data) => {
                    if (data && data.rows) {
                        setBalances(data.rows);
                    }
                });
            } else {
                setFieldValue('balance_id', '');
            }
        }, [stockId, productId, setFieldValue]);

        const handleSelect = () => {
            const balance = balances.find((value) => value.id === Number(values.balance_id));
            onSelect(balance);
        };

        const handleCategory = useCallback(
            (category: any) => {
                if (!category) {
                    setFieldValue('product_id', '');
                }
            },
            [setFieldValue],
        );

        const handleProduct = useCallback(
            (product: any) => {
                if (product && product?.category_id) {
                    setFieldValue('category_id', product?.category_id ?? '');
                }
            },
            [setFieldValue],
        );

        const productsFilter: Record<string, any> = {
            stocks_balances: { in_stock: false, stock_id: stockId },
        };

        if (categoryId) {
            productsFilter.category_id = categoryId;
        }

        return (
            <FormikProvider value={formik}>
                <Dialog
                    open={open}
                    actions={
                        <React.Fragment>
                            <Button onClick={handleSelect}>Выбрать</Button>
                            <Button variant="text" onClick={() => onClose()}>
                                Закрыть
                            </Button>
                        </React.Fragment>
                    }
                    title="Добавить партию"
                    onClose={() => onClose()}
                >
                    <Box sx={{ minWidth: 400 }}>
                        <SelectWithData
                            name={`category_id`}
                            type="categories"
                            label="Категория"
                            selectedCallback={handleCategory}
                            fullWidth
                        />
                    </Box>
                    <Box sx={{ minWidth: 400 }}>
                        <SelectWithData
                            name={`product_id`}
                            type="products"
                            label="Продукт"
                            filterParams={productsFilter}
                            selectedCallback={handleProduct}
                            fullWidth
                        />
                    </Box>
                    <SelectWithData
                        name="balance_id"
                        data={balances}
                        label="Партия"
                        withoutRefresh
                        fullWidth
                        required
                        withoutAutocomplete
                        menuItem={(item: BalanceProps) => {
                            return (
                                <MenuItem sx={{ display: 'block', p: 2 }} key={item.id} value={item.id}>
                                    <Typography variant="body1" sx={{ display: 'block' }}>
                                        <b>Производитель:</b> {item.manufacturer_name || '–'}
                                    </Typography>
                                    {!item.manufacturer_is_own && (
                                        <Typography variant="body1" sx={{ display: 'block' }}>
                                            <b>Описание:</b> {item.description || '–'}
                                        </Typography>
                                    )}
                                    <Typography variant="body1" sx={{ display: 'block' }}>
                                        <b>Лот:</b> {item.lot || '–'}
                                    </Typography>
                                    <Typography variant="body1" sx={{ display: 'block' }}>
                                        <b>Дата поступления:</b> {moment(item.created_at).format('DD.MM.YYYY')}
                                    </Typography>
                                    <Typography variant="body1" sx={{ display: 'block' }}>
                                        <b>Начальное количество:</b>{' '}
                                        {`${getLocalNumber(item.quantity, { isFullDigits: appStore.isFullDigits })} ${
                                            item.measure_name
                                        }`}
                                    </Typography>
                                </MenuItem>
                            );
                        }}
                    />
                </Dialog>
            </FormikProvider>
        );
    },
);

import { FC, PropsWithChildren, useEffect } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { Navigation } from './../Navigation/Navigation';
import { Header } from './../Header/Header';

import { dictionariesStore } from '../../store/DictionariesStore';

const useStyles = makeStyles((theme: Theme) => ({
    baseContainer: {
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    main: {
        paddingTop: 60,
        left: 0,
        width: '100%',
        height: '100vh',
        boxSizing: 'border-box',
        overflow: 'hidden',
        transition: theme.transitions.create(['left'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
            height: 'auto',
            minWidth: '100%',
            minHeight: '100%',
            maxHeight: '1000px',
        },
    },
    page: {
        padding: theme.spacing(2),
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: 12,
            height: 'auto',
        },
    },
}));

export const Dashboard: FC<PropsWithChildren> = observer(({ children }) => {
    const classes = useStyles();

    useEffect(() => {
        dictionariesStore.loadDictionaries({});
    }, []);

    return (
        <div className={classes.baseContainer}>
            <Header />
            <Navigation />
            <main className={clsx(classes.main)}>
                <div className={classes.page}>{children}</div>
            </main>
        </div>
    );
});

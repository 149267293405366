import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ruRU } from '@mui/material/locale';
import { orange, blue } from '@mui/material/colors';
import { StyledEngineProvider } from '@mui/material/styles';

import { Dashboard } from './components/Dashboard/Dashboard';
import { PrivateRoute } from './components/PrivateRoute';

import { Login } from './pages/Login/Login';
import { NotFound } from './pages/NotFound';
import { listsRoutes, NavigationProps, routes } from './routes';
import { cardRoutes } from './routes/cards';

const theme = createTheme(
    {
        typography: {
            fontSize: 13,
        },
        palette: {
            primary: {
                main: blue[500],
            },
            secondary: {
                main: orange[500],
            },
        },
        components: {
            MuiButton: {
                defaultProps: {
                    color: 'primary',
                    variant: 'contained',
                },
            },
            MuiTextField: {
                defaultProps: {
                    size: 'small',
                    variant: 'outlined',
                    margin: 'dense',
                },
            },
            MuiFormControl: {
                defaultProps: {
                    size: 'small',
                    margin: 'dense',
                },
            },
        },
    },
    ruRU,
);

const App = () => {
    return (
        <BrowserRouter>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        {routes.map(({ path, element, tabs, ...rest }: NavigationProps) => {
                            if (tabs?.length) {
                                return tabs.map(({ path, element, ...rest }) => (
                                    <Route
                                        key={String(path)}
                                        path={path}
                                        element={<Dashboard>{element}</Dashboard>}
                                        {...rest}
                                    />
                                ));
                            }
                            return (
                                <Route
                                    key={String(path)}
                                    path={path}
                                    element={<Dashboard>{element}</Dashboard>}
                                    {...rest}
                                />
                            );
                        })}
                        {cardRoutes.map(({ path, element, ...rest }) => {
                            return (
                                <Route
                                    key={String(path)}
                                    path={path}
                                    element={<Dashboard>{element}</Dashboard>}
                                    {...rest}
                                />
                            );
                        })}
                        {listsRoutes.map(({ path, element, ...rest }) => {
                            return (
                                <Route
                                    key={String(path)}
                                    path={path}
                                    element={<Dashboard>{element}</Dashboard>}
                                    {...rest}
                                />
                            );
                        })}
                        <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
                        <Route path="/" element={<PrivateRoute element={<Dashboard />} />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </ThemeProvider>
            </StyledEngineProvider>
        </BrowserRouter>
    );
};

export default App;

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useLocation } from 'react-router';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Alert, Button, CircularProgress, TextField, Backdrop } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { authenticationApi } from '../../services/authentication';
import { getErrorMessage } from '../../utils/getErrorMessage';
import { authStore } from '../../store/AuthStore';

const useStyles = makeStyles((theme: Theme) => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        zIndex: theme.zIndex.drawer + 1,
    },
}));

const validationSchema = yup.object({
    login: yup.string().required('Логин обязателен'),
    password: yup
        .string()
        .min(8, 'Пароль должен состоять минимум из 8 символов')
        .required('Пароль не может быть пустым'),
});

const LoginForm = observer(() => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    const [mainError, setMainError] = useState('');
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        if (authenticationApi.currentUser) {
            navigate('/dashboard');
        }
    }, [navigate]);

    function handleLogin(data: { login: string; password: string }) {
        setMainError('');

        setProcessing(true);

        authenticationApi
            .login(data.login, data.password)
            .then((data: any) => {
                if (data) {
                    if (data.errors) {
                        setMainError(getErrorMessage(data.errors));
                    } else {
                        authStore.updateUser(data);

                        // восстанавливаем страницу, если нас разлогинило в процессе
                        const { from } = (location.state as any) || { from: { pathname: '/dashboard' } };

                        navigate(from);
                    }
                } else {
                    setMainError('Что-то пошло не так, попробуйте позже');
                }
            })
            .catch((e) => {
                console.error(e);
                setMainError('Что-то пошло не так, попробуйте позже');
            })
            .finally(() => {
                setProcessing(false);
            });
    }

    const formik = useFormik({
        initialValues: {
            login: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values: { login: string; password: string }) => {
            handleLogin(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <TextField
                name="login"
                id="login"
                fullWidth
                disabled={processing}
                value={formik.values.login}
                onChange={formik.handleChange}
                error={formik.touched.login && Boolean(formik.errors.login)}
                helperText={formik.touched.login && formik.errors.login}
                label="Ваш логин или e-mail"
                required
                autoComplete="username"
                autoFocus
            />
            <TextField
                id="password"
                name="password"
                type="password"
                label="Ваш пароль"
                fullWidth
                disabled={processing}
                autoComplete="current-password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
            />
            <Button type="submit" fullWidth className={classes.submit} disabled={processing}>
                Войти в систему
            </Button>
            {/* @ts-ignore */}
            {mainError && <Alert severity="error">{mainError}</Alert>}
            <Backdrop className={classes.backdrop} open={processing}>
                <CircularProgress />
            </Backdrop>
        </form>
    );
});

export { LoginForm };

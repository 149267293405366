import { ServiceBase } from './serviceBase';

const { getData, create, update, remove, get } = new ServiceBase({ path: 'products' });

export {
    getData as getProducts,
    create as createProduct,
    update as updateProduct,
    remove as removeProducts,
    get as getProduct,
};

import { LayoutRouteProps } from 'react-router-dom';

import {
    PageWithProduct,
    PageWithSupplier,
    PageWithBuyer,
    PageWithTransaction,
    PageWithStockBalance,
    PageWithManufacture,
    PageWithProductBalance,
    PageWithBalance,
} from '../components/Page/withDetails';

export const cardRoutes: LayoutRouteProps[] = [
    {
        path: '/dashboard/product/:id',
        element: <PageWithProduct />,
    },
    {
        path: '/dashboard/product',
        element: <h1>Продукт не указан</h1>,
    },
    {
        path: '/dashboard/supplier/:id',
        element: <PageWithSupplier />,
    },
    {
        path: '/dashboard/buyer/:id',
        element: <PageWithBuyer />,
    },
    {
        path: '/dashboard/supplier',
        element: <h1>Поставщик не указан</h1>,
    },
    {
        path: '/dashboard/transaction/:id',
        element: <PageWithTransaction />,
    },
    {
        path: '/dashboard/transaction',
        element: <h1>Транзакция не указана</h1>,
    },
    {
        path: '/dashboard/stock/:id',
        element: <PageWithStockBalance />,
    },
    {
        path: '/dashboard/productbalance',
        element: <PageWithProductBalance />,
    },
    {
        path: '/dashboard/stock',
        element: <h1>Склад не указан</h1>,
    },
    {
        path: '/dashboard/manufacture/:id',
        element: <PageWithManufacture />,
    },
    {
        path: '/dashboard/balanceDetail',
        element: <PageWithBalance />,
    },
];

const fieldErrorMap: Record<string, string> = {
    login: 'Логин',
    name: 'Название',
    code: 'Код',
    email: 'Почта',
    inn: 'ИНН',
    sku: 'УИП',
    components: 'Состав',
    comments: 'Комментарий',
};

/**
 * Обработка текста ошибки.
 *
 * @param errors – сообщения об ошибке. Могут прийти как текстом, так и объектом с полями
 * @returns
 */
export const getErrorMessage = (errors?: any, tableName?: string) => {
    if (typeof errors === 'string') {
        return errors;
    }

    if (Object.keys(errors).length) {
        return Object.keys(errors)
            .map((error: string) => {
                if (error.match(/components.*quantity/)) {
                    return `Количество компонента: ${errors[error]}`;
                }

                if (tableName === 'transactions' && error === 'items') {
                    return `Количество партий: ${
                        errors[error]?.toLowerCase() === 'required' ? 'не заполнено' : errors[error]
                    }`;
                }

                return `${fieldErrorMap[error] || 'Ошибка'}: ${
                    errors[error]?.toLowerCase() === 'required' ? 'не заполнено' : errors[error]
                }`;
            })
            .join(',\n');
    }

    return '';
};

import { observable, configure, action } from 'mobx';

import { getAuthDataFromLocalStorage } from '../utils/getAuthDataFromLocalStorage';
import { getShortName } from '../utils/getShortName';
import { AUTH_PROP } from '../const/localStorage';

configure({ enforceActions: 'observed' });

interface UserProps {
    id?: string;
    login?: string;
    first_name?: string;
    patronymic_name?: string;
    last_name?: string;
}

export class AuthStore {
    @observable user: UserProps;

    constructor() {
        this.user = getAuthDataFromLocalStorage() || {};
    }

    get profileInfo() {
        const { first_name = '', last_name = '', patronymic_name = '' } = this.user;

        return getShortName(`${last_name || ''} ${first_name || ''} ${patronymic_name || ''}`);
    }

    @action
    updateUser({ first_name, last_name, patronymic_name }: UserProps) {
        localStorage.setItem(
            AUTH_PROP,
            JSON.stringify({ ...getAuthDataFromLocalStorage(), first_name, last_name, patronymic_name }),
        );

        this.user = getAuthDataFromLocalStorage();
    }
}

export const authStore = new AuthStore();

import { request } from './request';

import { FilterParamsProps } from '../typings/table';
import { getDataRequestParams } from '../utils/getDataRequestParams';

interface ServiceProps {
    path: string;
}

class TransactionsService {
    path: string;

    constructor(props: ServiceProps) {
        this.path = props.path;
    }

    addTransaction = (data: any) => {
        return request(`${this.path}/transaction`, data, 'POST');
    };

    /**
     * Получение списка товаров на балансе складов
     * @param data
     * @returns
     */
    getBalances = (data: FilterParamsProps) => {
        return request(`${this.path}/balances`, data, 'POST');
    };

    private group = (arr: [] = []) => {
        return arr.reduce((acc: any, x: any) => {
            if (x.stock_is_manufacture) {
                return acc;
            }

            const stock = {
                id: x.stock_id,
                name: x.stock_name,
            };

            const founded = acc.find((acc_item: any) => acc_item.id === stock.id);

            if (!founded) {
                acc.push(stock);
            }

            return acc;
        }, []);
    };

    /**
     * Получение списка складов по фильтру
     * @param data
     * @returns
     */
    getStocksFromBalances = (data: any) => {
        return this.getBalances(data).then((data) => ({
            rows: this.group(data?.rows),
            errors: data?.errors,
        }));
    };

    /**
     * Получение списка транзакций
     * @param data
     * @returns
     */
    getTransactions = (params: FilterParamsProps) => {
        const requestParams = getDataRequestParams(params);

        return request(`${this.path}/transactions`, requestParams, 'POST');
    };

    getBalance = (id: string) => {
        return request(`${this.path}/balance/${id}`, 'GET');
    };
}

const { addTransaction, getBalances, getTransactions, getStocksFromBalances, getBalance } = new TransactionsService({
    path: 'stocks',
});

export { addTransaction, getBalances, getTransactions, getStocksFromBalances, getBalance };

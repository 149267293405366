import { FC, useState, useRef, useEffect, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { FormikValues, FieldArray, useFormikContext } from 'formik';
import { Alert, Button, MenuItem, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ButtonWithRemove } from '../../../components/Button/withRemove/ButtonWithRemove';
import { DividerWithTitle } from '../../../components/DividerWithTitle';
import { InputNumber, SelectWithData } from '../../../components/Inputs';

import { getBalances } from '../../../services/transactions';
import { BalanceProps } from '../../../store/BalanceStore';
import { TRAN_MERGE_ID, TRAN_MOVE_ID } from '../../../const/transactions';
import { BalanceMenuItem } from './BalanceMenuItem';

interface BalanceFormProps {
    index: number;
    productId?: number;
    balances: BalanceProps[];
    currentMeasure?: string;
}

interface BalancesByProductProps {
    stockId: number;
    productId?: number;
    currentMeasure?: string;
}

const useStyles = makeStyles(() => ({
    contentBase: {
        margin: '12px 0',
        maxHeight: '280px',
        overflow: 'auto',
        position: 'relative',
        borderRadius: 6,
        backgroundColor: '#f5f5f5',
    },
    contentWithItems: {
        padding: 12,
        border: '1px solid rgb(200, 200, 200)',
    },
    itemCard: {
        padding: '24px 12px',
        margin: '12px 0',
    },
    menuItem: {
        display: 'block',
        borderBottom: '1px solid rgb(200, 200, 200)',
    },
}));

const getBatches = (items: BalanceProps[], id?: number, selectedBalanceIds: number[] = []) => {
    return items
        .filter((item: BalanceProps) => {
            return (
                (!id || item.product_id === id) &&
                !item.stock_is_manufacture &&
                selectedBalanceIds.indexOf(item.id) === -1
            );
        })
        .map((item: BalanceProps) => {
            const _item: BalanceProps = {
                ...item,
            };

            return _item;
        }, []);
};

const BalanceForm: FC<BalanceFormProps> = ({ index, balances, productId, currentMeasure }) => {
    const classes = useStyles();
    const { values, setFieldValue } = useFormikContext<FormikValues>();

    const batches = useMemo(() => {
        const items = values?.items || [];
        // получаем список выбранных партий, но не текущей
        const selectedBalanceIds =
            items
                ?.map((item: any) => item.balance_id)
                .filter((id: number) => {
                    return items?.[index]?.balance_id !== id;
                }) || [];

        return getBatches(balances, productId, selectedBalanceIds);
    }, [balances, productId, values?.items, index]);

    const isMerge = Number(values.tran_id) === TRAN_MERGE_ID;
    const isTransfer = Number(values.tran_id) === TRAN_MOVE_ID;

    const [selectedBalance, setSelectedBalance] = useState();

    const selectedBalanceCallback = useCallback(
        (selected: any) => {
            setSelectedBalance(selected);

            if (selected?.product_id) {
                setFieldValue(`product_id`, selected.product_id);
            }

            if (selected?.stock_id) {
                setFieldValue(isTransfer ? `stock_id_from` : `stock_id`, selected?.stock_id);
            }
        },
        [setFieldValue, isTransfer],
    );
    return (
        <>
            {
                <SelectWithData
                    name={`items.${index}.balance_id`}
                    data={batches}
                    label="Партия"
                    fieldName="product_name"
                    fieldId="id"
                    withoutRefresh
                    fullWidth
                    selectedCallback={selectedBalanceCallback}
                    withoutAutocomplete
                    menuItem={(item: BalanceProps) => (
                        <MenuItem className={classes.menuItem} key={item.id} value={item.id}>
                            <BalanceMenuItem item={item} />
                        </MenuItem>
                    )}
                />
            }
            {!isMerge && (
                <InputNumber
                    name={`items.${index}.quantity`}
                    label="Количество"
                    measure={currentMeasure}
                    isShort
                    fullWidth
                    withoutRound={true}
                    mantissa={3}
                    required
                />
            )}
            {!isMerge && (
                <Button
                    color="inherit"
                    onClick={() => {
                        // @ts-ignore
                        setFieldValue(`items.${index}.quantity`, selectedBalance?.balance || 0);
                    }}
                >
                    Указать всё количество
                </Button>
            )}
        </>
    );
};

export const BalancesByProduct: FC<BalancesByProductProps> = ({ stockId, productId, currentMeasure }) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const classes = useStyles();
    const { values } = useFormikContext<FormikValues>();
    const items = values?.items || [];
    const isMerge = Number(values.tran_id) === TRAN_MERGE_ID;
    const [balances, setBalances] = useState<BalanceProps[]>([]);

    useEffect(() => {
        getBalances({
            filterData: { stock_id: stockId, in_stock: true, product_id: productId },
            orderDir: 'asc',
            order: 'created_at',
        }).then((data) => {
            if (data.rows) {
                setBalances(data.rows);
            }
        });
    }, [stockId, productId]);

    if (!balances.length) {
        return (
            <Alert severity="info" style={{ margin: '4px 0' }}>
                Нет таких партий
            </Alert>
        );
    }

    return (
        <FieldArray name="items">
            {({ remove, push }) => {
                if (!items.length) {
                    push({});
                }

                return (
                    <>
                        <div
                            className={clsx(items.length ? classes.contentWithItems : '', classes.contentBase)}
                            ref={contentRef}
                        >
                            {items.map((component: any, index: number) => {
                                return (
                                    <span ref={index + 1 === items.length ? contentRef : undefined}>
                                        <Paper
                                            elevation={3}
                                            key={component.id || `component-${index}`}
                                            className={classes.itemCard}
                                        >
                                            <DividerWithTitle>
                                                {index + 1}{' '}
                                                {items.length > (isMerge ? 2 : 1) ? (
                                                    <ButtonWithRemove buttonText="" onRemove={() => remove(index)} />
                                                ) : null}
                                            </DividerWithTitle>
                                            <BalanceForm
                                                index={index}
                                                productId={productId}
                                                balances={balances}
                                                currentMeasure={currentMeasure}
                                            />
                                        </Paper>
                                    </span>
                                );
                            })}
                        </div>
                        <Button
                            variant="contained"
                            onClick={() => {
                                push({});
                                setTimeout(() => contentRef?.current?.scrollIntoView({ behavior: 'smooth' }), 100);
                            }}
                        >
                            Добавить партию
                        </Button>
                    </>
                );
            }}
        </FieldArray>
    );
};

import { useState, useRef, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Button, MenuItem, Menu, ListItemIcon, ListItemText } from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { LibraryBooks } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

import { listsRoutes, NavigationProps } from '../../routes';

const DictionariesMenuButton = withStyles((theme: Theme) => ({
    root: {
        backgroundColor: grey[100],
        color: theme.palette.getContrastText(grey[100]),
        '&:hover': {
            backgroundColor: grey[200],
        },
        marginRight: 12,
    },
}))(Button);

const useStyles = makeStyles(() => ({
    navItem: {
        textDecoration: 'none',
        color: '#333',
        whiteSpace: 'nowrap',
    },
}));

export const DictionariesMenu = observer(() => {
    const classes = useStyles();
    const buttonRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(buttonRef.current);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const getNavItem = (route: NavigationProps) => {
        return (
            <NavLink
                onClick={handleClose}
                to={{ pathname: String(route.path) }}
                key={route.name}
                className={classes.navItem}
            >
                <MenuItem>
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText primary={route.name} />
                </MenuItem>
            </NavLink>
        );
    };

    return (
        <div ref={buttonRef}>
            <DictionariesMenuButton color="primary" startIcon={<LibraryBooks />} onClick={handleMenu}>
                Словари
            </DictionariesMenuButton>
            <Menu
                style={{
                    zIndex: 1601,
                }}
                id="dicts-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {listsRoutes.map((route) => {
                    return getNavItem(route);
                })}
            </Menu>
        </div>
    );
});

import { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { Alert, Grid } from '@mui/material';

import { ProductsForm } from './ProductsForm';
import { BalancesByProduct } from './BalancesByProduct';

import { InputText, SelectWithDictionary, InputDate, SelectWithData } from '../../../components/Inputs';
import { DividerWithTitle } from '../../../components/DividerWithTitle';

import { TRAN_INPUT_ID, TRAN_MERGE_ID, TRAN_MOVE_ID, TRAN_OUTPUT_ID, TRAN_SALE_ID } from '../../../const/transactions';
import { FormikValues, useFormikContext } from 'formik';
import { BalancesByStock } from './BalancesByStock';

export const EditForm: FC = () => {
    const [error, setError] = useState('');
    const [currentMeasure, setCurrentMeasure] = useState('');

    const { values, setFieldValue } = useFormikContext<FormikValues>();

    useEffect(() => setError(''), []);

    const isNew = Number(values.tran_id) === TRAN_INPUT_ID;
    const isSale = Number(values.tran_id) === TRAN_SALE_ID;
    const isRemove = Number(values.tran_id) === TRAN_OUTPUT_ID;
    const isTransfer = Number(values.tran_id) === TRAN_MOVE_ID;
    const isMerge = Number(values.tran_id) === TRAN_MERGE_ID;

    const isBalancesByStock = isSale;
    const isBalancesByProduct = isRemove || isTransfer || isMerge;

    const balanceStockId = isTransfer ? values.stock_id_from : values.stock_id;

    const { category_id: currentCategoryId, product_id: currentProductId } = values;

    const categoryFilter = useMemo(
        () => ({
            stocks_balances: {
                stock_id: balanceStockId,
                in_stock: true,
            },
        }),
        [balanceStockId],
    );

    const productsFilter = useMemo(
        () => ({
            category_id: currentCategoryId,
            stocks_balances: {
                stock_id: balanceStockId,
                in_stock: true,
            },
        }),
        [currentCategoryId, balanceStockId],
    );

    const stocksFilter = useMemo(
        () => ({
            product_id: currentProductId,
            in_stock: true,
            is_manufacture: false,
            stock_is_manufacture: false,
        }),
        [currentProductId],
    );

    const toStockFilter = useMemo(() => ({ is_manufacture: false }), []);

    const selectedProductCallback = useCallback(
        (selected: any) => {
            if (selected?.category_id) {
                setFieldValue(`category_id`, selected?.category_id);
            }

            if (selected?.measure_name) {
                setFieldValue(`measure_name`, selected?.measure_name);
                setCurrentMeasure(selected?.measure_name);
            }
        },
        [setFieldValue],
    );

    return (
        <form>
            <Grid container spacing={3} style={{ width: 1000 }}>
                <Grid item xs={6}>
                    <DividerWithTitle>Общие параметры</DividerWithTitle>
                    <SelectWithDictionary name="tran_id" type="trans" label="Транзакция" fullWidth />
                    {isNew && (
                        <>
                            <SelectWithData name={`supplier_id`} type="suppliers" label="Поставщик" fullWidth />
                            <InputDate id="invoice_on" name="invoice_on" label="Дата счёта" />
                            <InputText name="invoice" label="Номер счёта" fullWidth />
                        </>
                    )}
                    {isSale && (
                        <>
                            <SelectWithData name={`buyer_id`} type="buyers" label="Покупатель" fullWidth />
                            <InputDate id="shipment_on" name="shipment_on" label="Дата отгрузки" />
                            <InputText name="invoice" label="Номер товарной накладной" fullWidth />
                        </>
                    )}
                    <InputText name="comments" rows={3} multiline label="Комментарий" fullWidth />
                    {isBalancesByProduct && (
                        <SelectWithDictionary
                            name={`category_id`}
                            type="categories"
                            label="Категория"
                            filterParams={categoryFilter}
                            fullWidth
                        />
                    )}
                    {isBalancesByProduct && (
                        <SelectWithData
                            name={`product_id`}
                            type="products"
                            label="Продукт"
                            filterParams={productsFilter}
                            selectedCallback={selectedProductCallback}
                            fullWidth
                        />
                    )}
                    {(isBalancesByProduct || isBalancesByStock) && (
                        <SelectWithData
                            name={isTransfer ? `stock_id_from` : `stock_id`}
                            type="stocksFromBalances"
                            label={isMerge ? 'Склад' : isSale ? 'Склад отгрузки' : 'Откуда'}
                            order="stock_name"
                            emptyText="Нет доступных складов"
                            filterParams={stocksFilter}
                            fullWidth
                        />
                    )}
                    {(isNew || isTransfer) && (
                        <SelectWithData
                            name={`stock_id`}
                            type="stocks"
                            label={'Куда'}
                            filterParams={toStockFilter}
                            fullWidth
                        />
                    )}
                </Grid>
                <Grid item xs={6}>
                    <DividerWithTitle>{isNew ? 'Продукты' : 'Партии на складах'}</DividerWithTitle>
                    {isNew && <ProductsForm />}
                    {isBalancesByStock && <BalancesByStock stockId={balanceStockId} />}
                    {isBalancesByProduct && (
                        <BalancesByProduct
                            stockId={balanceStockId}
                            productId={currentProductId}
                            currentMeasure={currentMeasure}
                        />
                    )}
                </Grid>
            </Grid>
            {error && (
                <Alert severity="error" style={{ margin: '4px 0' }}>
                    {error}
                </Alert>
            )}
        </form>
    );
};

import { FC, useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import {
    Table,
    TableContainer,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Tooltip,
    Button,
    Alert,
} from '@mui/material';
import { TableChartOutlined, ImportExport } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

import { Page, PageProps } from '../Page';
import { ColumnsPanel } from '../../ColumnsPanel';
import { ColumnProps } from '../../../typings/table';
import { appStore } from '../../../store/AppStore';
import { getStocks } from '../../../services/stocks';
import { getLocalNumber } from '../../../utils/getLocalNumber';
import { CellConfigurator } from '../../ModuleTable/Cell';
import { stockDetailsStore } from '../../../store/StockDetailsStore';

const useStyles = makeStyles(() => ({
    page: {
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
    },
    table: {
        backgroundColor: '#fff',
    },
    cardLink: {
        textDecoration: 'none',
        '&:hover > div': {
            boxShadow: '0 0 4px 4px rgba(40,40,40,.2)',
        },
    },
    card: {
        margin: 12,
    },
    listItem: {
        paddingTop: 4,
        paddingBottom: 4,
    },
    listTitle: {
        fontWeight: 'bold',
        marginRight: 4,
    },
    lastCell: {
        borderBottom: '1px solid #333',
    },
}));

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

/**
 * Состояние конкретного продукта на складе (Склад-Категория-Продукт)
 */
export const PageWithProductBalance: FC<PageProps> = observer((props) => {
    const classes = useStyles();
    const { isFullDigits } = appStore;
    const { getProduct, current, data, toggleColumnsPanel, columnsPanelState, toggleColumn, loading, productColumns } =
        stockDetailsStore;
    const query = useQuery();

    const stockId = query.get('stock') || '';
    const productId = query.get('product') || undefined;

    const [currentStock, setCurrentStock] = useState(current);
    const { items } = data;

    useEffect(() => {
        getProduct(stockId, { product_id: productId });
    }, [getProduct, stockId, productId]);

    useEffect(() => {
        if (!current) {
            getStocks({ filterData: { id: stockId } }).then((data) => {
                setCurrentStock(data?.rows?.[0]);
            });
        }
    }, [stockId, current]);

    const columnsButton = (
        <Tooltip title="Настройка столбцов" arrow placement="bottom">
            <Button variant="contained" onClick={toggleColumnsPanel}>
                <TableChartOutlined />
            </Button>
        </Tooltip>
    );

    const isColumnVisble = (columnField: string) => {
        return !productColumns.find((column: ColumnProps) => {
            return column.field === columnField;
        })?.hidden;
    };

    const toggleColumnHandler = useCallback(
        (name: string) => toggleColumn(name, productColumns),
        [productColumns, toggleColumn],
    );

    const handleAddTransaction = useCallback((data: any) => {
        const dataStr = JSON.stringify({
            tran_id: 4,
            product_id: data.product_id,
            category_id: data.category_id,
            stock_id: data.stock_id_from,
            items: [{ balance_id: data.id, isEdit: true }],
        });
        window.open(`transactions?create&data=${dataStr}`);
    }, []);

    if (loading) {
        return <h4>Загрузка данных...</h4>;
    }

    if (!current || !items) {
        return (
            <Page {...props} title={currentStock?.name} withPaper={false}>
                <Alert severity="warning">На складе пусто.</Alert>
            </Page>
        );
    }

    return (
        <Page
            {...props}
            title={`${stockId ? current.stock_name + ': ' : ''} ${current.product_name} (${current.category_name})`}
            withPaper={false}
            rightHeader={columnsButton}
        >
            <ColumnsPanel columns={productColumns} isVisble={columnsPanelState.isOpen} onChange={toggleColumnHandler} />
            <TableContainer className={classes.page}>
                <Table stickyHeader className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {productColumns.map((column: ColumnProps) => {
                                if (column.hidden || (stockId && column.field === 'stock_name')) {
                                    return null;
                                }

                                return (
                                    <TableCell align={column.align}>
                                        <span className={classes.listTitle}>{column.title}</span>
                                    </TableCell>
                                );
                            })}
                            {!isColumnVisble('balance') && <TableCell />}
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item) => {
                            return (
                                <>
                                    {item.records.map((record: any) => {
                                        const createTransaction = () => handleAddTransaction(record);

                                        return (
                                            <TableRow key={record.id}>
                                                {isColumnVisble('stock_name') && !stockId && (
                                                    <TableCell>{record.stock_name || '–'}</TableCell>
                                                )}
                                                {isColumnVisble('created_at') && (
                                                    <TableCell>
                                                        {`${moment(record.created_at).format('DD.MM.YYYY')}`}
                                                    </TableCell>
                                                )}
                                                {isColumnVisble('manufactured_at') && (
                                                    <TableCell>
                                                        {record.manufactured_at
                                                            ? `${moment(record.manufactured_at).format('DD.MM.YYYY')}`
                                                            : '–'}
                                                    </TableCell>
                                                )}
                                                {isColumnVisble('supplier_name') && (
                                                    <TableCell>{record.supplier_name || '–'}</TableCell>
                                                )}
                                                {isColumnVisble('invoice') && (
                                                    <TableCell>{record.invoice || '–'}</TableCell>
                                                )}
                                                {isColumnVisble('invoice_on') && (
                                                    <TableCell>{`${
                                                        record.invoice_on
                                                            ? moment(record.invoice_on).format('DD.MM.YYYY')
                                                            : '–'
                                                    }`}</TableCell>
                                                )}
                                                {isColumnVisble('mcost') && (
                                                    <CellConfigurator
                                                        value={record.mcost}
                                                        type="withDashAndPrice"
                                                        item={record}
                                                    />
                                                )}
                                                {isColumnVisble('balance') && (
                                                    <CellConfigurator
                                                        value={record.balance}
                                                        type="quantity"
                                                        item={record}
                                                    />
                                                )}
                                                <TableCell width={1} align="right">
                                                    <Button variant="contained" onClick={createTransaction}>
                                                        <ImportExport />
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    <TableRow>
                                        <TableCell colSpan={stockId ? 7 : 8} align="right">
                                            <span className={classes.listTitle}>
                                                {`Всего: ${getLocalNumber(item.balance, { isFullDigits })} ${
                                                    item.measure_name
                                                }`}
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                </>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Page>
    );
});

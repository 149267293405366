import { FC } from 'react';
import { TableCell, Chip } from '@mui/material';

import { makeStyles } from '@mui/styles';

interface CellWithBadgeProps {
    isTrue?: boolean;
    trueValue?: string;
    trueProps?: string;
    falseValue?: string;
    falseProps?: string;
}

const useStyles = makeStyles(() => ({
    trueBadge: (props: any) => {
        return {
            ...props.trueProps,
        };
    },
    falseBadge: (props: any) => {
        return {
            ...props.falseProps,
        };
    },
}));

export const CellWithBadge: FC<CellWithBadgeProps> = ({
    isTrue,
    trueValue,
    falseValue,
    trueProps = {},
    falseProps = {},
    ...rest
}) => {
    const classes = useStyles({ trueProps, falseProps });

    const trueBadge = trueValue ? <Chip className={classes.trueBadge} label={trueValue} /> : null;
    const falseBadge = falseValue ? <Chip className={classes.falseBadge} label={falseValue} /> : null;

    return <TableCell {...rest}>{isTrue ? trueBadge : falseBadge}</TableCell>;
};

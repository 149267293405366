import { observable, configure } from 'mobx';
import { TableStoreBase, TableData } from './BaseTableStore';

import { getAccounts, createAccount, updateAccount } from '../services/admin';
import { ColumnProps } from '../typings/table';
import { authStore } from './AuthStore';

configure({ enforceActions: 'observed' });

interface UserProps {
    email: string;
    login: string;
    first_name?: string;
    patronymic_name?: string;
    last_name?: string;
}

export interface AdminItemProps extends UserProps {
    [key: string]: any;
    id: number | string;
    is_disable: boolean;
    created_at: string;
}

export interface AdminDataProps extends TableData {
    items?: AdminItemProps[];
}

export class AdminStore extends TableStoreBase {
    @observable columns: ColumnProps[] = [
        { field: 'login', title: 'Логин' },
        { field: 'last_name', title: 'Фамилия' },
        { field: 'first_name', title: 'Имя' },
        { field: 'patronymic_name', title: 'Отчество' },
        { field: 'email', title: 'E-mail' },
        {
            field: 'is_disabled',
            title: 'Статус',
            type: 'boolean',
            dataParams: {
                trueProps: {
                    borderRadius: 6,
                    backgroundColor: 'rgba(200, 30, 30, .1)',
                    border: '0',
                    color: '#d33',
                    fontWeight: 600,
                },
                falseProps: {
                    borderRadius: 6,
                    backgroundColor: 'rgba(30, 200, 30, .1)',
                    border: '0',
                    color: '#3c3',
                    fontWeight: 600,
                },
                trueValue: 'Заблокирован',
                falseValue: 'Активен',
            },
        },
        { field: 'created_at', type: 'date', title: 'Дата создания' },
        { field: 'auth_at', type: 'datetime', title: 'Дата последней авторизации' },
    ];

    @observable data: AdminDataProps = {};

    @observable pageLimit: number = 1;

    constructor() {
        super('admin');

        this.getData = getAccounts;
        this.createItem = createAccount;
    }

    updateItem = (id: string, data: UserProps) => {
        if (id) {
            return updateAccount(id, data).then((res) => {
                // если пользователь обновляет свои данные, то обновляем его профиль локально тоже
                if (res && !res.errors && id === authStore.user.id) {
                    const { first_name, last_name, patronymic_name } = data;
                    authStore.updateUser({ first_name, last_name, patronymic_name });
                }

                return res;
            });
        }

        return createAccount(data);
    };
}

export const adminStore = new AdminStore();

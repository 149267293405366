import { FC } from 'react';

import { PageWithTable } from '../../components/Page/withTable/PageWithTable';
import { SelectWithData } from '../../components/Inputs';

import { PageProps } from '../../components/Page';
import { balanceStore } from '../../store/BalanceStore';

interface TransactionsPageProps extends PageProps {
    title?: string;
}

const FilterForm: FC = () => {
    return (
        <>
            <SelectWithData name="product_id" type="products" label="Продукты" multiple style={{ width: 340 }} />
            <SelectWithData name="stock_id" type="stocks" label="Склад" style={{ width: 280 }} />
        </>
    );
};

export const BalancePage: FC<TransactionsPageProps> = ({ title, ...props }) => {
    return (
        <>
            <PageWithTable
                title={title}
                store={balanceStore}
                FilterForm={FilterForm}
                withRemove={false}
                disabePaging
                disableEdit
                disabeItemOpen
                justOpenItem
                {...props}
            />
        </>
    );
};

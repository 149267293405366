import { observable, configure } from 'mobx';
import { TableStoreBase, TableData } from './BaseTableStore';

import { getCategories, createCategory, updateCategory, removeCategory } from '../services/categories';
import { ColumnProps } from '../typings/table';

configure({ enforceActions: 'observed' });

export interface CategoryProps {
    [key: string]: any;
    name: string;
}

export interface CategoriesDataProps extends TableData {
    items?: CategoryProps[];
}

const booleanDataParams = {
    falseProps: {
        borderRadius: 6,
        backgroundColor: 'rgba(200, 30, 30, .1)',
        border: '0',
        color: '#d33',
        fontWeight: 600,
    },
    trueProps: {
        borderRadius: 6,
        backgroundColor: 'rgba(30, 200, 30, .1)',
        border: '0',
        color: '#3c3',
        fontWeight: 600,
    },
    trueValue: 'Да',
    falseValue: 'Нет',
};

export class CategoriesStore extends TableStoreBase {
    constructor() {
        super('categories');

        this.filterParams.order = 'name';
        this.filterParams.orderDir = 'asc';

        this.getData = getCategories;
        this.removeItem = removeCategory;
        this.updateItem = updateCategory;
        this.createItem = createCategory;
    }

    @observable columns: ColumnProps[] = [
        { field: 'name', title: 'Наименование', sortable: true },
        { field: 'code', title: 'Код', sortable: true },
        {
            field: 'is_manufacture',
            title: 'Производится',
            type: 'boolean',
            dataParams: booleanDataParams,
        },
        {
            field: 'is_ready',
            title: 'Готовая продукция',
            type: 'boolean',
            dataParams: booleanDataParams,
        },
    ];

    @observable data: CategoriesDataProps = {};

    @observable pageLimit: number = 1;
}

export const categoriesStore = new CategoriesStore();

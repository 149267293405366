import { observable, action, configure, runInAction } from 'mobx';
import { FilterParamsProps } from '../typings/table';

import { getDictionaries } from '../services/dictionaries';
import { DictionariesType } from '../typings/dictionaries';

configure({ enforceActions: 'observed' });

interface DictionariesItem {
    id: number;
    name: string;
}

type DictionariesDataType = Record<DictionariesType, DictionariesItem[]>;

export class DictionariesStore {
    protected errors?: any;

    @observable loading: boolean = false;

    @observable dictionaries: DictionariesDataType = {
        categories: [],
        measures: [],
        trans: [],
        manufactures_statuses: [],
    };

    constructor() {
        this.getTransaction = this.getTransaction.bind(this);
    }

    getTransaction(id: number) {
        return this.dictionaries.trans.find((item) => item.id === id);
    }

    @action setDict(dictName: DictionariesType, data: any) {
        this.dictionaries[dictName] = data;
    }

    @action loadDictionaries(params: FilterParamsProps) {
        this.loading = true;
        this.errors = null;

        Object.keys(this.dictionaries).forEach((dictName) => this.setDict(dictName as DictionariesType, []));

        return getDictionaries(params)
            .then((data) => {
                if (data && data.errors) {
                    runInAction(() => {
                        this.errors = data.errors;
                    });
                }
                if (data && !data.errors) {
                    runInAction(() => {
                        Object.keys(this.dictionaries).forEach((dictName) =>
                            this.setDict(dictName as DictionariesType, data[dictName]),
                        );
                    });
                }

                return data;
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    }
}

export const dictionariesStore = new DictionariesStore();

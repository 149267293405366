import { NAV_STATE } from '../const/localStorage';

export const getNavState = () => {
    let data;
    let localStorageData = localStorage.getItem(NAV_STATE);

    if (localStorageData) {
        try {
            data = JSON.parse(localStorageData);
        } catch {}
    }

    return data;
};

import { FC } from 'react';
import * as yup from 'yup';

import { PageWithTable } from '../../components/Page/withTable/PageWithTable';
import { InputText } from '../../components/Inputs/Text';
import { SelectWithDictionary } from '../../components/Inputs';
import { SelectWithData } from '../../components/Inputs';
import { EditForm } from './Forms';
import { ViewItem } from './ViewItem';

import { productsStore } from '../../store/ProductsStore';

interface ProductsPageProps {
    title?: string;
}

const initialValues = {
    photo: null,
    name: '',
    sku: '',
    category_id: '',
    measure_id: 1,
    suppliers: [],
    components: [],
};

yup.addMethod(yup.array, 'unique', function (message, mapper, getPath) {
    return this.test('unique', message, function (list: any = []) {
        const set = Array.from(new Set(list.map(mapper)));
        const isUnique = list.length === set.length;

        if (isUnique) {
            return true;
        }

        // @ts-ignore
        const idx = list.findIndex((l, i) => mapper(l) !== set[i]);
        // @ts-ignore
        return this.createError({
            path: getPath(idx),
            message: message,
        });
    });
});

yup.addMethod(yup.array, 'checkMax', function (message, mapper, max, getPath) {
    return this.test('checkMax', message, function (list: any) {
        const count = (list || []).filter(mapper).length;

        if (count <= max) {
            return true;
        }

        // @ts-ignore
        const idx = list.findIndex((item) => mapper(item));
        // @ts-ignore
        return this.createError({
            path: getPath(idx),
            message: message,
        });
    });
});

const validationSchema = yup.object({
    name: yup.string().required('Поле обязателено'),
    sku: yup.string().required('Поле обязателено'),
    category_id: yup.number().required('Поле обязателено'),
    measure_id: yup.string().required('Поле обязателено'),
    suppliers: yup
        .array()
        .of(
            yup.object().shape({
                supplier_id: yup.string().required('Если добавили поставщика, то его нужно выбрать'),
                sku: yup.string().nullable(),
                delivery_days: yup.number().nullable(),
                main: yup.boolean(),
            }),
        )
        // @ts-ignore
        .unique(
            'Поставщики должны быть разные',
            (a: any) => a.supplier_id,
            (idx: number) => `suppliers.${idx}.supplier_id`,
        )
        .checkMax(
            'Главный поставщик может быть только один',
            (a: any) => Boolean(a.main),
            1,
            (idx: number) => `suppliers.${idx}.main`,
        ),
    components: yup
        .array()
        .of(
            yup.object().shape({
                component_id: yup.string().required('Нужно выбрать продукт'),
                component_category_id: yup.string(),
                quantity: yup.number().required('Нужно указать количество'),
            }),
        )
        // @ts-ignore
        .unique(
            'Компоненты должны быть разные',
            (a: any) => a.component_id,
            (idx: number) => `components.${idx}.component_id`,
        ),
});

const FilterForm: FC = () => (
    <>
        <InputText name="filter" label="Название" />
        <SelectWithDictionary
            name={`category_id`}
            type="categories"
            label="Категории"
            multiple
            style={{ width: 340 }}
        />
        <SelectWithData name={`suppliers.id`} type="suppliers" label="Поставщик" style={{ width: 280 }} />
    </>
);

export const ProductsPage: FC<ProductsPageProps> = () => {
    return (
        <>
            <PageWithTable
                title="Продукты"
                store={productsStore}
                EditForm={EditForm}
                ViewTemplate={ViewItem}
                FilterForm={FilterForm}
                validationSchema={validationSchema}
                initialValues={initialValues}
            />
        </>
    );
};

import { FC, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Alert, List, ListItem, ListItemText, Card, Typography, CardMedia } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Page, PageProps } from '../Page';

import { getProduct } from '../../../services/products';
import { TableItem } from '../../../typings/table';
import { getImageUrl } from '../../../utils/getImageUrl';
import { getLocalNumber } from '../../../utils/getLocalNumber';
import { appStore } from '../../../store/AppStore';

interface PageWithProductProps extends PageProps {}

const useStyles = makeStyles(() => ({
    page: {
        height: '100%',
        overflow: 'auto',
    },
    cardImage: {
        margin: 'auto',
        maxWidth: 400,
    },
    cardLink: {
        textDecoration: 'none',
        '&:hover > div': {
            boxShadow: '0 0 4px 4px rgba(40,40,40,.2)',
        },
    },
    card: {
        margin: 12,
    },
    listItem: {
        paddingTop: 4,
        paddingBottom: 4,
    },
    listTitle: {
        fontWeight: 'bold',
        marginRight: 4,
    },
}));

export const PageWithProduct: FC<PageWithProductProps> = observer((props) => {
    const classes = useStyles();
    const { isFullDigits } = appStore;
    // @ts-ignore
    const { id } = useParams();

    const [current, setCurrent] = useState<TableItem>({} as TableItem);
    const [measureQuantity, setMeasureQuantity] = useState(1);

    useEffect(() => {
        if (current?.measure_id !== 3) {
            setMeasureQuantity(1000);
        } else {
            setMeasureQuantity(1);
        }
    }, [current]);

    useEffect(() => {
        if (id) {
            getProduct(id).then((data) => {
                if (data.data) {
                    setCurrent(data.data);
                }
            });
        }
    }, [id]);

    return (
        <Page {...props} title={`Продукт: ${current.name}`} withPaper={false}>
            <div className={classes.page}>
                {current.photo ? (
                    <Card className={classes.cardImage}>
                        <CardMedia
                            component="img"
                            alt="Фотография продукта"
                            image={getImageUrl(current.photo)}
                            title="Фотография продукта"
                        />
                    </Card>
                ) : null}
                <Typography className={classes.card} variant="h5">
                    Категория:
                </Typography>
                <Card className={classes.card}>
                    <ListItem>
                        <ListItemText>{current.category_name}</ListItemText>
                    </ListItem>
                </Card>
                <Typography className={classes.card} variant="h5">
                    Единица учета:
                </Typography>
                <Card className={classes.card}>
                    <ListItem>
                        <ListItemText>{current.measure_name}</ListItemText>
                    </ListItem>
                </Card>
                {current?.suppliers?.length ? (
                    <Typography className={classes.card} variant="h5">
                        Поставщики:
                    </Typography>
                ) : (
                    ''
                )}
                {current?.suppliers?.map((supplier: TableItem) => {
                    return (
                        <Link
                            key={supplier.supplier_id}
                            to={`/dashboard/supplier/${supplier.supplier_id}`}
                            className={classes.cardLink}
                        >
                            <Card className={classes.card}>
                                <List>
                                    <ListItem>
                                        <span className={classes.listTitle}>Название:</span>
                                        <ListItemText>{supplier.supplier_name}</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <span className={classes.listTitle}>Артикул:</span>
                                        <ListItemText>{supplier.sku || '–'}</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <span className={classes.listTitle}>Срок поставки (дней):</span>
                                        <ListItemText>{supplier.delivery_days || '–'}</ListItemText>
                                    </ListItem>
                                </List>
                            </Card>
                        </Link>
                    );
                })}
                {current?.components?.length ? (
                    <>
                        <Typography className={classes.card} variant="h5">
                            Состав:
                        </Typography>

                        <Alert
                            severity="info"
                            style={{ margin: '8px 0 4px' }}
                        >{`Рецепт продукта на ${measureQuantity} ${current.measure_name}`}</Alert>
                    </>
                ) : (
                    ''
                )}
                {current?.components?.map((component: TableItem) => {
                    return (
                        <Link
                            key={component.component_id}
                            to={`/dashboard/product/${component.component_id}`}
                            className={classes.cardLink}
                        >
                            <Card className={classes.card}>
                                <List>
                                    <ListItem className={classes.listItem}>
                                        <ListItemText>{component.component_name || '–'}</ListItemText>
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                        <span className={classes.listTitle}>Количество:</span>
                                        <ListItemText>
                                            {getLocalNumber(component.quantity * measureQuantity, { isFullDigits })}{' '}
                                            {component.component_measure_name}
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </Card>
                        </Link>
                    );
                })}
            </div>
        </Page>
    );
});

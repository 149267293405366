import { FC } from 'react';
import _ from 'lodash';

import MuiPhoneNumber, { MuiPhoneNumberProps } from 'material-ui-phone-number';
import { FormikValues, useFormikContext } from 'formik';

type InputPhoneProps = MuiPhoneNumberProps & {
    name: string;
    label: string;
};

export const InputPhone: FC<InputPhoneProps> = ({ name, label, onChange, ...props }) => {
    const { values, errors, touched, setFieldValue } = useFormikContext<FormikValues>();

    const currentValue = _.get(values, name);
    const currentError = _.get(errors, name);
    const currentTouched = _.get(touched, name);

    return (
        <MuiPhoneNumber
            defaultCountry="ru"
            disableAreaCodes
            disableDropdown
            name={name}
            id={name}
            label={label}
            value={currentValue || ''}
            onChange={(e: React.ChangeEvent) => setFieldValue(name, e)}
            error={currentTouched && Boolean(currentError)}
            helperText={currentTouched && currentError ? String(currentError) : undefined}
            size="small"
            variant="outlined"
            margin="normal"
            {...props}
        />
    );
};

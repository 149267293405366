import { FC } from 'react';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/ru';

import { TextField, TextFieldProps } from '@mui/material';

import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FormikValues, useFormikContext } from 'formik';

interface InputDateProps {
    id?: string;
    name: string;
    label: string;
    required?: boolean;
    withTime?: boolean;
    onlyTime?: boolean;
    withoutHelpText?: boolean;
    value?: Date | number;
    style?: any;
    startOfDay?: boolean;
    endOfDay?: boolean;
}

moment.locale('ru');

export const InputDate: FC<InputDateProps> = ({
    id,
    name,
    label,
    required,
    withoutHelpText,
    // value,
    startOfDay,
    endOfDay,
}) => {
    const { values, errors, setFieldValue } = useFormikContext<FormikValues>();

    let currentValue = _.get(values, name);
    const currentError = _.get(errors, name);

    // useEffect(() => {
    //     if (value) {
    //         if (startOfDay) {
    //             setFieldValue(name, moment(value).startOf('day'));
    //         } else if (endOfDay) {
    //             setFieldValue(name, moment(value).endOf('day'));
    //         } else {
    //             setFieldValue(name, value);
    //         }
    //     }
    // }, [name, value, startOfDay, endOfDay, setFieldValue]);

    if (currentValue) {
        if (startOfDay) {
            currentValue = moment(currentValue).startOf('day');
        } else if (endOfDay) {
            currentValue = moment(currentValue).endOf('day');
        }
    }

    const commonProps: DatePickerProps<AdapterMoment, string> = {
        label,
        value: currentValue || null,
        onChange: (value) => {
            setFieldValue(name, value || null);
        },
        disableFuture: true,
        mask: '__.__.____',
        renderInput: (params: TextFieldProps) => (
            <TextField
                {...params}
                id={id || name}
                helperText={withoutHelpText ? '' : currentError ? String(currentError) : undefined}
                error={Boolean(currentError)}
                placeholder="ДД.ММ.ГГГГ"
                name={name}
                required={required}
                style={{ width: 170 }}
            />
        ),
    };

    const datePicker = <DatePicker {...commonProps} />;

    return (
        <LocalizationProvider libInstance={moment} dateAdapter={AdapterMoment} adapterLocale="ru">
            {datePicker}
        </LocalizationProvider>
    );
};

import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { List, ListItem, Card, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

import { Page, PageProps } from '../Page';
import { getTransactionTitle } from '../../../pages/Transactions/utils/getTransactionTitle';

import { getTransactions } from '../../../services/transactions';
import { TableItem } from '../../../typings/table';
import { getLocalNumber } from '../../../utils/getLocalNumber';
import { appStore } from '../../../store/AppStore';
import { observer } from 'mobx-react';
import { TRAN_INVENTORY_ID, TRAN_MOVE_ID, TRAN_SALE_ID } from '../../../const/transactions';

const useStyles = makeStyles(() => ({
    page: {
        height: '100%',
        overflow: 'auto',
    },
    cardLink: {
        textDecoration: 'none',
        '&:hover > div': {
            boxShadow: '0 0 4px 4px rgba(40,40,40,.2)',
        },
    },
    card: {
        margin: 12,
    },
    listItem: {
        paddingTop: 4,
        paddingBottom: 4,
    },
    listTitle: {
        fontWeight: 'bold',
        marginRight: 4,
    },
}));

export const PageWithTransaction: FC<PageProps> = observer((props) => {
    const classes = useStyles();
    const { isFullDigits } = appStore;
    // @ts-ignore
    const { id } = useParams();

    const [current, setCurrent] = useState<TableItem>({} as TableItem);

    useEffect(() => {
        getTransactions({ filterData: { id: id } }).then((data) => {
            if (data.rows?.[0]) {
                setCurrent(data.rows[0]);
            }
        });
    }, [id]);

    const title = getTransactionTitle(current as TableItem);

    const quantityWithSignSymbol = current.tran_id !== TRAN_SALE_ID && current.tran_id !== TRAN_INVENTORY_ID;

    return (
        <Page {...props} title={title} withPaper={false}>
            <div className={classes.page}>
                <Card className={classes.card}>
                    <List>
                        {Number(current.tran_id) === TRAN_MOVE_ID ? (
                            <>
                                <ListItem>
                                    <Typography variant="body1">
                                        <span className={classes.listTitle}>Тип:</span>{' '}
                                        {Number(current.quantity) < 0 ? 'Списание' : 'Поступление'}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <span className={classes.listTitle}>Откуда</span>{' '}
                                        {Number(current.quantity) < 0 ? current.stock_name : current.from_stock_name}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <span className={classes.listTitle}>Куда</span>{' '}
                                        {Number(current.quantity) < 0 ? current.from_stock_name : current.stock_name}
                                    </Typography>
                                </ListItem>
                            </>
                        ) : null}
                        {Number(current.tran_id) !== TRAN_MOVE_ID && (
                            <ListItem>
                                <Typography variant="body1">
                                    <span className={classes.listTitle}>Склад:</span> {current.stock_name}
                                </Typography>
                            </ListItem>
                        )}
                        <ListItem>
                            <Typography variant="body1">
                                <span className={classes.listTitle}>УИП:</span> {current.product_sku || '–'}
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <span className={classes.listTitle}>Продукт:</span> {current.product_name || '–'}
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <span className={classes.listTitle}>Производитель:</span>{' '}
                                {current.manufacturer_name || '–'}
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <span className={classes.listTitle}>Лот:</span> {current.balance_lot || '–'}
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <span className={classes.listTitle}>Произведено:</span>{' '}
                                {current.balance_manufactured_at
                                    ? `${moment(current.balance_manufactured_at).format('DD.MM.YYYY')}`
                                    : '–'}
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <span className={classes.listTitle}>Количество:</span> {}
                                {`${getLocalNumber(current.quantity, {
                                    withSignSymbol: quantityWithSignSymbol,
                                    isFullDigits,
                                })} ${current.measure_name || ''}`}
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <span className={classes.listTitle}>Себестоимость единицы:</span>{' '}
                                {current.balance_mcost
                                    ? `${getLocalNumber(current.balance_mcost, { isFullDigits })} р.`
                                    : '–'}
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <span className={classes.listTitle}>Поставщик:</span> {current.supplier_name || '–'}
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <span className={classes.listTitle}>Номер счёта:</span> {current.balance_invoice || '–'}
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <span className={classes.listTitle}>Дата счёта:</span>{' '}
                                {current.balance_invoice_on
                                    ? `${moment(current.balance_invoice_on).format('DD.MM.YYYY')}`
                                    : '–'}
                            </Typography>
                        </ListItem>
                        {Number(current.tran_id) === TRAN_SALE_ID ? (
                            <>
                                <ListItem>
                                    <Typography variant="body1">
                                        <span className={classes.listTitle}>Покупатель:</span>{' '}
                                        {current.sale_buyer_name || '–'}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <span className={classes.listTitle}>Дата отгрузки:</span>{' '}
                                        {current.sale_shipment_on
                                            ? `${moment(current.shipment_on).format('DD.MM.YYYY')}`
                                            : '–'}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <span className={classes.listTitle}>Номер товарной накладной:</span>{' '}
                                        {current.sale_invoice || '–'}
                                    </Typography>
                                </ListItem>
                            </>
                        ) : null}
                        <ListItem>
                            <Typography variant="body1">
                                <span className={classes.listTitle}>Автор:</span> {current.created_by_name || '–'}
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <span className={classes.listTitle}>Комментарий:</span> {current.comments || '–'}
                            </Typography>
                        </ListItem>
                    </List>
                </Card>
            </div>
        </Page>
    );
});

import React from 'react';
import { LayoutRouteProps } from 'react-router-dom';
import {
    Settings,
    HomeWork,
    ImportExport,
    Assignment,
    AccountTree,
    CorporateFare,
    CheckCircleOutline,
    BusinessCenter,
} from '@mui/icons-material';

import { AdminPage } from '../pages/Admin';
import { ProductsPage } from '../pages/Products';
import { BalancePage } from '../pages/Balance';
import { TransactionsPage } from '../pages/Transactions';
import { ManufacturePage } from '../pages/Manufacture';
import { InventoriesPage } from '../pages/Inventories';
import { ProjectsPage } from '../pages/Projects';

export interface NavigationProps extends LayoutRouteProps {
    name: string;
    icon?: React.ReactElement;
    tabs?: NavigationProps[];
    inProgress?: boolean;
}

export { listsRoutes } from './lists';

export const routes: NavigationProps[] = [
    {
        path: '/dashboard/admin',
        name: 'Администрирование',
        element: <AdminPage title="Администрирование" />,
        icon: <Settings />,
    },
    {
        path: '/dashboard/products',
        name: 'Продукты',
        element: <ProductsPage />,
        icon: <Assignment />,
    },
    {
        name: 'Модуль «Склады»',
        icon: <HomeWork />,
        tabs: [
            {
                path: '/dashboard/balance',
                name: 'Состояние складов',
                element: <BalancePage title="Состояние складов" />,
                icon: <CorporateFare />,
            },
            {
                path: '/dashboard/transactions',
                name: 'Транзакции',
                element: <TransactionsPage title="Транзакции" />,
                icon: <ImportExport />,
            },
            {
                path: '/dashboard/inventories',
                name: 'Инвентаризация',
                element: <InventoriesPage title="Инвентаризация" />,
                icon: <CheckCircleOutline />,
            },
        ],
    },
    {
        path: '/dashboard/manufactures',
        name: 'Производство',
        element: <ManufacturePage title="Производство" />,
        icon: <AccountTree />,
    },
    {
        path: '/dashboard/projects',
        name: 'Проекты',
        element: <ProjectsPage title="Проекты" />,
        icon: <BusinessCenter />,
    },
];

import React from 'react';
import clsx from 'clsx';
import { NavLink, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';

import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Drawer,
    Divider,
    IconButton,
    Collapse,
    Tooltip,
} from '@mui/material';
import { ChevronLeft, ExpandLess, ExpandMore } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { NAV_WIDTH } from './Navigation.const';

import { routes, NavigationProps } from '../../routes';

import { appStore } from '../../store/AppStore';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const useStyles = makeStyles((theme: Theme) => ({
    navOpen: {
        width: NAV_WIDTH,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    navClose: {
        width: theme.spacing(7),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    navHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    nav: {
        overflowX: 'hidden',
    },
    navList: {
        padding: 0,
    },
    listItemText: { whiteSpace: 'nowrap' },
    navLink: {
        color: '#333',
        display: 'flex',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
    },
    navItemActive: {
        color: '#f46b45',
        backgroundColor: 'rgb(235, 110, 56, .3)',
        borderLeft: '4px solid #f46b45',
        boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, .3)',
    },
    collapseButton: {
        backgroundColor: 'rgb(60, 60, 60, .1)',
        boxShadow: '0px 2px 4px rgba(60, 60, 60, .3)',
    },
    collapsePart: {
        backgroundColor: 'rgb(30, 30, 30, .1)',
        boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, .3)',
    },
}));

export const Navigation = observer(() => {
    const classes = useStyles();
    const location = useLocation();

    const query = useQuery();

    const getNavItem = (route: NavigationProps) => {
        if (route.inProgress && !query.get('dev')) {
            return null;
        }

        return (
            <Tooltip
                key={route.name}
                title={route.name}
                arrow
                placement="right"
                disableHoverListener={Boolean(appStore.isNavOpen)}
            >
                <NavLink
                    to={{ pathname: String(route.path), search: location.search }}
                    key={route.name}
                    className={classes.navLink}
                >
                    {({ isActive }) => (
                        <ListItem button className={isActive ? classes.navItemActive : undefined}>
                            <ListItemIcon>{route.icon}</ListItemIcon>
                            <ListItemText primary={route.name} className={classes.listItemText} />
                        </ListItem>
                    )}
                </NavLink>
            </Tooltip>
        );
    };

    return (
        <Drawer
            className={clsx({
                [classes.navOpen]: appStore.isNavOpen,
                [classes.navClose]: !appStore.isNavOpen,
            })}
            variant="permanent"
            open={appStore.isNavOpen}
            classes={{
                paper: clsx(classes.nav, {
                    [classes.navOpen]: appStore.isNavOpen,
                    [classes.navClose]: !appStore.isNavOpen,
                }),
            }}
        >
            <div className={classes.navHeader}>
                <IconButton onClick={appStore.toggleNavigation}>
                    <ChevronLeft />
                </IconButton>
            </div>
            <Divider />
            <List className={classes.navList}>
                {routes.map((route, index) => {
                    if (route.tabs?.length) {
                        const [open, setOpen] = React.useState(true);
                        const handleClick = () => {
                            setOpen(!open);
                        };

                        if (route.inProgress && !query.get('dev')) {
                            return null;
                        }

                        return (
                            <React.Fragment key={index}>
                                <Tooltip
                                    title={route.name}
                                    arrow
                                    placement="right"
                                    disableHoverListener={Boolean(appStore.isNavOpen)}
                                >
                                    <ListItem button onClick={handleClick} className={classes.collapseButton}>
                                        <ListItemIcon>{route.icon}</ListItemIcon>
                                        <ListItemText primary={route.name} className={classes.listItemText} />
                                        {appStore.isNavOpen ? open ? <ExpandLess /> : <ExpandMore /> : null}
                                    </ListItem>
                                </Tooltip>
                                <Collapse className={classes.collapsePart} in={open} timeout="auto" unmountOnExit>
                                    {route.tabs.map((tab) => {
                                        return getNavItem(tab);
                                    })}
                                </Collapse>
                            </React.Fragment>
                        );
                    } else {
                        return getNavItem(route);
                    }
                })}
            </List>
        </Drawer>
    );
});

import { FC } from 'react';
import * as yup from 'yup';

import { PageWithTable } from '../../components/Page/withTable/PageWithTable';
import { InputText } from '../../components/Inputs/Text';

import { FormProps } from '../../typings/table';
import { FormikValues, useFormikContext } from 'formik';
import { adminStore } from '../../store/AdminStore';
import { Checkbox } from '../../components/Inputs';

interface AdminPageProps {
    title?: string;
}

const validationSchema = yup.object({
    login: yup.string().required('Логин обязателен'),
    first_name: yup.string().required('Имя обязателено'),
    last_name: yup.string().required('Фамилия обязательна'),
    patronymic_name: yup.string().nullable(),
    email: yup.string().email('Некорректно указана почта').required('Почта обязательна'),
    is_disabled: yup.boolean(),
});

const initialValues = {
    login: '',
    first_name: '',
    patronymic_name: '',
    last_name: '',
    email: '',
    is_disabled: false,
};

const EditForm: FC<FormProps> = ({ isEdit }) => {
    const { handleChange } = useFormikContext<FormikValues>();

    return (
        <form>
            <InputText name="login" label="Логин" fullWidth required />
            <InputText name="last_name" label="Фамилия" fullWidth required />
            <InputText name="first_name" label="Имя" fullWidth required />
            <InputText name="patronymic_name" label="Отчество" fullWidth />
            <InputText name="email" label="Почта" fullWidth required />
            {isEdit ? (
                <Checkbox
                    id="is_disabled"
                    name="is_disabled"
                    onChange={handleChange}
                    color="primary"
                    label="Заблокирован"
                />
            ) : null}
        </form>
    );
};

const FilterForm: FC = () => (
    <>
        <InputText name="login" label="Логин" />
        <InputText name="email" label="Почта" />
    </>
);

export const AdminPage: FC<AdminPageProps> = ({ title }) => {
    return (
        <>
            <PageWithTable
                title={title}
                getModalTitle={(data) => data?.last_name + ' ' + data?.first_name + ' ' + data?.patronymic_name}
                store={adminStore}
                EditForm={EditForm}
                FilterForm={FilterForm}
                validationSchema={validationSchema}
                initialValues={initialValues}
                withRemove={false}
            />
        </>
    );
};

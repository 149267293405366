import { observable, configure, action, runInAction, autorun } from 'mobx';

import { TableStoreBase, TableData } from './BaseTableStore';
import { getBalances as getBalancesApi } from '../services/transactions';
import { getStocks } from '../services/stocks';
import { getCommonParamsLocalStorage } from '../utils/commonParamsLocalStorage';
import { ColumnProps } from '../typings/table';

configure({ enforceActions: 'observed' });

export interface StockProps {
    comments: string;
    stock_id: number;
    stock_name: string;
    items: BalanceItemProps[];
}

export interface BalanceItemProps {
    supplier_id: number;
    supplier_name: string;
    stock_name: string;
    balance: number;
    mcost: number;
    measure_name: string;
    product_id: number;
    product_name: string;
    category_name: string;
    category_id: string;
    quantity: number;
    id: number;
    created_at: Date;
    manufactured_at: Date;
    invoice?: number;
    invoice_on?: Date;
}

export interface BalanceProps extends StockProps, BalanceItemProps {
    [key: string]: any;
}

export interface IBalance extends TableData {
    items?: BalanceProps[];
}

const paramsFromLocalStorage: Record<string, any> = getCommonParamsLocalStorage();
const tableParams = paramsFromLocalStorage?.balance;

const groupCategory = (arr: any[] = []) => {
    return arr.reduce((acc: any, item: any) => {
        const sum: {
            group_name: string;
            group_id: string;
            group_field: string;
            records: BalanceItemProps[];
        } = {
            group_name: item.category_name,
            group_id: item.category_id,
            group_field: 'category_name',
            records: [],
        };

        const founded = acc.findIndex((acc_item: any) => acc_item.group_id === sum.group_id);

        if (acc[founded]) {
            acc[founded].records.push(item);
        } else {
            sum.records.push(item);

            acc.push(sum);
        }

        return acc;
    }, []);
};

export class BalanceStore extends TableStoreBase {
    protected stocks = [];

    initialFilterData = {};

    initialFilterParams = {
        limit: undefined,
        page: undefined,
        filterData: tableParams?.filterData || this.initialFilterData,
    };

    constructor() {
        super('balance');

        runInAction(() => {
            this.filterParams = {
                ...this.initialFilterParams,
            };
        });

        this.getData = getBalancesApi;

        this.itemPageLink = '/dashboard/productbalance';

        this.primaryKeyField = 'product_id';

        this.getLink = (data) =>
            data.fullBalance ? `/dashboard/productbalance?product=${data.product_id}` : undefined;

        this.getCellLink = (data, id) => {
            return data.fullBalance && id
                ? `/dashboard/productbalance?stock=${id}&product=${data.product_id}`
                : undefined;
        };

        autorun(() => {
            // Пересчитываем баланс, если отключаются какие-то склады
            const visibleColumnsStocks = this.columns
                .filter((column) => column.hidden && column.type === 'stock_name')
                .map((column) => column.field);

            runInAction(() => {
                // TODO: баланс хорошы бы переписать, много костылей под хотелки
                if (this.columns.find((column) => column.field === 'category_name')?.hidden) {
                    if (this.data.items?.find((item) => item.records)) {
                        this.data.items = this.data.items?.reduce((acc: BalanceProps[], item: BalanceProps) => {
                            return acc.concat(item.records);
                        }, []);
                    }

                    this.data.items = this.data?.items?.map((record: BalanceProps) => {
                        record.fullBalanceCurrent = Number(record.fullBalance);

                        visibleColumnsStocks.forEach((stockId) => {
                            const balance =
                                record.stocks.find((stock: any) => +stock.stock_id === +stockId)?.balance || 0;

                            record.fullBalanceCurrent = record.fullBalanceCurrent - balance;
                        });

                        return record;
                    });
                } else {
                    if (!this.data.items?.find((item) => item.group_name)) {
                        this.data.items = groupCategory(this.data.items);
                    }

                    this.data.items = this.data.items?.map((item) => {
                        item.records = item.records.map((record: BalanceProps) => {
                            record.fullBalanceCurrent = Number(record.fullBalance);

                            visibleColumnsStocks.forEach((stockId) => {
                                const balance =
                                    record.stocks.find((stock: any) => +stock.stock_id === +stockId)?.balance || 0;

                                record.fullBalanceCurrent = record.fullBalanceCurrent - balance;
                            });

                            return record;
                        });

                        return item;
                    });
                }
            });
        });
    }

    private group = (arr: [] = []) => {
        return arr.reduce((acc: any, x: BalanceProps) => {
            const product = {
                product_id: x.product_id,
                product_name: x.product_name,
                category_name: x.category_name,
                category_id: x.category_id,
                measure_name: x.measure_name,
                sku: x.product_sku,
                stocks: this.stocks.map((stock: any) => ({ ...stock, balance: 0 })),
                fullBalance: 0,
                fullBalanceCurrent: 0,
            };

            const stock = {
                stock_id: x.stock_id,
                stock_name: x.stock_name,
                comments: x.comments,
                balance: Number(x.balance),
            };

            const foundedIndex = acc.findIndex((acc_item: any) => acc_item.product_id === product.product_id);

            const currentStockIndex = product.stocks.findIndex((stock: any) => stock.stock_id === x.stock_id);

            if (acc[foundedIndex]) {
                // если продукт уже занесён в данные
                acc[foundedIndex].stocks[currentStockIndex].balance += stock.balance;

                acc[foundedIndex].fullBalance += stock.balance;
                acc[foundedIndex].fullBalanceCurrent += stock.balance;
            } else {
                // если о продукт ещё нет информации
                product.fullBalance += stock.balance;
                product.fullBalanceCurrent += stock.balance;
                product.stocks[currentStockIndex].balance = stock.balance;

                acc.push(product);
            }

            return acc;
        }, []);
    };

    @action loadData: () => Promise<any> = async () => {
        this.loading = true;
        this.data.errors = null;
        this.updateSelected([]);

        // после скрытия столбца и сразу сортировки за этим у нас устаревшее знание о tableParams.
        // нужно заного забирать об этом информацию
        const paramsFromLocalStorage: Record<string, any> = getCommonParamsLocalStorage();
        const tableParams = paramsFromLocalStorage?.balance;

        // получаем список всех складов
        await getStocks(
            this.filterParams.order === 'stock_name' ? { order: 'name', orderDir: this.filterParams.orderDir } : {},
        ).then((data: any) => {
            if (data?.rows) {
                this.stocks = data.rows.map((item: any) => ({
                    stock_id: item.id,
                    stock_name: item.name,
                }));

                runInAction(() => {
                    // добавляем колонки со складами
                    this.columns = [
                        ...(this.columns.length ? this.columns : this.initialColumns).filter(
                            (column: any) => column.type !== 'stock_name' && column.field !== 'fullBalanceCurrent',
                        ),
                        ...this.stocks.map(
                            (stock: any): ColumnProps => ({
                                field: String(stock.stock_id),
                                title: stock.stock_name,
                                type: 'stock_name',
                                sortable: false,
                                hidden: false,
                            }),
                        ),
                    ].map((column) => {
                        if (tableParams?.columnsState) {
                            column.hidden =
                                tableParams?.columnsState?.find(
                                    (columnState: any) => columnState.field === column.field,
                                )?.hidden || false;
                        }

                        return column;
                    });
                });
            }
        });

        return this.getData(this.filterParams)
            .then((data) => {
                if (data && (data as BalanceProps).rows) {
                    runInAction(() => {
                        const temp = this.group((data as BalanceProps).rows);

                        this.data.items = !this.columns.find((column) => column.field === 'category_name')?.hidden
                            ? groupCategory(temp)
                            : temp;
                    });
                }
                if (data && (data as BalanceProps).errors) {
                    runInAction(() => {
                        this.data.errors = (data as BalanceProps).errors;
                    });
                }
            })
            .finally(() => {
                // в конце добавляем итоговый столбец с общим балансом
                runInAction(() => {
                    this.loading = false;

                    this.columns.push({
                        field: 'fullBalanceCurrent',
                        title: 'Всего',
                        type: 'quantity',
                        // @ts-ignore
                        disable: true,
                        sortable: false,
                    });
                });
            });
    };

    initialColumns: ColumnProps[] = [
        { field: 'category_name', title: 'Категория', hidden: false, sortable: true },
        { field: 'sku', title: 'УИП', sortable: true },
        { field: 'product_name', title: 'Продукт', sortable: true },
    ].map((column) => {
        if (Array.isArray(tableParams?.columnsState)) {
            column.hidden =
                tableParams.columnsState.find((columnState: any) => columnState.field === column.field)?.hidden ||
                false;
        }

        return column;
    });

    @observable columns: ColumnProps[] = [...this.initialColumns];

    @observable data: IBalance = {};
}

export const balanceStore = new BalanceStore();

import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { authenticationApi } from '../../services/authentication';

interface PrivateRouteProps extends PropsWithChildren {
    element: React.ReactElement | null;
}

/**
 * Обёртка над роутингами, которые не должны быть доступны, если нет залогина
 */
export const PrivateRoute: FC<PrivateRouteProps> = ({ element }) => {
    const { currentUser } = authenticationApi;
    if (!currentUser) return <Navigate to="/login" replace />;
    return element;
};

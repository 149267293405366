import { FC, useEffect, useMemo, useState, useCallback, useRef } from 'react';
import _ from 'lodash';

import { getProducts } from '../../../services/products';
import { getSuppliers } from '../../../services/suppliers';
import { getStocks } from '../../../services/stocks';
import { getManufacturers } from '../../../services/manufacturers';
import { getStocksFromBalances } from '../../../services/transactions';
import { getCategories } from '../../../services/categories';
import { getEmployees } from '../../../services/employess';
import { getErrorMessage } from '../../../utils/getErrorMessage';
import { getShortName } from '../../../utils/getShortName';
import { DirectionType } from '../../../typings/table';

import { Select, FormSelectProps } from './Select';
import { Autocomplete } from './Autocomplete';
import { AutocompleteMultiple } from './AutocompleteMultiple';
import { FormikValues, useFormikContext } from 'formik';
import { getAccounts } from '../../../services/admin';
import { getBuyers } from '../../../services/buyers';

export interface FormSelectWithDataProps extends FormSelectProps {
    filterParams?: Record<string, any>;
    fieldName?: string;
    fieldId?: string;
    type?:
        | 'suppliers'
        | 'products'
        | 'stocks'
        | 'stocksFromBalances'
        | 'categories'
        | 'employees'
        | 'accounts'
        | 'manufacturers'
        | 'buyers';
    data?: any[];
    withoutRefresh?: boolean;
    menuItem?: any;
    order?: string;
    emptyText?: string;
    selectedCallback?: (data?: any) => void;
    withoutAutocomplete?: boolean;
    multiple?: boolean;
    selectFirst?: boolean;
    orderDir?: DirectionType;
}

const strategyMap = {
    suppliers: getSuppliers,
    products: getProducts,
    stocks: getStocks,
    stocksFromBalances: getStocksFromBalances,
    categories: getCategories,
    accounts: getAccounts,
    employees: getEmployees,
    manufacturers: getManufacturers,
    buyers: getBuyers,
};

export const SelectWithData: FC<FormSelectWithDataProps> = ({
    type,
    fieldName = 'name',
    fieldId = 'id',
    filterParams = null,
    name,
    data: dataFromProps,
    order = 'name',
    orderDir = 'asc',
    selectedCallback,
    withoutAutocomplete,
    disabled,
    multiple,
    selectFirst,
    ...props
}) => {
    const [data, setData] = useState(dataFromProps || []);
    const [isLoaded, setLoaded] = useState(Boolean(dataFromProps));
    const [isLoading, setLoading] = useState(false);
    const [errorData, setErrorData] = useState('');

    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    const { values, setFieldValue } = useFormikContext<FormikValues>();

    useEffect(() => {
        if (dataFromProps) {
            setData(dataFromProps);
        }
    }, [dataFromProps]);

    const filterParamsSerialized = JSON.stringify(filterParams);

    const loadData = useCallback(() => {
        setErrorData('');

        if (type) {
            setLoaded(false);
            setLoading(true);

            strategyMap[type]({ filterData: JSON.parse(filterParamsSerialized), order, orderDir })
                .then((data) => {
                    if (data.rows) {
                        setData(data.rows || []);
                    }

                    if (data.errors) {
                        setErrorData(getErrorMessage(data.errors));
                        setData([]);
                    }
                })
                .catch(() => {
                    setData([]);
                })
                .finally(() => {
                    setLoaded(true);
                    setLoading(false);
                });
        }
    }, [type, filterParamsSerialized, order, orderDir]);

    const currentValue = _.get(values, name);

    useEffect(() => {
        if (selectFirst && Array.isArray(data) && data.length > 0 && !currentValue) {
            setFieldValue(name, data[0][fieldId]);
        }
    }, [currentValue, selectFirst, setFieldValue, name, data, fieldId]);

    // загружаем список
    // TODO: перейти на стор. Грузить списки лучше при открытии карточки 1 раз.
    useEffect(() => {
        setErrorData('');
        if (type) {
            loadData();
        }
    }, [loadData, type]);

    const items = useMemo(() => {
        return data.map((item: any) => {
            return {
                ...item,
                name:
                    type === 'employees' || type === 'accounts'
                        ? getShortName(`${item.last_name} ${item.first_name} ${item.patronymic_name}`)
                        : item[fieldName],
                id: item[fieldId],
            };
        });
    }, [data, fieldName, fieldId, type]);

    // проверка, что элемент есть в списке
    const hasCurrentItemInFiltered = useMemo(() => {
        if (Array.isArray(currentValue)) {
            return items.some(
                (item) => currentValue.indexOf(String(item.id)) > -1 || currentValue.indexOf(item.id) > -1,
            );
        }

        return items.some((item) => String(item.id) === String(currentValue));
    }, [items, currentValue]);

    const clearField = useCallback(() => {
        setFieldValue(name, '');
    }, [setFieldValue, name]);

    useEffect(() => {
        if (!hasCurrentItemInFiltered && isLoaded && currentValue && mounted.current) {
            // очищаем поле, если выбранный элемент не содержится в списке (после фильтрации)
            clearField();
        }
    }, [hasCurrentItemInFiltered, isLoaded, clearField, currentValue, items]);

    const currentItem = items.find((item) => String(item.id) === String(currentValue)) || null;

    useEffect(() => {
        if (selectedCallback && isLoaded) {
            selectedCallback(currentItem);
        }
    }, [selectedCallback, currentItem, isLoaded]);

    const Component = withoutAutocomplete ? Select : multiple ? AutocompleteMultiple : Autocomplete;

    return (
        <Component
            {...props}
            disabled={disabled}
            multiple={multiple}
            items={items}
            name={name}
            isLoading={isLoading}
            isLoaded={isLoaded}
            errorData={errorData}
            onRefresh={loadData}
        />
    );
};

import { FC } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DividerWithTitle } from '../../../components/DividerWithTitle';

import { DialogData } from '../../../typings/table';
import { nl2br } from '../../../utils/nl2br';

interface ViewBuyerProps {
    current?: DialogData;
}

const useStyles = makeStyles(() => ({
    link: {
        textDecoration: 'none',
        color: '#333',
    },
    chip: {
        cursor: 'pointer',
        margin: 2,
    },
    page: {
        height: '100%',
        minWidth: 480,
    },
    listTitle: {
        fontWeight: 'bold',
        marginRight: 4,
    },
    listItem: {
        margin: '0',
    },
}));

export const ViewItem: FC<ViewBuyerProps> = ({ current }) => {
    const classes = useStyles();

    if (!current) {
        return null;
    }

    return (
        <div className={classes.page}>
            <DividerWithTitle>Данные</DividerWithTitle>
            <Typography variant="body1">
                <span className={classes.listTitle}>ИНН:</span> {current.inn || '–'}
            </Typography>
            <DividerWithTitle>Контакты</DividerWithTitle>
            <Typography variant="body1">
                <span className={classes.listTitle}>ФИО:</span>{' '}
                {[current.last_name, current.first_name, current.patronymic_name].filter(Boolean).join(' ') || '–'}
            </Typography>
            <Typography variant="body1">
                <span className={classes.listTitle}>Телефон:</span> {current.phone || '–'}
            </Typography>
            <Typography variant="body1">
                <span className={classes.listTitle}>Почта:</span> {current.email || '–'}
            </Typography>
            <DividerWithTitle>Дополнительно</DividerWithTitle>
            <div>{nl2br(current.comments)}</div>
        </div>
    );
};

import { FC } from 'react';
import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface ManufactureStatusProps {
    type: number;
    status: string;
}

const useStyles = makeStyles(() => ({
    plan: {
        backgroundColor: 'rgba(100, 100, 100, .1)',
        color: '#777',
        borderRadius: 6,
        border: '0',
        fontWeight: 600,
    },
    progress: {
        backgroundColor: 'rgba(10, 100, 250, .1)',
        color: '#3af',
        border: '0',
        borderRadius: 6,
        fontWeight: 600,
    },
    cancel: {
        borderRadius: 6,
        backgroundColor: 'rgba(200, 30, 30, .1)',
        border: '0',
        color: '#d33',
        fontWeight: 600,
    },
    finished: {
        borderRadius: 6,
        backgroundColor: 'rgba(30, 200, 30, .1)',
        border: '0',
        color: '#3c3',
        fontWeight: 600,
    },
}));

export const ManufactureStatus: FC<ManufactureStatusProps> = ({ type, status }) => {
    const classes = useStyles();

    if (type === 2) {
        return <Chip className={classes.progress} label={status} />;
    }

    if (type === 3) {
        return <Chip className={classes.finished} label={status} />;
    }

    if (type === 4) {
        return <Chip className={classes.cancel} label={status} />;
    }

    return <Chip className={classes.plan} label={status} />;
};

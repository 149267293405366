import { FC } from 'react';

import { PageWithTable } from '../../components/Page/withTable/PageWithTable';
import { EditForm, validationSchema } from './EditForm';

import { projectsStore } from '../../store/ProjectsStore';

interface ProjectsPageProps {
    title?: string;
}

export const ProjectsPage: FC<ProjectsPageProps> = ({ title }) => {
    return (
        <>
            <PageWithTable
                title={title}
                store={projectsStore}
                EditForm={EditForm}
                validationSchema={validationSchema}
                getRecordKey={(record: any) => record?.id + ':' + record?.product_id}
            />
        </>
    );
};

import { FC } from 'react';
import { observer } from 'mobx-react';
import { Chip } from '@mui/material';
import {
    MonetizationOnTwoTone,
    DeleteForeverTwoTone,
    AddCircleTwoTone,
    OpenWithTwoTone,
    CheckCircleOutline,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import {
    TRAN_INPUT_ID,
    TRAN_INVENTORY_ID,
    TRAN_MERGE_ID,
    TRAN_MOVE_ID,
    TRAN_OUTPUT_ID,
    TRAN_SALE_ID,
} from '../../const/transactions';
import { dictionariesStore } from '../../store/DictionariesStore';

interface TransactionProps {
    type: number;
}

const useStyles = makeStyles(() => ({
    posting: {
        backgroundColor: 'rgba(10, 100, 250, .1)',
        color: '#3af',
        borderRadius: 6,
        border: '0',
        fontWeight: 600,
    },
    postingIcon: {
        color: '#3af !important',
    },
    postingTo: {
        backgroundColor: 'rgba(100, 100, 100, .1)',
        color: '#777',
        border: '0',
        borderRadius: 6,
        fontWeight: 600,
    },
    postingToIcon: {
        color: '#777 !important',
    },
    disposal: {
        borderRadius: 6,
        backgroundColor: 'rgba(200, 30, 30, .1)',
        border: '0',
        color: '#d33',
        fontWeight: 600,
    },
    disposalIcon: {
        color: '#d33 !important',
    },
    sale: {
        borderRadius: 6,
        backgroundColor: 'rgba(30, 200, 30, .1)',
        border: '0',
        color: '#3c3',
        fontWeight: 600,
    },
    saleIcon: {
        color: '#3c3 !important',
    },
    inventory: {
        borderRadius: 6,
        backgroundColor: 'rgba(30, 200, 200, .1)',
        border: '0',
        color: '#3cc',
        fontWeight: 600,
    },
    inventoryIcon: {
        color: '#3cc !important',
    },
    unification: {
        borderRadius: 6,
        backgroundColor: 'rgba(238, 168, 73, .1)',
        border: '0',
        color: '#ffa122',
        fontWeight: 600,
    },
    unificationIcon: {
        color: '#ffa122 !important',
    },
}));

export const Transaction: FC<TransactionProps> = observer(({ type }) => {
    const { getTransaction } = dictionariesStore;
    const classes = useStyles();
    const title = getTransaction(type)?.name;

    if (type === TRAN_MERGE_ID) {
        return (
            <Chip
                className={classes.unification}
                label={title}
                icon={<AddCircleTwoTone className={classes.unificationIcon} />}
            />
        );
    }

    if (type === TRAN_INPUT_ID) {
        return (
            <Chip
                className={classes.posting}
                label={title}
                icon={<AddCircleTwoTone className={classes.postingIcon} />}
            />
        );
    }

    if (type === TRAN_MOVE_ID) {
        return (
            <Chip
                className={classes.postingTo}
                label={title}
                icon={<OpenWithTwoTone className={classes.postingToIcon} />}
            />
        );
    }

    if (type === TRAN_OUTPUT_ID) {
        return (
            <Chip
                className={classes.disposal}
                label={title}
                icon={<DeleteForeverTwoTone className={classes.disposalIcon} />}
            />
        );
    }

    if (type === TRAN_SALE_ID) {
        return (
            <Chip
                className={classes.sale}
                label={title}
                icon={<MonetizationOnTwoTone className={classes.saleIcon} />}
            />
        );
    }

    if (type === TRAN_INVENTORY_ID) {
        return (
            <Chip
                className={classes.inventory}
                label={title}
                icon={<CheckCircleOutline className={classes.inventoryIcon} />}
            />
        );
    }

    return <Chip label={title} />;
});

import { FC, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import {
    FormControl,
    InputLabel,
    Select as SelectMaterial,
    MenuItem,
    SelectProps,
    FormHelperText,
    OutlinedInput,
    Chip,
    CircularProgress,
    IconButton,
    Tooltip,
    Alert,
} from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { FormikValues, useFormikContext } from 'formik';

interface ItemProps {
    id: any;
    value?: any;
    name: string;
}

const useStyles = makeStyles(() => ({
    menuPaper: {
        maxHeight: 300,
    },
    formControl: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    },
    loading: {
        margin: 8,
    },
    refreshButton: {
        height: 30,
        width: 30,
        margin: '0 8px',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
}));

export interface FormSelectProps extends SelectProps {
    name: string;
    isLoading?: boolean;
    isLoaded?: boolean;
    items?: ItemProps[];
    errorData?: string;
    onRefresh?: () => void;
    withoutRefresh?: boolean;
    disableEmpty?: boolean;
    menuItem?: any;
    emptyText?: string;
}

export const Select: FC<FormSelectProps> = ({
    name,
    label = null,
    items = [],
    fullWidth,
    multiple,
    required,
    onChange,
    isLoading,
    isLoaded,
    errorData,
    onRefresh,
    withoutRefresh,
    menuItem,
    emptyText = 'Пусто',
    disableEmpty,
    ...props
}) => {
    const classes = useStyles();

    const { values, errors, handleChange } = useFormikContext<FormikValues>();

    const defaultValue = multiple ? [] : '';

    const normalizeItiems = useMemo(
        () =>
            items.reduce((res: Record<string, any>, item) => {
                // @ts-ignore
                res[item.id] = item.name;

                return res;
            }, {}),
        [items],
    );

    const selectProps: SelectProps = {};

    if (multiple) {
        selectProps.renderValue = (selected) => {
            if (selected || isLoading) {
                return (
                    <div className={classes.chips}>
                        {isLoading ? <span style={{ opacity: 0.6 }}>Загружается...</span> : null}
                        {(selected as string[]).map((value) => {
                            if (normalizeItiems[value]) {
                                return (
                                    <Chip
                                        size="small"
                                        key={value}
                                        label={normalizeItiems[value]}
                                        className={classes.chip}
                                    />
                                );
                            }

                            return null;
                        })}
                    </div>
                );
            }
        };
    }

    const currentValue = isLoading ? '' : _.get(values, name);
    const currentError = _.get(errors, name);

    const isLabelUp = useMemo(
        () => Boolean(!multiple || currentValue?.length || isLoading),
        [multiple, currentValue, isLoading],
    );

    const hasEmpty = useMemo(() => !disableEmpty && !multiple && !required, [multiple, required, disableEmpty]);

    return (
        <FormControl variant="outlined" fullWidth={fullWidth} error={Boolean(currentError)}>
            <div className={classes.formControl}>
                <InputLabel htmlFor={name} shrink={isLabelUp} required={required}>
                    {label}
                </InputLabel>
                <SelectMaterial
                    error={Boolean(currentError)}
                    fullWidth={fullWidth}
                    value={currentValue || defaultValue}
                    displayEmpty={hasEmpty || isLoading}
                    multiple={multiple}
                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                    onChange={onChange ? onChange : handleChange}
                    input={
                        <OutlinedInput
                            required={required}
                            notched={isLabelUp}
                            name={name}
                            id={name}
                            label={label}
                            error={Boolean(currentError)}
                            {...props}
                        />
                    }
                    {...selectProps}
                >
                    {isLoading ? (
                        <MenuItem value="">
                            <span style={{ opacity: 0.6 }}>Загружается...</span>
                        </MenuItem>
                    ) : null}
                    {hasEmpty && !isLoading && isLoaded && items?.length ? (
                        <MenuItem value="">
                            <span style={{ opacity: 0.3 }}>Не выбрано</span>
                        </MenuItem>
                    ) : null}
                    {isLoaded && !items?.length ? (
                        <MenuItem value="">
                            <span style={{ opacity: 0.3 }}>{emptyText}</span>
                        </MenuItem>
                    ) : null}
                    {isLoading ? <CircularProgress size={28} className={classes.loading} /> : null}
                    {items?.length
                        ? items.map((item) => {
                              return (
                                  menuItem?.(item) || (
                                      <MenuItem key={item.id} value={item.id}>
                                          {item.name}
                                      </MenuItem>
                                  )
                              );
                          })
                        : null}
                    {errorData ? (
                        <Alert severity="error" style={{ margin: '4px 0' }}>
                            {errorData}
                        </Alert>
                    ) : null}
                </SelectMaterial>
                {!withoutRefresh && onRefresh ? (
                    <Tooltip title="Обновить список" arrow placement="bottom">
                        <IconButton size="small" className={classes.refreshButton} onClick={onRefresh}>
                            <Refresh />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </div>
            {Boolean(currentError) && <FormHelperText error>{String(currentError)}</FormHelperText>}
        </FormControl>
    );
};

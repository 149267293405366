import { FC, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { List, ListItem, ListItemText, Card, Typography, ListItemAvatar, Avatar } from '@mui/material';
import { Image } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { Page, PageProps } from '../Page';

import { getSupplier } from '../../../services/suppliers';
import { getProducts } from '../../../services/products';
import { TableItem } from '../../../typings/table';

interface PageWithProductProps extends PageProps {}

const useStyles = makeStyles(() => ({
    page: {
        height: '100%',
        overflow: 'scroll',
    },
    cardLink: {
        textDecoration: 'none',
        '&:hover > div': {
            boxShadow: '0 0 4px 4px rgba(40,40,40,.2)',
        },
    },
    card: {
        margin: 12,
    },
    listItem: {
        paddingTop: 4,
        paddingBottom: 4,
    },
    listTitle: {
        fontWeight: 'bold',
        marginRight: 4,
    },
}));

export const PageWithSupplier: FC<PageWithProductProps> = (props) => {
    const classes = useStyles();
    // @ts-ignore
    const { id } = useParams();

    const [current, setCurrent] = useState<TableItem>({} as TableItem);
    const [products, setProducts] = useState<TableItem[]>([]);

    useEffect(() => {
        if (id) {
            getSupplier(id).then((data) => {
                if (data.data) {
                    setCurrent(data.data);
                }
            });
        }
    }, [id]);

    useEffect(() => {
        getProducts({ filterData: { suppliers: { id } } }).then((data) => {
            if (data.rows) {
                setProducts(data.rows);
            }
        });
    }, [setProducts, id]);

    return (
        <Page {...props} title={`Поставщик: ${current.name}`} withPaper={false}>
            <div className={classes.page}>
                <Typography className={classes.card} variant="h5">
                    Данные:
                </Typography>
                <Card className={classes.card}>
                    <List>
                        <ListItem>
                            <span className={classes.listTitle}>ИНН:</span>
                            <ListItemText>{current.inn || '–'}</ListItemText>
                        </ListItem>
                    </List>
                </Card>
                <Typography className={classes.card} variant="h5">
                    Контакты:
                </Typography>
                <Card className={classes.card}>
                    <List>
                        <ListItem>
                            <span className={classes.listTitle}>ФИО:</span>
                            <ListItemText>
                                {[current.last_name, current.first_name, current.patronymic_name]
                                    .filter(Boolean)
                                    .join(' ') || '–'}
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <span className={classes.listTitle}>Телефон:</span>
                            <ListItemText>{current.phone || '–'}</ListItemText>
                        </ListItem>
                        <ListItem>
                            <span className={classes.listTitle}>Почта:</span>
                            <ListItemText>{current.email || '–'}</ListItemText>
                        </ListItem>
                    </List>
                </Card>
                {products?.length ? (
                    <Typography className={classes.card} variant="h5">
                        Поставляемые продукты:
                    </Typography>
                ) : (
                    ''
                )}
                {products?.map((product: TableItem) => {
                    return (
                        <Link to={`/dashboard/product/${product.id}`} className={classes.cardLink}>
                            <Card className={classes.card}>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar variant="rounded" src={`/${product.photo}`}>
                                                <Image />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText>{product.name}</ListItemText>
                                    </ListItem>
                                </List>
                            </Card>
                        </Link>
                    );
                })}
            </div>
        </Page>
    );
};

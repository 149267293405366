import React, { FC } from 'react';
import { TableCell, Checkbox } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { orange } from '@mui/material/colors';

interface CellWithCheckboxProps {
    isSelected?: boolean;
    enable?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles((theme) => ({
    root: {
        color: orange[500],
        '&$checked': {
            color: orange[500],
        },
    },
    checked: {},
}));

export const CellWithCheckbox: FC<CellWithCheckboxProps> = ({ isSelected = false, enable = false, onChange }) => {
    const classes = useStyles();

    if (!enable) {
        return null;
    }

    return (
        <TableCell padding="checkbox">
            <Checkbox
                checked={isSelected}
                onChange={onChange}
                onClick={(event) => event.stopPropagation()}
                classes={{
                    root: classes.root,
                    checked: classes.checked,
                }}
            />
        </TableCell>
    );
};

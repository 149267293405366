import { FC } from 'react';
import moment from 'moment';
import { TableCell } from '@mui/material';

interface CellProps {
    value?: string;
}

moment().locale('ru');

export const CellWithDate: FC<CellProps> = ({ value }) => {
    return <TableCell style={{ whiteSpace: 'nowrap' }}>{moment(value).format('DD.MM.YYYY')}</TableCell>;
};

import { FC } from 'react';
import { TableCell } from '@mui/material';

import { ManufactureStatus } from '../../Format';

interface CellWithManufactureStatusProps {
    type: number;
    status: string;
}

export const CellWithManufactureStatus: FC<CellWithManufactureStatusProps> = ({ type, status }) => {
    return (
        <TableCell>
            <ManufactureStatus type={type} status={status} />
        </TableCell>
    );
};

import { ServiceBase } from './serviceBase';

const { getData, create, update, remove, get } = new ServiceBase({ path: 'manufactures' });

export {
    getData as getManufactures,
    create as createManufacture,
    update as updateManufacture,
    remove as removeManufacture,
    get as getManufacture,
};

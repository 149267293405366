export const getImageUrl = (url: string) => {
    if (typeof url !== 'string') {
        return '';
    }

    if (url.includes('data:')) {
        return url;
    }

    return `/${url}`;
};

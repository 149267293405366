import { FC, useCallback } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { Button, Paper, Divider } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';

import { FilterParamsProps } from '../../typings/table';

interface FilterComponentProps {
    FilterForm: FC;
    onFilter: (props: FilterParamsProps, isFilterSubmit?: boolean) => void;
    onClear: () => void;
    loading?: boolean;
    filterValues?: Record<string, any>;
    initialFilterState?: Record<string, any>;
    isVisble: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: grey[50],
        transition: theme.transitions.create('all', {
            duration: theme.transitions.duration.standard,
        }),
        maxHeight: 0,
        maxWidth: '100%',
        opacity: 0,
        pointerEvents: 'none',
    },
    fieldsContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: '0 6px',
        '& > *': {
            margin: '12px 6px',
        },
        '& > * > .MuiFormControl-root': {
            marginTop: 8,
        },
    },
    filterOpen: {
        maxHeight: '600px',
        marginBottom: 24,
        opacity: 1,
        pointerEvents: 'auto',
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 12,
        '& > * + *': {
            marginLeft: 8,
        },
    },
}));

export const Filter: FC<FilterComponentProps> = ({
    onFilter,
    FilterForm,
    loading,
    isVisble,
    onClear,
    filterValues = {},
}) => {
    const classes = useStyles();

    const filterFormConfig = useFormik({
        enableReinitialize: true,
        initialValues: filterValues,
        onSubmit: (values: any) => {
            onFilter({ filterData: _.pickBy(values, _.identity) }, true);
        },
    });

    const handleSearch = useCallback(() => {
        filterFormConfig.handleSubmit();
    }, [filterFormConfig]);

    const handleClear = useCallback(() => {
        onClear();
        filterFormConfig.resetForm();
    }, [filterFormConfig, onClear]);

    return (
        <Paper
            className={clsx(
                {
                    [classes.filterOpen]: isVisble,
                },
                classes.root,
            )}
        >
            <FormikProvider value={filterFormConfig}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSearch();
                    }}
                >
                    <div className={classes.fieldsContainer}>
                        <FilterForm />
                    </div>
                    <Divider />
                    <div className={classes.buttonsContainer}>
                        <Button variant="contained" color="inherit" onClick={handleClear} disabled={loading}>
                            Очистить фильтр
                        </Button>
                        <Button type="submit" variant="contained" color="primary" disabled={loading}>
                            Найти
                        </Button>
                    </div>
                </form>
            </FormikProvider>
        </Paper>
    );
};

import { AUTH_PROP } from '../const/localStorage';

export const getAuthDataFromLocalStorage = () => {
    let data;
    let localStorageData = localStorage.getItem(AUTH_PROP);

    if (localStorageData) {
        try {
            data = JSON.parse(localStorageData);
        } catch {}
    }

    return data
}
import { FC } from 'react';

import { FormSelectWithDataProps, SelectWithData } from './SelectWithData';

export const AccountSelect: FC<FormSelectWithDataProps> = ({
    filterParams = { is_manufacture: false },
    label = 'Пользователь',
    type = 'accounts',
    order = 'last_name',
    fieldName = 'last_name',
    ...props
}) => (
    <SelectWithData
        filterParams={filterParams}
        label={label}
        type={type}
        order={order}
        fieldName={fieldName}
        {...props}
    />
);

type Params = {
    /** сколько знаков после запятой выводить */
    digits?: number;
    /** нужно ли указывать в плюс или минус значение */
    withSignSymbol?: boolean;
    /** нужно ли показывать все знаки после запятой */
    isFullDigits?: boolean;
};

export const getLocalNumber = (value: string | number, numberParams: Params = {}) => {
    const { digits = 3, withSignSymbol = false, isFullDigits = false } = numberParams;
    const _value = parseFloat(Number(value).toPrecision(8));
    let tempValue = Math.abs(Number(_value));
    let duration = '';

    if (withSignSymbol) {
        duration = Number(_value) > 0 ? '+' : Number(_value) < 0 ? '-' : '';
    }

    const params: Intl.NumberFormatOptions = {
        useGrouping: true,
        maximumFractionDigits: isFullDigits ? 8 : digits,
    };

    const rounded = tempValue.toLocaleString('ru-RU', params);

    const isRoundedNotZero = parseFloat(rounded.replace(/[^0-9-.]/g, ''));

    if (isRoundedNotZero) {
        const cuttedArr = rounded.split(',');

        if (!isFullDigits && cuttedArr.length > 1 && cuttedArr[0].length >= 4) {
            return `${duration}${cuttedArr[0]}`;
        } else if (!isFullDigits && cuttedArr.length > 1 && cuttedArr[0].length < 4) {
            return `${duration}${cuttedArr[0]},${cuttedArr[1].slice(0, 4 - cuttedArr[0].length)}`;
        }

        return `${duration}${rounded}`;
    }

    if (Number(tempValue)) {
        // если было какое-то исходное значение, но при этом округелённое ноль, то выводим
        // - либо полное число с кучей знаков после запятой
        // - либо "схлопнутое" до >0
        return isFullDigits ? `${duration}${_value}` : '>0';
    }

    // иначе просто ноль выводим
    return '0';
};

import { ServiceBase } from './serviceBase';

const { getData, create, update, remove, get } = new ServiceBase({ path: 'admin/accounts' });

export {
    get as getAccount,
    getData as getAccounts,
    create as createAccount,
    update as updateAccount,
    remove as removeAccounts,
};

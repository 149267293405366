import * as yup from 'yup';

const cancelSchema = yup.object({
    comments_cancel: yup.string().required('Нужно указать причину отмены'),
});

const newSchema = yup.object({
    manufactured_at: yup.date().required('Нужно указать дату производства'),
    product_id: yup.number().required('Нужно указать продукт'),
    measure_id: yup.number(),
    comments: yup.string().nullable(),
    quantity: yup.number().moreThan(0, 'Количество продукта должно быть больше 0').required('Нужно указать количество'),
});

const validComponent = yup.object().shape({
    balance_id: yup.string().required('Нужно выбрать партию'),
    quantity: yup.number().required('Нужно указать количество'),
});

const validStock = yup.object().shape({
    stock_id: yup.string().required('Нужно выбрать склад'),
    quantity: yup.number().required('Нужно указать количество'),
});

const inPlanSchema = yup.object({
    manufactured_at: yup.date().required('Нужно указать дату производства'),
    product_id: yup.number().required('Нужно указать продукт'),
    measure_id: yup.number(),
    stock_id: yup.number().required('Нужно указать склад производства'),
    comments: yup
        .string()
        .nullable()
        .when('status_id', (id, schema) => {
            return Number(id) === 4
                ? schema.test('test', 'Нужно прокомментировать причину отмены', (val: number) => {
                      return val;
                  })
                : schema;
        }),
    quantity: yup.number().moreThan(0, 'Количество продукта должно быть больше 0').required('Нужно указать количество'),
    components: yup.array().when('status_id', {
        is: (id: string) => Number(id) !== 4 && Number(id) !== 1,
        then: yup.array().of(validComponent),
    }),
});

const writeOffSchema = yup
    .object()
    .nullable()
    .when(['transactions', 'status_id'], {
        is: (transactions: any, status_id: any) => {
            return (!transactions || !transactions.length) && Number(status_id) !== 4;
        },
        then: yup.object().shape({
            comments: yup.string().required('Нужно указать причину списания, если ничего не отгружаете'),
            quantity: yup.number().required('Нужно указать количество в списании, если ничего не отгружаете'),
        }),
        otherwise: yup.object({
            comments: yup
                .string()
                .test('quantity', 'Нужно указать причину списания, если списываете продукт', function (item) {
                    return (
                        (this.parent.comments && !this.parent.quantity) ||
                        (!this.parent.comments && !this.parent.quantity) ||
                        (this.parent.comments && this.parent.quantity)
                    );
                }),
            quantity: yup
                .number()
                .test('comments', 'Нужно указать количество, если комментируете списание', function (item) {
                    return (
                        (!this.parent.comments && this.parent.quantity) ||
                        (!this.parent.comments && !this.parent.quantity) ||
                        (this.parent.comments && this.parent.quantity)
                    );
                }),
        }),
    });

const inProgressSchema = yup.object({
    manufactured_at: yup.date().required('Нужно указать дату производства'),
    product_id: yup.number().required('Нужно указать продукт'),
    measure_id: yup.number(),
    write_off: writeOffSchema,
    comments: yup
        .string()
        .nullable()
        .when('status_id', (id, schema) => {
            return Number(id) === 4
                ? schema.test('test', 'Нужно прокомментировать причину отмены', (val: number) => {
                      return val;
                  })
                : schema;
        }),
    quantity: yup.number().moreThan(0, 'Количество продукта должно быть больше 0').required('Нужно указать количество'),
    transactions: yup.array().when(['status_id'], {
        is: (status_id: any) => {
            return Number(status_id) !== 4;
        },
        then: yup.array().of(validStock),
    }),
    invoice: yup.string().when(['stock_is_contractor', 'status_id'], {
        is: (isContractor: boolean, status_id: any) => isContractor && Number(status_id) !== 4,
        then: yup.string().required('Обязательно для заполнения'),
    }),
    manufacture_price: yup.number().when(['stock_is_contractor', 'status_id'], {
        is: (isContractor: boolean, status_id: any) => isContractor && Number(status_id) !== 4,
        then: yup.number().required('Обязательно для заполнения'),
    }),
});

export { newSchema, inPlanSchema, inProgressSchema, cancelSchema };

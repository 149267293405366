import { FC } from 'react';
import { TableCell, Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface CellWithImageProps {
    src?: string;
}

const useStyles = makeStyles((theme) => ({
    photo: {
        margin: 'auto',
    },
}));

export const CellWithImage: FC<CellWithImageProps> = ({ src }) => {
    const classes = useStyles();

    return (
        <TableCell align="center">
            <Avatar variant="rounded" src={`/${src}`} className={classes.photo} />
        </TableCell>
    );
};

import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, List, ListItem, Card, Typography, CardMedia } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DividerWithTitle } from '../../../components/DividerWithTitle';

import { DialogData, TableItem } from '../../../typings/table';
import { getImageUrl } from '../../../utils/getImageUrl';
import { getLocalNumber } from '../../../utils/getLocalNumber';
import { observer } from 'mobx-react';
import { appStore } from '../../../store/AppStore';

interface ViewProductProps {
    current?: DialogData;
}

const useStyles = makeStyles(() => ({
    page: {
        height: '100%',
        minWidth: 480,
    },
    cardImage: {
        margin: 'auto',
        maxWidth: 400,
        marginBottom: 12,
    },
    cardLink: {
        textDecoration: 'none',
        '&:hover > div': {
            boxShadow: '0 0 4px 4px rgba(40,40,40,.2)',
        },
    },
    card: {
        margin: '12px 0',
    },
    listItem: {
        paddingTop: 4,
        paddingBottom: 4,
    },
    listTitle: {
        fontWeight: 'bold',
        marginRight: 4,
    },
}));

export const ViewItem: FC<ViewProductProps> = observer(({ current }) => {
    const isFullDigits = appStore.isFullDigits;
    const classes = useStyles();
    const [measureQuantity, setMeasureQuantity] = useState(1);

    useEffect(() => {
        if (current?.measure_id !== 3) {
            setMeasureQuantity(1000);
        } else {
            setMeasureQuantity(1);
        }
    }, [current]);

    if (!current) {
        return null;
    }

    return (
        <div className={classes.page}>
            {current.photo ? (
                <Card className={classes.cardImage}>
                    <CardMedia
                        component="img"
                        alt="Фотография продукта"
                        image={getImageUrl(current.photo)}
                        title="Фотография продукта"
                    />
                </Card>
            ) : null}
            <DividerWithTitle>Описание</DividerWithTitle>
            <Typography variant="body1">
                <span className={classes.listTitle}>Категория:</span> {current.category_name}
            </Typography>
            <Typography variant="body1">
                <span className={classes.listTitle}>Единица учета:</span> {current.measure_name}
            </Typography>
            {current?.suppliers?.length ? <DividerWithTitle>Поставщики</DividerWithTitle> : ''}
            {current?.suppliers?.map((supplier: TableItem) => {
                return (
                    <Link
                        key={supplier.supplier_id}
                        to={`/dashboard/supplier/${supplier.supplier_id}`}
                        className={classes.cardLink}
                        target="_blank"
                    >
                        <Card className={classes.card}>
                            <List>
                                <ListItem>
                                    <Typography variant="body1">
                                        <span className={classes.listTitle}>Название:</span> {supplier.supplier_name}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <span className={classes.listTitle}>Артикул:</span> {supplier.sku || '–'}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <span className={classes.listTitle}>Срок поставки (дней):</span>{' '}
                                        {supplier.delivery_days || '–'}
                                    </Typography>
                                </ListItem>
                            </List>
                        </Card>
                    </Link>
                );
            })}
            {current?.components?.length ? <DividerWithTitle>Состав</DividerWithTitle> : ''}
            <Alert
                severity="info"
                style={{ margin: '8px 0 4px' }}
            >{`Рецепт продукта на ${measureQuantity} ${current.measure_name}`}</Alert>
            {current?.components?.map((component: TableItem) => {
                return (
                    <Link
                        key={component.component_id}
                        to={`/dashboard/product/${component.component_id}`}
                        className={classes.cardLink}
                        target="_blank"
                    >
                        <Card className={classes.card}>
                            <List>
                                <ListItem>
                                    <Typography variant="body1">
                                        {component.component_name || '–'} (
                                        {getLocalNumber(component.quantity * measureQuantity, { isFullDigits })}{' '}
                                        {component.component_measure_name})
                                    </Typography>
                                </ListItem>
                            </List>
                        </Card>
                    </Link>
                );
            })}
        </div>
    );
});

import React, { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Alert, TablePagination, Divider, Snackbar } from '@mui/material';

import { ButtonsPanel } from './ButtonsPanel';

import { Filter } from '../../components/Filter';
import { ColumnsPanel } from '../../components/ColumnsPanel';
import { ModuleTable } from '../../components/ModuleTable/ModuleTable';
import { DialogWithEdit } from './Dialogs/DialogWithEdit';
import { Page, PageProps } from '../../components/Page';
import { getErrorMessage } from '../../utils/getErrorMessage';
import { ColumnProps, TableItem } from '../../typings/table';
import { manufactureStore } from '../../store/ManufactureStore';

interface PageWithTableProps extends PageProps {
    /** Столбцы в таблице */
    columns?: ColumnProps[];
    /** Шаблон для фильтра */
    FilterForm?: FC;
    /** Дефолтные поля для карточки создания */
    initialValues?: Record<string, any>;
    /** Есть ли кнопка удаления */
    withRemove?: boolean;
    /** Отключить пагинацию */
    disabePaging?: boolean;
    /** Отключить возможность открывать элемент таблицы */
    disabeItemOpen?: boolean;
    /** Какой-то конкретный заголовок детальной карточки */
    detailTitle?: string;
    /** Какое-то конкретное поле для заголовка детальной карточки */
    detailTitleField?: string;
    /** Колбэк на формирование заголовка карточки */
    getModalTitle?: (item?: TableItem) => string;
}

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const ManufacturePage: FC<PageWithTableProps> = observer(
    ({
        FilterForm,
        initialValues,
        withRemove = true,
        detailTitle,
        detailTitleField,
        disabePaging,
        getModalTitle,
        disabeItemOpen,
        ...props
    }) => {
        const {
            changeOrder,
            itemPageLink,
            data = {},
            loadData,
            loading,
            filterParams,
            updateFilterParams,
            toggleOpenFilter,
            filterState,
            columnsPanelState,
            clearFilter,
            messageInfo,
            hideMessageInfo,
            toggleColumnsPanel,
            columns,
            toggleColumn,
        } = manufactureStore;

        const { items = [], errors } = data;

        const query = useQuery();
        const navigate = useNavigate();

        const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
        const [selectedId, setSelectedId] = useState('');
        const [error, setError] = useState('');

        const handleChangePage = (event: unknown, newPage: number) => {
            updateFilterParams({ page: newPage });
        };

        const handleToggleFilter = () => {
            toggleOpenFilter();
        };
        const handleToggleColumns = () => {
            toggleColumnsPanel();
        };

        const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
            updateFilterParams({ limit: +event.target.value, page: 0 });
        };

        useEffect(() => {
            if (loadData) {
                loadData();
            }
        }, [loadData]);

        const handleOpenEditDialog = useCallback(
            (data?: TableItem) => {
                if (data?.id) {
                    if (data.status_id > 2) {
                        // открываем новую страницу с записью, если указан параметр
                        return window.open(`${itemPageLink}/${data?.id}`);
                    }

                    setSelectedId(data?.id);
                } else {
                    setSelectedId('');
                }
                setIsOpenEditDialog(true);
            },
            [itemPageLink],
        );

        const isCreate = query.has('create');

        // Если есть параметр create, то открываем страницу создания новой записи
        useEffect(() => {
            if (isCreate) {
                handleOpenEditDialog();
            }
        }, [isCreate, handleOpenEditDialog]);

        const rightHeader = (
            <ButtonsPanel
                handleOpenEditDialog={handleOpenEditDialog}
                handleFilter={handleToggleFilter}
                handleColumns={handleToggleColumns}
                filterChanged={filterState.isChanged}
                filterOpen={filterState.isOpen}
            />
        );

        const updateQueryParams = useCallback(() => {
            query.delete('create');
            navigate({ search: query.toString() }, { replace: true });
        }, [query, navigate]);

        const onSubmitDialog = useCallback(() => {
            setError('');
            updateQueryParams();
            loadData();
        }, [updateQueryParams, loadData]);

        const onCloseDialog = useCallback(() => {
            setIsOpenEditDialog(false);
            setError('');
            updateQueryParams();
            setSelectedId('');
        }, [updateQueryParams]);

        const { page = 0, limit = 25 } = filterParams;

        const underTitle = FilterForm ? (
            <>
                <Filter
                    isVisble={filterState.isOpen}
                    FilterForm={FilterForm}
                    filterValues={filterParams.filterData}
                    onFilter={updateFilterParams}
                    onClear={clearFilter}
                />
                <ColumnsPanel columns={columns} isVisble={columnsPanelState.isOpen} onChange={toggleColumn} />
            </>
        ) : undefined;

        return (
            <Page {...props} rightHeader={rightHeader} underTitle={underTitle}>
                <Snackbar open={!!messageInfo} onClose={hideMessageInfo}>
                    <Alert onClose={hideMessageInfo} severity="info">
                        {messageInfo}
                    </Alert>
                </Snackbar>
                <DialogWithEdit
                    itemPageLink={itemPageLink}
                    id={selectedId}
                    open={isOpenEditDialog}
                    onError={(errors) => {
                        setError(getErrorMessage(errors));
                    }}
                    onClose={onCloseDialog}
                    onConfirm={onSubmitDialog}
                    error={error}
                />
                <ModuleTable
                    columns={columns}
                    data={items}
                    orderBy={filterParams.order}
                    orderDirection={filterParams.orderDir}
                    changeOrder={changeOrder}
                    canSelect={false}
                    handleEdit={!disabeItemOpen ? handleOpenEditDialog : () => {}}
                    page={page}
                    loading={loading}
                    errors={errors}
                />
                <Divider />
                {Boolean(items?.length || (!items?.length && page > 0)) && !disabePaging && (
                    <TablePagination
                        style={{ whiteSpace: 'nowrap', minHeight: 52 }}
                        rowsPerPageOptions={[50, 100, 200]}
                        component="div"
                        count={-1}
                        rowsPerPage={limit}
                        page={page}
                        labelRowsPerPage="Записей на страницу:"
                        labelDisplayedRows={({ from, to }) => {
                            const lastIndex = Math.min(items.length + page * limit, to);
                            return from <= lastIndex ? `Записи с ${from} по ${lastIndex}` : 'Данных нет';
                        }}
                        nextIconButtonProps={{
                            disabled: items.length < Number(limit) || items.length === 0,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </Page>
        );
    },
);

import { FC } from 'react';
import * as yup from 'yup';

import { PageWithTable } from '../../components/Page/withTable/PageWithTable';
import { InputText, Checkbox } from '../../components/Inputs';

import { PageProps } from '../../components/Page';
import { stocksStore } from '../../store/StocksStore';

interface StocksPageProps extends PageProps {
    title?: string;
}

const validationSchema = yup.object({
    name: yup.string().required('Наименование обязателено'),
    description: yup.string().nullable(),
});

const initialValues = {
    name: '',
    description: '',
    /** склад используется для производства */
    is_manufacture: false,
    /** склад используется для производства по умолчанию */
    is_manufacture_default: false,
    /** склад подрядчики для производства */
    is_contractor: false,
    /** склад, в который "отгружаются" продукты после производства (ИЗ ПРОИЗВОДСТВА) */
    is_ready_default: false,
};

const EditForm: FC = () => (
    <form>
        <InputText name="name" label="Наименование" required fullWidth />
        <InputText name="description" rows={3} multiline label="Комментарий" fullWidth />
        <Checkbox name="is_manufacture_default" label="Для производства" />
        <Checkbox name="is_ready_default" label="Для готовой продукции" />
        <Checkbox name="is_contractor" label="Подрядчик" />
    </form>
);

export const StocksPage: FC<StocksPageProps> = ({ title, ...props }) => {
    return (
        <PageWithTable
            title={title}
            store={stocksStore}
            EditForm={EditForm}
            validationSchema={validationSchema}
            initialValues={initialValues}
            disableColumnsPanel
            withEditButton
            isOpenDetail
            {...props}
        />
    );
};

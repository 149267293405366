import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, Table, TableContainer, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

import { StocksForm } from './StocksForm';

import { InputText, InputNumber } from '../../../components/Inputs';
import { DividerWithTitle } from '../../../components/DividerWithTitle';

import { ColumnProps } from '../../../typings/table';
import { getShortName } from '../../../utils/getShortName';
import { getLocalNumber } from '../../../utils/getLocalNumber';
import { observer } from 'mobx-react';
import { appStore } from '../../../store/AppStore';

import { FormProps } from '..';
import { FormikValues, useFormikContext } from 'formik';

const useStyles = makeStyles(() => ({
    page: {
        height: '100%',
        minWidth: 480,
    },
    listTitle: {
        fontWeight: 'bold',
        marginRight: 4,
    },
    card: {
        margin: '12px 0',
    },
    row: {
        textDecoration: 'none',
        '&:hover': {
            background: '#f5f5f5',
        },
    },
}));

export const InProgressForm: FC<FormProps> = observer(() => {
    const isFullDigits = appStore.isFullDigits;
    const classes = useStyles();
    const { values, setFieldValue } = useFormikContext<FormikValues>();

    const { components } = values;

    const isContractorStock = !!components?.find((component: any) => component.stock_is_contractor);
    const stockName = components?.[0]?.stock_name;

    useEffect(() => {
        setFieldValue('stock_is_contractor', isContractorStock);
    }, [setFieldValue, isContractorStock]);

    const columns = [
        { field: 'product_name', title: 'Продукт' },
        { field: 'category_name', title: 'Категория' },
        { field: 'quantity', title: 'Количество' },
        { field: 'balance_manufactured_at', title: 'Дата производства' },
        { field: 'balance_created_at', title: 'Дата поступления' },
        { field: 'supplier_name', title: 'Поставщик' },
        { field: 'balance_mcost', title: 'Себестоимость единицы' },
    ];

    return (
        <form>
            <Grid container spacing={3} style={{ minWidth: '100%' }}>
                <Grid item xs={6}>
                    <DividerWithTitle>Общие параметры</DividerWithTitle>
                    <Typography variant="body1">
                        <span className={classes.listTitle}>Дата:</span>
                        {values.manufactured_at ? `${moment(values.manufactured_at).format('DD.MM.YYYY')}` : '–'}
                    </Typography>
                    <Typography variant="body1">
                        <span className={classes.listTitle}>Лот:</span>
                        {values.lot || '–'}
                    </Typography>
                    <Typography variant="body1">
                        <span className={classes.listTitle}>Категория:</span> {values.category_name || '–'}
                    </Typography>
                    <Typography variant="body1">
                        <span className={classes.listTitle}>Продукт:</span> {values.product_name || '–'}
                    </Typography>
                    <Typography variant="body1">
                        <span className={classes.listTitle}>Количество:</span>{' '}
                        {`${getLocalNumber(values.quantity, { isFullDigits })} ${values.measure_name}`}
                    </Typography>
                    <Typography variant="body1">
                        <span className={classes.listTitle}>Ответственный:</span>
                        {values.employee_id
                            ? getShortName(
                                  `${values.employee_last_name} ${values.employee_first_name} ${values.employee_patronymic_name}`,
                              )
                            : '–'}
                    </Typography>
                    <Typography variant="body1">
                        <span className={classes.listTitle}>Склад производства:</span> {stockName}
                        {isContractorStock ? ' (Подрядчик)' : ''}
                    </Typography>
                    {isContractorStock && <InputText name="invoice" label="Номер счёта" fullWidth />}
                    {isContractorStock && <InputNumber name="manufacture_price" label="Стоимость работ" fullWidth />}
                    <InputText
                        name="comments"
                        rows={3}
                        multiline
                        label="Комментарий"
                        style={{
                            width: 400,
                        }}
                    />
                    <DividerWithTitle>Списание</DividerWithTitle>
                    <InputNumber
                        name={`write_off.quantity`}
                        label="Количество"
                        measure={values.measure_name}
                        fullWidth
                        required
                    />
                    <InputText name="write_off.comments" rows={3} multiline label="Причина списания" fullWidth />
                </Grid>

                <Grid item xs={6}>
                    <DividerWithTitle>Склады</DividerWithTitle>
                    <StocksForm />
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={3} style={{ minWidth: 600 }}>
                <Grid item width="100%">
                    <DividerWithTitle>Компоненты</DividerWithTitle>
                    <TableContainer className={classes.card}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column: ColumnProps) => {
                                        if (column.hidden) {
                                            return null;
                                        }

                                        return (
                                            <TableCell align={column.align} key={column.field}>
                                                <span className={classes.listTitle}>{column.title}</span>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {components.map((item: any) => {
                                    return (
                                        <TableRow
                                            key={item.id}
                                            component={Link}
                                            target="_blank"
                                            to={`/dashboard/balanceDetail?balance=${item.balance_id}`}
                                            className={classes.row}
                                        >
                                            <TableCell>{item.product_name}</TableCell>
                                            <TableCell>{item.category_name}</TableCell>
                                            <TableCell>{`${getLocalNumber(item.quantity, {
                                                isFullDigits,
                                            })} ${item.measure_name}`}</TableCell>
                                            <TableCell>
                                                {item.manufactured_at
                                                    ? moment(item.manufactured_at).format('DD.MM.YYYY')
                                                    : '–'}
                                            </TableCell>
                                            <TableCell>{moment(item.created_at).format('DD.MM.YYYY')}</TableCell>
                                            <TableCell>{item.supplier_name}</TableCell>
                                            <TableCell>
                                                {getLocalNumber(item.balance_mcost, { isFullDigits })} {'р.'}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </form>
    );
});

import { FC, useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { FormikValues, FieldArray, useFormikContext } from 'formik';
import { Alert, Paper, Typography, MenuItem, CircularProgress, Button, Tooltip } from '@mui/material';
import { HelpOutlineOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { DividerWithTitle } from '../../../components/DividerWithTitle';
import { SelectWithData, InputNumber } from '../../../components/Inputs';

import { getBalances } from '../../../services/transactions';
import { getProduct } from '../../../services/products';
import { TableItem } from '../../../typings/table';
import { BalanceProps } from '../../../store/BalanceStore';
import { getLocalNumber } from '../../../utils/getLocalNumber';
import { appStore } from '../../../store/AppStore';
import { observer } from 'mobx-react';

interface ComponentFormProps {
    index: number;
    productId?: number;
    component: TableItem;
    quantity: number;
    quantityType?: number;
}

interface ComponentsFormProps {
    productId?: number;
    quantityType?: number;
    quantity?: number;
}

const useStyles = makeStyles(() => ({
    title: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 18,
    },
    itemDisabled: {
        opacity: 0.5,
        pointerEvents: 'none',
    },
    titleIcon: {
        marginLeft: 4,
        width: 18,
        height: 18,
    },
    contentBase: {
        margin: '12px 0',
        maxHeight: '430px',
        overflow: 'auto',
        position: 'relative',
        borderRadius: 6,
        backgroundColor: '#f5f5f5',
    },
    contentWithItems: {
        padding: 12,
        border: '1px solid rgb(200, 200, 200)',
    },
    itemCard: {
        padding: '24px 12px',
        margin: '12px 0',
    },
    list: {
        padding: 0,
    },
    menuItem: {
        display: 'block',
        borderBottom: '1px solid rgb(200, 200, 200)',
    },
    listItem: {
        display: 'block',
        paddingTop: 4,
        paddingBottom: 4,
    },
    listTitle: {
        fontWeight: 'bold',
        marginRight: 4,
    },
    listItemRed: {
        color: 'red',
    },
}));

// Подготавливем отображение партий с продуктом
const getBatchesView = (items: BalanceProps[], from: number, isFullDigits?: boolean) => {
    return items.map((item: BalanceProps) => {
        const _item: BalanceProps = {
            ...item,
            product_name: `${item.product_name}. Остаток: ${getLocalNumber(item?.quantity, { isFullDigits })} ${
                item?.measure_name
            }`,
            disabled: from > item.balance || item.stock_is_contractor,
            isContractor: item.stock_is_contractor,
        };

        return _item;
    }, []);
};

// Продукт из состава
const ComponentEditForm: FC<ComponentFormProps> = observer(({ index, component, quantity = 0, quantityType }) => {
    const isFullDigits = appStore.isFullDigits;
    const classes = useStyles();
    const [batches, setBatches] = useState<BalanceProps[]>([]);
    const [isLoading, setLoading] = useState(false);

    const { setFieldValue, values } = useFormikContext<FormikValues>();

    const currentQuantity = values.components[index]?.quantity;

    const needComponentQuantity = quantity * component.quantity;

    useEffect(() => {
        if (!component.component_id) {
            return setBatches([]);
        }

        setLoading(true);

        // Запрашиваем партии с требуемым продуктом на складах
        getBalances({
            filterData: {
                in_stock: true,
                product_id: component.component_id,
            },
            orderDir: 'asc',
            order: 'manufactured_at',
        })
            .then((data) => {
                if (data.rows) {
                    setBatches(
                        getBatchesView(
                            data.rows.filter((row: any) => !row.stock_is_manufacture),
                            needComponentQuantity,
                        ),
                    );
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [component, needComponentQuantity, index, quantityType]);

    useEffect(() => {
        setFieldValue(`components.${index}.quantity`, needComponentQuantity);
    }, [setFieldValue, quantityType, index, needComponentQuantity, component, quantity]);

    useEffect(() => {
        // устанавливаем дефолтное значение, если в поле пусто и есть партия
        if (!values.components[index]?.balance_id) {
            const notDisabled = batches.find((item) => !item.disabled);

            setFieldValue(`components.${index}.balance_id`, notDisabled?.id || undefined);
        }
    }, [values.components, setFieldValue, batches, index]);

    useEffect(() => {
        if (values.measure_id !== 3) {
            setFieldValue('measure_quantity', 1000);
        } else {
            setFieldValue('measure_quantity', 1);
        }
    }, [values.measure_id, setFieldValue]);

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <>
            {quantityType !== 1 && (
                <InputNumber
                    name={`components.${index}.quantity`}
                    label={'Количество'}
                    withoutRound
                    fullWidth
                    required
                />
            )}
            {quantityType === 1 && (
                <Typography className={classes.listItem} variant="body1">
                    <span className={classes.listTitle}>Требуемое количество:</span>{' '}
                    {values.components[index]?.quantity}
                </Typography>
            )}
            {batches.filter((item) => item.balance >= values.components[index]?.quantity).length ? (
                <SelectWithData
                    name={`components.${index}.balance_id`}
                    data={batches}
                    label="Наличие на складе"
                    fieldName="product_name"
                    fieldId="id"
                    withoutRefresh
                    fullWidth
                    required
                    withoutAutocomplete
                    menuItem={(item: BalanceProps) => {
                        return (
                            <MenuItem
                                className={clsx(classes.menuItem, { [classes.itemDisabled]: item.disabled })}
                                key={item.id}
                                value={item.id}
                            >
                                <Typography className={classes.listItem} variant="body1">
                                    <span className={classes.listTitle}>Производитель:</span>{' '}
                                    {item.manufacturer_name || '–'}
                                </Typography>
                                {!item.manufacturer_is_own && (
                                    <Typography className={classes.listItem} variant="body1">
                                        <span className={classes.listTitle}>Описание:</span> {item.description || '–'}
                                    </Typography>
                                )}
                                <Typography className={classes.listItem} variant="body1">
                                    <span className={classes.listTitle}>Лот:</span>
                                    {item.lot || '–'}
                                </Typography>
                                <Typography
                                    className={clsx(classes.listItem, {
                                        [classes.listItemRed]: item.disabled && !item.isContractor,
                                    })}
                                    variant="body1"
                                >
                                    <span className={classes.listTitle}>Остаток:</span>
                                    {`${getLocalNumber(item.balance, { isFullDigits })} ${item.measure_name}`}
                                </Typography>
                                <Typography className={classes.listItem} variant="body1">
                                    <span className={classes.listTitle}>Дата поступления:</span>
                                    {moment(item.created_at).format('DD.MM.YYYY')}
                                </Typography>
                                <Typography className={classes.listItem} variant="body1">
                                    <span className={classes.listTitle}>Склад:</span> {`${item.stock_name}`}
                                    {item.isContractor ? ' (Склад поставщика)' : ''}
                                </Typography>
                            </MenuItem>
                        );
                    }}
                />
            ) : (
                <Alert severity="error" style={{ margin: '4px 0' }}>
                    Отсутствует необходимое количество продукта на складах
                </Alert>
            )}
            {values.quantity_type === 2 ? (
                <Button
                    onClick={() => {
                        setFieldValue(`quantity`, currentQuantity / component.quantity);
                    }}
                >
                    Рассчитать необходимый состав
                </Button>
            ) : null}
        </>
    );
});

export const ComponentsFormFromStock: FC<ComponentsFormProps> = observer(({ productId, quantity, quantityType }) => {
    const isFullDigits = appStore.isFullDigits;

    const contentRef = useRef<HTMLDivElement>(null);
    const classes = useStyles();
    const [products, setProducts] = useState<BalanceProps[]>([]);
    const [loading, setLoading] = useState(false);

    const { setFieldValue, values } = useFormikContext<FormikValues>();

    const { measure_quantity, measure_name } = values;

    useEffect(() => {
        if (!productId) {
            setProducts([]);
            setFieldValue('components', []);
            return;
        }
        setLoading(true);
        // Запрашиваем состав нужного продукта
        getProduct(String(productId))
            .then((data) => {
                if (data?.data?.components) {
                    setProducts(data.data.components);
                    setFieldValue('components', data.data.components);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [setFieldValue, productId]);

    if (loading) {
        return (
            <Alert severity="info" style={{ margin: '4px 0' }}>
                Загрузка...
            </Alert>
        );
    }

    if (!productId) {
        return (
            <Alert severity="info" style={{ margin: '4px 0' }}>
                Не выбран продукт
            </Alert>
        );
    }

    if (!products.length) {
        return (
            <Alert severity="info" style={{ margin: '4px 0' }}>
                Нет состава для производства
            </Alert>
        );
    }

    return (
        <>
            <DividerWithTitle>
                <Tooltip
                    title={
                        <>
                            <Typography variant="body2">{`Рецепт продукта (${measure_quantity} ${measure_name}):`}</Typography>
                            {products.map((component: TableItem) => (
                                <li style={{ fontSize: 12, lineHeight: 1.4 }}>{`${
                                    component.component_name
                                } – ${getLocalNumber(component.quantity * measure_quantity, { isFullDigits })} ${
                                    component.component_measure_name
                                }`}</li>
                            ))}
                        </>
                    }
                    arrow
                >
                    <Typography className={classes.title}>
                        {'Состав'}
                        <HelpOutlineOutlined className={classes.titleIcon} />
                    </Typography>
                </Tooltip>
            </DividerWithTitle>
            <FieldArray name="components">
                {() => {
                    return (
                        <>
                            <div
                                className={clsx(products.length ? classes.contentWithItems : '', classes.contentBase)}
                                ref={contentRef}
                            >
                                {products.map((component: TableItem, index: number) => {
                                    return (
                                        <Paper elevation={3} key={component.id} className={classes.itemCard}>
                                            {/* TODO: нужен тип кол-ва mesure_name */}
                                            <DividerWithTitle>{`${component.component_sku || ''} – ${
                                                component.component_name
                                            }`}</DividerWithTitle>
                                            <ComponentEditForm
                                                index={index}
                                                productId={productId}
                                                quantityType={quantityType}
                                                component={component}
                                                quantity={quantity || 0}
                                            />
                                        </Paper>
                                    );
                                })}
                            </div>
                        </>
                    );
                }}
            </FieldArray>
        </>
    );
});

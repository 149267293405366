import { FC } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { TextField, TextFieldProps, Typography } from '@mui/material';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { FormikValues, useFormikContext } from 'formik';

interface InputProps extends NumberFormatProps {
    name: string;
    label: string;
    maxLength?: number;
    measure?: string;
    /** поле маленькой ширины */
    isShort?: boolean;
    /** без скругления */
    withoutRound?: boolean;
    mantissa?: number;
    withoutDecimal?: boolean;
    textFieldSize?: 'small' | 'medium';
}

const useStyles = makeStyles(() => ({
    formControl: {
        display: 'flex',
        alignItems: 'center',
    },
    short: {
        maxWidth: 160,
    },
    measure: {
        color: 'rgba(0, 0, 0, 0.54)',
        marginLeft: 8,
    },
}));

function SmallInput(props: TextFieldProps) {
    return <TextField size="small" margin="none" {...props} />;
}

export const InputNumber: FC<InputProps> = ({
    name,
    label,
    maxLength,
    value,
    measure,
    isShort,
    withoutRound,
    mantissa = 4,
    disabled,
    withoutDecimal,
    textFieldSize = 'medium',
    ...props
}) => {
    const classes = useStyles();

    const { values, errors, handleChange } = useFormikContext<FormikValues>();

    const currentValue = _.get(values, name);
    const currentError = _.get(errors, name);

    return (
        <div className={clsx(classes.formControl)}>
            <NumberFormat
                className={clsx({
                    [classes.short]: isShort,
                })}
                name={name}
                id={name}
                label={label}
                error={Boolean(currentError)}
                helperText={currentError ? String(currentError) : undefined}
                variant="outlined"
                value={currentValue}
                onChange={handleChange}
                customInput={textFieldSize === 'small' ? SmallInput : TextField}
                disabled={disabled}
                decimalSeparator={withoutDecimal ? '' : '.'}
                {...props}
            />
            {measure ? (
                <Typography variant="body1" className={classes.measure}>
                    {measure}
                </Typography>
            ) : null}
        </div>
    );
};

import React from 'react';
import { LayoutRouteProps } from 'react-router-dom';
import { Group, ListAlt, PrecisionManufacturing, House, AssignmentInd, ShoppingBasket } from '@mui/icons-material';

import { StocksPage as Stocks } from '../pages/Stocks';
import { ManufacturersPage as Manufacturers } from '../pages/Manufacturers';
import { EmployeesPage as Employees } from '../pages/Employees';
import { CategoriesPage as Categories } from '../pages/Categories';
import { SuppliersPage } from '../pages/Suppliers';
import { BuyersPage } from '../pages/Buyers';

export interface NavigationProps extends LayoutRouteProps {
    name: string;
    icon?: React.ReactElement;
    tabs?: NavigationProps[];
    inProgress?: boolean;
}

export const listsRoutes: NavigationProps[] = [
    {
        path: '/dashboard/employees',
        name: 'Сотрудники',
        element: <Employees title="Сотрудники" />,
        icon: <Group fontSize="small" />,
    },
    {
        path: '/dashboard/categories',
        name: 'Категории',
        element: <Categories title="Категории" />,
        icon: <ListAlt fontSize="small" />,
    },
    {
        path: '/dashboard/manufacturers',
        name: 'Производители',
        element: <Manufacturers title="Производители" />,
        icon: <PrecisionManufacturing fontSize="small" />,
    },
    {
        path: '/dashboard/suppliers',
        name: 'Поставщики',
        element: <SuppliersPage title="Поставщики" />,
        icon: <AssignmentInd />,
    },
    {
        path: '/dashboard/buyers',
        name: 'Покупатели',
        element: <BuyersPage title="Покупатели" />,
        icon: <ShoppingBasket />,
    },
    {
        path: '/dashboard/stocks',
        name: 'Склады',
        element: <Stocks title="Склады" />,
        icon: <House />,
    },
];

import { FC, useCallback, useState } from 'react';
import { Button, ButtonProps, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { ConfirmDialog } from '../../Dialog/withConfirm/DialogWithConfirm';

const useStyles = makeStyles(() => ({
    removeButton: {
        color: '#fff',
        backgroundColor: '#d20',
        '&:hover': {
            backgroundColor: '#d20',
            opacity: 0.8,
        },
    },
    removeIcon: {
        color: '#d20',
    },
}));

interface ButtonWithRemoveProps extends ButtonProps {
    buttonText?: string;
    onRemove: () => void;
}

export const ButtonWithRemove: FC<ButtonWithRemoveProps> = ({
    onRemove,
    title = 'Удаление',
    buttonText = 'Удалить',
    disabled,
}) => {
    const classes = useStyles();

    const [needConfirmation, setNeedConfirmation] = useState(false);

    const handleButtonClick = useCallback(() => {
        setNeedConfirmation(true);
    }, []);

    const handleCloseDialog = useCallback(() => {
        setNeedConfirmation(false);
    }, []);

    const handleConfirmDialog = useCallback(() => {
        onRemove();
        handleCloseDialog();
    }, [onRemove, handleCloseDialog]);

    return (
        <>
            <ConfirmDialog
                open={needConfirmation}
                title={title}
                message="Вы уверены, что хотите удалить выбранные записи?"
                onConfirm={handleConfirmDialog}
                onClose={handleCloseDialog}
            />
            {buttonText ? (
                <Button
                    className={classes.removeButton}
                    variant="contained"
                    onClick={handleButtonClick}
                    disabled={disabled}
                    startIcon={<Delete />}
                >
                    {buttonText}
                </Button>
            ) : (
                <IconButton size="small" className={classes.removeIcon} onClick={handleButtonClick} disabled={disabled}>
                    <Delete fontSize="small" />
                </IconButton>
            )}
        </>
    );
};

import { FC } from 'react';
import { Link } from 'react-router-dom';
import { TableCell, TableCellProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CellWithDateTime } from './CellWithDateTime';
import { CellWithDate } from './CellWithDate';
import { CellWithImage } from './CellWithImage';
import { CellWithBadge } from './CellWithBadge';
import { CellWithTransaction } from './CellWithTransaction';
import { CellWithManufactureStatus } from './CellWithManufactureStatus';

import { getShortName } from './../../../utils/getShortName';
import { getLocalNumber } from './../../../utils/getLocalNumber';
import { TableItem, ColumnProps, CellType } from './../../../typings/table';
import { TRAN_INVENTORY_ID, TRAN_SALE_ID } from '../../../const/transactions';
import { observer } from 'mobx-react';
import { appStore } from '../../../store/AppStore';
import { padZero } from '../../../utils/padZero';
import { nl2br } from '../../../utils/nl2br';

interface CellProps extends TableCellProps {
    value?: any;
    type?: CellType;
    item: TableItem;
    column?: ColumnProps;
}

const useStyles = makeStyles(() => ({
    cellContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '100%',
    },
    cellContent: {
        display: 'flex',
        height: '100%',
        width: '100%',
    },
    cellLeft: {
        width: '100%',
        fontSize: '.9rem',
        textAlign: 'right',
        flex: 1,
    },
    cellRight: {
        opacity: 0.4,
        fontSize: '.8rem',
        minWidth: 40,
        display: 'flex',
        alignItems: 'center',
    },
    cellCenter: {
        minWidth: 4,
        opacity: 0.5,
    },
    cellEnd: {
        display: 'flex',
        height: 'auto',
        alignItems: 'center',
        minWidth: 20,
        justifyContent: 'flex-end',
    },
}));

export const CellConfigurator: FC<CellProps> = observer(({ value, type, item, column, ...rest }) => {
    const isFullDigits = appStore.isFullDigits;
    const classes = useStyles();

    if (typeof value === 'string' && type === 'datetime') {
        return <CellWithDateTime value={value} {...rest} />;
    }

    if (typeof value === 'string' && type === 'date') {
        return <CellWithDate value={value} {...rest} />;
    }

    if (typeof value === 'string' && type === 'img') {
        return <CellWithImage src={value} {...rest} />;
    }

    if (type === 'number') {
        const valueFormated = getLocalNumber(value, { isFullDigits });
        const arr = valueFormated.split(',');

        const center = arr.length > 1 ? ',' : '';

        return (
            <TableCell {...rest}>
                <div className={classes.cellContainer}>
                    <div className={classes.cellContent}>
                        <div className={classes.cellLeft}>{arr[0]}</div>
                        <div className={classes.cellCenter}>{center}</div>
                        <div className={classes.cellRight}>{arr[1] || ''}</div>
                    </div>
                </div>
            </TableCell>
        );
    }

    if (type === 'stock_name') {
        const balance = item.stocks?.find((stock: any) => String(stock.stock_id) === column?.field)?.balance;
        const measure = item?.measure_name;

        const valueFormated = getLocalNumber(balance, { isFullDigits });
        const arr = valueFormated.split(',');

        const center = arr.length > 1 ? ',' : '';

        return (
            <TableCell {...rest}>
                <div
                    className={classes.cellContainer}
                    style={{ opacity: !Number(balance) ? 0.4 : 1, whiteSpace: 'nowrap' }}
                >
                    <div className={classes.cellContent}>
                        <div className={classes.cellLeft}>{arr[0]}</div>
                        <div className={classes.cellCenter}>{center}</div>
                        <div className={classes.cellRight}>{arr[1] || ''}</div>
                    </div>
                    <div className={classes.cellEnd}>{measure}</div>
                </div>
            </TableCell>
        );
    }

    if (type === 'quantity') {
        const measure = item?.measure_name;

        const valueFormated = getLocalNumber(value, { isFullDigits });
        const arr = valueFormated.split(',');

        const center = arr.length > 1 ? ',' : '';

        return (
            <TableCell {...rest}>
                <div className={classes.cellContainer}>
                    <div className={classes.cellContent}>
                        <div className={classes.cellLeft}>{arr[0]}</div>
                        <div className={classes.cellCenter}>{center}</div>
                        <div className={classes.cellRight}>{arr[1] || ''}</div>
                    </div>
                    <div className={classes.cellEnd}>{measure}</div>
                </div>
            </TableCell>
        );
    }

    if (type === 'quantityTransaction') {
        const measure = item?.measure_name;
        const withSignSymbol = item?.tran_id !== TRAN_SALE_ID && item?.tran_id !== TRAN_INVENTORY_ID;

        const valueFormated = getLocalNumber(value, { isFullDigits, withSignSymbol });
        const arr = valueFormated.split(',');

        const center = arr.length > 1 ? ',' : '';

        return (
            <TableCell {...rest}>
                <div className={classes.cellContainer}>
                    <div className={classes.cellContent}>
                        <div className={classes.cellLeft}>{arr[0]}</div>
                        <div className={classes.cellCenter}>{center}</div>
                        <div className={classes.cellRight}>{arr[1] || ''}</div>
                    </div>
                    <div className={classes.cellEnd}>{measure}</div>
                </div>
            </TableCell>
        );
    }

    if (typeof value === 'number' && type === 'transaction') {
        return <CellWithTransaction type={value} {...rest} />;
    }

    if (type === 'manufactureStatus') {
        return <CellWithManufactureStatus type={value} status={item.status_name} {...rest} />;
    }

    if (type === 'photo') {
        return <CellWithImage src={value} {...rest} />;
    }

    if (type === 'boolean') {
        return <CellWithBadge isTrue={value} {...rest} />;
    }

    if (type === 'withDash') {
        return <TableCell {...rest}>{value ? String(value) : '–'}</TableCell>;
    }

    if (type === 'withDashAndPrice') {
        if (!value) {
            return (
                <TableCell {...rest}>
                    <div className={classes.cellContainer}>
                        <div className={classes.cellContent}>
                            <div className={classes.cellLeft}>–</div>
                            <div className={classes.cellCenter}></div>
                            <div className={classes.cellRight}></div>
                        </div>
                        <div className={classes.cellEnd}>р.</div>
                    </div>
                </TableCell>
            );
        }

        const valueFormated = getLocalNumber(value, { isFullDigits });
        const arr = String(valueFormated).split(',');

        const center = arr[1] && arr[1] !== '00' ? ',' : '';

        return (
            <TableCell {...rest}>
                <div className={classes.cellContainer}>
                    <div className={classes.cellContent}>
                        <div className={classes.cellLeft}>{arr[0]}</div>
                        <div className={classes.cellCenter}>{center}</div>
                        <div className={classes.cellRight}>{arr[1] === '00' ? '' : arr[1]}</div>
                    </div>
                    <div className={classes.cellEnd}>р.</div>
                </div>
            </TableCell>
        );
    }

    if (type === 'suppliers') {
        if (value?.length) {
            return (
                <TableCell {...rest}>
                    <ul style={{ margin: 0, padding: 0 }}>
                        {value.map((supplier: any, index: number) => {
                            return (
                                <li key={index}>
                                    <Link
                                        target="_blank"
                                        to={`/dashboard/supplier/${supplier.supplier_id}`}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        {[supplier.supplier_name, supplier.sku].filter(Boolean).join(' – ')}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </TableCell>
            );
        }

        return (
            <TableCell {...rest}>
                <span style={{ opacity: 0.6, whiteSpace: 'nowrap' }}>–</span>
            </TableCell>
        );
    }

    if (type === 'components') {
        if (value?.length) {
            return (
                <TableCell {...rest}>
                    <ul style={{ margin: 0, padding: 0 }}>
                        {value.map((component: any, index: number) => {
                            return (
                                <li key={index}>
                                    <Link
                                        target="_blank"
                                        to={`/dashboard/product/${component.component_id}`}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        {[component.component_name, component.sku].filter(Boolean).join(' – ')}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </TableCell>
            );
        }

        return <TableCell {...rest} />;
    }

    if (type === 'stock_items') {
        if (value?.length) {
            return (
                <TableCell {...rest}>
                    <ul style={{ margin: 0, padding: 0 }}>
                        {value.map((component: any, index: number) => {
                            return (
                                <li key={index} style={{ whiteSpace: 'nowrap' }}>
                                    {[
                                        component.product_name,
                                        `${getLocalNumber(component.balance, { isFullDigits })} ${
                                            component.measure_name
                                        }`,
                                    ]
                                        .filter(Boolean)
                                        .join(' – ')}
                                </li>
                            );
                        })}
                    </ul>
                </TableCell>
            );
        }

        return <TableCell {...rest} />;
    }

    if (value && typeof value === 'object' && value.length) {
        return (
            <TableCell {...rest}>
                <em>Отображение данных еще не готово</em>
            </TableCell>
        );
    }

    if (typeof value === 'string' && type === 'shortname') {
        return <TableCell {...rest}>{getShortName(value)}</TableCell>;
    }

    if (type === 'employee' || type === 'responsible' || type === 'developer') {
        const prefix = type + '_';

        return (
            <TableCell {...rest}>
                {item[prefix + 'id']
                    ? getShortName(
                          `${item[prefix + 'last_name']} ${item[prefix + 'first_name']} ${
                              item[prefix + 'patronymic_name']
                          }`,
                      )
                    : '–'}
            </TableCell>
        );
    }

    if (type === 'withPad') {
        if (!value) {
            return <TableCell {...rest} />;
        }

        const valueFormated = String(value);
        return <TableCell {...rest}>{padZero(valueFormated, column?.padSize)}</TableCell>;
    }

    const cellValue = value ? String(value) : '';
    const valueFormated = column?.multiline ? nl2br(cellValue) : cellValue;

    return <TableCell {...rest}>{valueFormated}</TableCell>;
});

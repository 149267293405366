import { FC, useState } from 'react';

import moment from 'moment';
import { observer } from 'mobx-react';
import { Button, Box, Tooltip } from '@mui/material';
import { useFormikContext } from 'formik';

import { FormProps } from '../../../typings/table';
import { InventoryProps, SignRecord } from '../../../store/InventoriesStore';
import { signInventory } from '../../../services/inventories';
import { ConfirmDialog } from '../../../components/Dialog/withConfirm/DialogWithConfirm';
import { authStore } from '../../../store/AuthStore';

export const EditTitle: FC<FormProps> = observer(({ isEdit, reloadData }) => {
    const { values: data, setFieldValue } = useFormikContext<InventoryProps>();
    const [confirmation, setConfirmation] = useState(false);

    const isSigned = Boolean(data?.signs?.find((s: SignRecord) => s.created_by_id === Number(authStore.user.id)));

    function handleSign() {
        signInventory(String(data?.id)).then((data) => {
            if (data?.data) {
                setFieldValue('signs', data?.data?.signs);
                reloadData();
            }
        });
    }

    if (!isEdit) {
        return null;
    }

    return (
        <Box p={1} flex="row">
            <ConfirmDialog
                open={confirmation}
                title="Подписать инвентаризацию"
                onConfirm={() => {
                    handleSign();
                    setConfirmation(false);
                }}
                onClose={() => setConfirmation(false)}
            >
                Вы уверены что хотите подписать инвентаризацию?
            </ConfirmDialog>
            <Tooltip
                title={
                    data?.signs?.length ? (
                        <div>
                            {data?.signs?.map((s: SignRecord, index: number) => (
                                <Box key={index} m={1}>
                                    {s.created_by_name} ({moment(s.created_at).format('DD.MM.YYYY')})
                                </Box>
                            ))}
                        </div>
                    ) : (
                        ''
                    )
                }
            >
                <span>
                    <Button variant="outlined" onClick={() => setConfirmation(true)} disabled={isSigned}>
                        {isSigned ? <span>Подписали: {data?.signs?.length}</span> : 'Подписать'}
                    </Button>
                </span>
            </Tooltip>
        </Box>
    );
});

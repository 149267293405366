import { observable, configure } from 'mobx';
import { TableStoreBase, TableData } from './BaseTableStore';

import { getEmployees, createEmployee, removeEmployee, updateEmployee } from '../services/employess';
import { ColumnProps } from '../typings/table';

configure({ enforceActions: 'observed' });

export interface EmployeeProps {
    [key: string]: any;
    id: number | string;
    first_name?: string;
    patronymic_name?: string;
    last_name?: string;
    phone: string;
    email: string;
    job: string;
    photo?: string;
}

export interface IEmployees extends TableData {
    items?: EmployeeProps[];
}

export class EmployeesStore extends TableStoreBase {
    constructor() {
        super('employees');

        this.filterParams.order = 'last_name';
        this.filterParams.orderDir = 'asc';

        this.getData = getEmployees;
        this.removeItem = removeEmployee;
        this.updateItem = updateEmployee;
        this.createItem = createEmployee;
    }

    @observable columns: ColumnProps[] = [
        {
            title: 'Фотография',
            field: 'photo',
            type: 'photo',
        },
        { field: 'last_name', title: 'Фамилия', sortable: true },
        { field: 'first_name', title: 'Имя', sortable: true },
        { field: 'patronymic_name', title: 'Отчество', sortable: true },
        {
            title: 'Должность',
            sortable: true,
            field: 'job',
        },
        {
            title: 'Контактный номер телефона',
            field: 'phone',
            sortable: true,
        },
        {
            title: 'E-mail',
            field: 'email',
            sortable: true,
        },
    ];

    @observable data: IEmployees = {};
}

export const employeesStore = new EmployeesStore();

import { FC, useMemo, useState, useCallback } from 'react';
import { Grid } from '@mui/material';

import { InputText, InputNumber, InputDate, SelectWithData } from '../../../components/Inputs';

import { FormProps } from '..';
import { FormikValues, useFormikContext } from 'formik';

export const NewForm: FC<FormProps> = () => {
    const { values, setFieldValue } = useFormikContext<FormikValues>();

    const [currentMeasure, setCurrentMeasure] = useState('');

    const productsFilter = useMemo(
        () => ({
            category_id: values.category_id,
            categories: { is_manufacture: 1 },
        }),
        [values.category_id],
    );
    const categoriesFilter = useMemo(
        () => ({
            is_manufacture: true,
        }),
        [],
    );

    const selectedCategoryCallback = useCallback(
        (selected: any) => {
            if (!selected) {
                setFieldValue(`product_id`, '');
            }
        },
        [setFieldValue],
    );

    const selectedCallback = useCallback(
        (selected: any) => {
            if (selected?.category_id) {
                setFieldValue(`category_id`, selected.category_id);
            }

            setFieldValue(`measure_id`, selected?.measure_id || '');
            setFieldValue(`measure_name`, selected?.measure_name || '');
            setCurrentMeasure(selected?.measure_name || '');
        },
        [setFieldValue],
    );

    return (
        <form>
            <Grid container spacing={3}>
                <Grid item style={{ width: 600 }}>
                    <InputDate id="date" name="manufactured_at" label="Дата" required />
                    <SelectWithData
                        name={`category_id`}
                        type="categories"
                        label="Категория"
                        filterParams={categoriesFilter}
                        selectedCallback={selectedCategoryCallback}
                        fullWidth
                    />
                    <SelectWithData
                        name={`product_id`}
                        type="products"
                        label="Продукт"
                        filterParams={productsFilter}
                        selectedCallback={selectedCallback}
                        fullWidth
                    />
                    <InputNumber
                        name={`quantity`}
                        label="Количество"
                        disabled={values.quantity_type === 2}
                        measure={currentMeasure}
                        isShort
                        fullWidth
                        required
                        withoutDecimal
                    />
                    <InputText name="comments" rows={3} multiline label="Комментарий" fullWidth />
                    <SelectWithData
                        name={`employee_id`}
                        type="employees"
                        label="Ответственный"
                        order="last_name"
                        fieldName="last_name"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </form>
    );
};

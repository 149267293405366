import { FC, useMemo, useState, useCallback } from 'react';
import { Grid, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { ComponentsFormFromStock } from './ComponentsForm';

import { InputText, InputNumber, InputDate, SelectWithData } from '../../../components/Inputs';
import { DividerWithTitle } from '../../../components/DividerWithTitle';

import { StockProps } from '../../../store/StocksStore';

import { FormProps } from '..';
import { FormikValues, useFormikContext } from 'formik';

const useStyles = makeStyles(() => ({
    menuItem: {
        display: 'block',
    },
    listItem: {
        display: 'block',
        paddingTop: 4,
        paddingBottom: 4,
    },
    contractor: {
        fontStyle: 'italic',
    },
}));

export const InPlanForm: FC<FormProps> = () => {
    const classes = useStyles();
    const { setFieldValue, values } = useFormikContext<FormikValues>();

    const [currentMeasure, setCurrentMeasure] = useState('');

    const productsFilter = useMemo(
        () => ({
            category_id: values.category_id,
            categories: { is_manufacture: 1 },
        }),
        [values.category_id],
    );
    const categoriesFilter = useMemo(
        () => ({
            is_manufacture: true,
        }),
        [],
    );
    const stocksFilter = useMemo(
        () => ({
            is_manufacture: true,
        }),
        [],
    );

    const selectedCategoryCallback = useCallback(
        (selected: any) => {
            if (!selected) {
                setFieldValue(`product_id`, '');
            }
        },
        [setFieldValue],
    );

    const selectedCallback = useCallback(
        (selected: any) => {
            if (selected?.category_id) {
                setFieldValue(`category_id`, selected.category_id);
            }

            setFieldValue(`measure_id`, selected?.measure_id || '');
            setFieldValue(`measure_name`, selected?.measure_name || '');
            setCurrentMeasure(selected?.measure_name || '');
        },
        [setFieldValue],
    );
    const selectedStockCallback = useCallback(
        (selected: any) => {
            setFieldValue('stock_is_contractor', !!selected?.is_contractor);
        },
        [setFieldValue],
    );

    return (
        <form>
            <Grid container spacing={3} style={{ minWidth: '100%' }}>
                <Grid item xs={6}>
                    <DividerWithTitle>Общие параметры</DividerWithTitle>
                    <Grid container>
                        <Grid item xs={4}>
                            <InputDate id="date" name="manufactured_at" label="Дата" />
                        </Grid>
                        <Grid item xs={8}>
                            <InputText name="lot" label="Лот" fullWidth />
                        </Grid>
                    </Grid>
                    <SelectWithData
                        name={`category_id`}
                        type="categories"
                        label="Категория"
                        filterParams={categoriesFilter}
                        selectedCallback={selectedCategoryCallback}
                        fullWidth
                    />
                    <SelectWithData
                        name={`product_id`}
                        type="products"
                        label="Продукт"
                        filterParams={productsFilter}
                        selectedCallback={selectedCallback}
                        fullWidth
                    />
                    <SelectWithData
                        name={`quantity_type`}
                        data={[
                            {
                                id: 1,
                                name: 'По целевому продукту',
                            },
                            {
                                id: 2,
                                name: 'По исходным продуктам',
                            },
                        ]}
                        label="Вариант расчёта количества"
                        fullWidth
                    />
                    <InputNumber
                        name={`quantity`}
                        label="Количество"
                        disabled={values.quantity_type === 2}
                        measure={currentMeasure}
                        isShort
                        fullWidth
                    />
                    <SelectWithData
                        name={`stock_id`}
                        type="stocks"
                        label="Склад для производства"
                        filterParams={stocksFilter}
                        fullWidth
                        withoutAutocomplete
                        selectFirst={!values.stock_id}
                        menuItem={(item: StockProps) => {
                            return (
                                <MenuItem className={clsx(classes.menuItem)} key={item.id} value={item.id}>
                                    <Typography className={classes.listItem} variant="body1">
                                        {item.name}
                                        {item.is_contractor ? (
                                            <span className={classes.contractor}> (Подрядчик)</span>
                                        ) : (
                                            ''
                                        )}
                                    </Typography>
                                </MenuItem>
                            );
                        }}
                        selectedCallback={selectedStockCallback}
                    />
                    <InputText name="comments" rows={3} multiline label="Комментарий" fullWidth />
                    <SelectWithData
                        name={`employee_id`}
                        type="employees"
                        label="Ответственный"
                        order="last_name"
                        fieldName="last_name"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <ComponentsFormFromStock
                        quantity={values.quantity}
                        productId={values.product_id}
                        quantityType={values.quantity_type}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

import { request } from './request';

/**
 * Функция устанавливает новый пароль для пользователя.
 *
 * Если передан только
 * логин и данный пользователь существует, ему высылается код на e-mail
 * для изменения пароля.
 *
 * Если передан и код и пароли, то пароль сбрасывается но указанный.
 *
 * При любых ошибках возвращается не пустой объект errors содержащий
 * ключи полей, в которых произошла ошибка и описание этой ошибки.
 *
 * Если ошибок не было, то для первого поведения возвращается ключ
 * codeSent: true
 *
 * Для второго случая возвращается ключ
 * passwordChanged: true
 *
 * @param {string} loginOrEmail логин или e-mail пользователя
 * @param {string} code высланный на e-mail код
 * @param {string} password новый пароль
 */
export const passwordReset = (login: string, code?: string, password?: string) => {
    return request('password-reset', {
        login,
        code,
        password,
    });
};

import { observable, configure } from 'mobx';
import { TableStoreBase, TableData } from './BaseTableStore';

import { getBuyers, createBuyer, updateBuyer, removeBuyers } from '../services/buyers';
import { ColumnProps } from '../typings/table';

configure({ enforceActions: 'observed' });

export interface BuyerProps {
    [key: string]: any;
    id: number | string;
    is_disable: boolean;
    created_at: string;
    email: string;
    login: string;
    name: string;
}

export interface BuyersDataProps extends TableData {
    items?: BuyerProps[];
}

export class BuyersStore extends TableStoreBase {
    constructor() {
        super('buyers');

        this.itemPageLink = '/dashboard/buyer';

        this.filterParams.order = 'name';
        this.filterParams.orderDir = 'asc';

        this.getData = getBuyers;
        this.removeItem = removeBuyers;
        this.updateItem = updateBuyer;
        this.createItem = createBuyer;
    }

    @observable columns: ColumnProps[] = [
        { field: 'name', title: 'Название', sortable: true },
        { field: 'last_name', title: 'Фамилия', sortable: true },
        { field: 'first_name', title: 'Имя' },
        { field: 'patronymic_name', title: 'Отчество' },
        { field: 'inn', title: 'ИНН', sortable: true },
        { field: 'phone', title: 'Телефон' },
        { field: 'email', title: 'E-mail', sortable: true },
    ];

    @observable data: BuyersDataProps = {};

    @observable pageLimit: number = 1;
}

export const buyersStore = new BuyersStore();

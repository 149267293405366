import React from 'react';
import { Typography } from '@mui/material';

export const Copyright = (props: any) => {
    return (
        <Typography component="div" variant="body2" color="textSecondary" {...props}>
            Производство © {new Date().getFullYear()} ИП Шубин
        </Typography>
    );
};

import { request } from './request';

import { FilterParamsProps } from '../typings/table';
import { getDataRequestParams } from '../utils/getDataRequestParams';

interface ServiceProps {
    path: string;
}

export class ServiceBase {
    path: string;

    constructor(props: ServiceProps) {
        this.path = props.path;
    }

    getData = (params: FilterParamsProps) => {
        const requestParams = getDataRequestParams(params);
        return request(this.path, requestParams, 'POST');
    };

    get = (id: string) => {
        return request(`${this.path}/edit/` + id, null, 'GET');
    };

    create = (data: any) => {
        return request(`${this.path}/create`, data, 'POST');
    };

    update = (id: string, data: any) => {
        return request(`${this.path}/edit/` + id, data, 'POST');
    };

    remove = (ids: string | string[]) => {
        return request(this.path, { ids }, 'DELETE');
    };
}

import { FC } from 'react';

import { PageWithTable } from '../../components/Page/withTable/PageWithTable';
import { EditForm, EditButtons, validationSchema } from './EditForm';

import { FormProps } from '../../typings/table';
import { EditDialogProps } from '../../components/Dialog/withEdit/DialogWithEdit';
import { EditTitle } from './EditForm/EditTitle';
import { inventoriesStore } from '../../store/InventoriesStore';

interface InventoriesPageProps {
    title?: string;
}

export const InventoriesPage: FC<InventoriesPageProps> = ({ title }) => {
    function dialogProps(props: FormProps): EditDialogProps {
        return {
            hideSaveButton: true,
            DialogProps: {
                fullScreen: props.isEdit,
                titleContent: <EditTitle {...props} />,
            },
        };
    }

    return (
        <>
            <PageWithTable
                title={title}
                store={inventoriesStore}
                EditForm={EditForm}
                EditButtons={EditButtons}
                EditDialogProps={dialogProps}
                getModalTitle={(data) => 'Инвентаризация: ' + data?.number}
                validationSchema={validationSchema}
            />
        </>
    );
};

import React, { FC, useCallback, useMemo } from 'react';
import { FormikValues, FieldArray, useFormikContext } from 'formik';
import { Button, Paper, List, ListItem, ListItemText, FormLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ButtonWithRemove } from '../../../components/Button/withRemove/ButtonWithRemove';
import { DividerWithTitle } from '../../../components/DividerWithTitle';
import { InputText, SelectWithData } from '../../../components/Inputs';
import { padZero } from '../../../utils/padZero';
import { nl2br } from '../../../utils/nl2br';

interface ProductFormProps {
    index: number;
}

const useStyles = makeStyles(() => ({
    contentWithItems: {
        padding: 12,
        border: '1px solid rgb(200, 200, 200)',
        minWidth: 480,
    },
    contentWithoutItems: {
        minWidth: 480,
    },
    listCard: {
        padding: '24px 12px',
        margin: '18px 0',
        boxShadow:
            '0px 3px 3px -2px rgba(0, 0, 0, .2), 0px 3px 4px 0px rgba(0, 0, 0, .14), 0px 1px 8px 0px rgba(0, 0, 0, .12)',
    },
    listTitle: {
        fontWeight: 'bold',
        marginRight: 4,
    },
}));

const ProductEditForm: FC<ProductFormProps> = ({ index }) => {
    const { values, setFieldValue } = useFormikContext<FormikValues>();

    const { category_id } = values.products[index];

    const productsFilter = useMemo(() => ({ category_id }), [category_id]);

    const selectedProductCallback = useCallback(
        (selected: any) => setFieldValue(`category_id`, selected?.category_id || ''),
        [setFieldValue],
    );

    const isNew = !values.products[index].id;
    const productNumber = values.products[index]?.number;

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormLabel>Порядковый номер:</FormLabel>
                <div style={{ paddingLeft: 5 }}>{isNew ? '<будет присвоен>' : padZero(productNumber, 2)}</div>
            </div>
            <SelectWithData
                name={`products.${index}.category_id`}
                type="categories"
                label="Категория"
                filterParams={{ is_ready: true }}
                required
                fullWidth
            />
            <SelectWithData
                name={`products.${index}.product_id`}
                type="products"
                label="Продукт"
                filterParams={productsFilter}
                selectedCallback={selectedProductCallback}
                required
                fullWidth
            />
            <InputText
                name={`products.${index}.description`}
                style={{ minWidth: 300 }}
                multiline
                fullWidth
                label="Описание продукта"
            />
        </div>
    );
};

const ProductForm: FC<ProductFormProps> = ({ index }) => {
    const classes = useStyles();

    const { values, setFieldValue } = useFormikContext<FormikValues>();

    const onChangeState = useCallback(() => {
        setFieldValue(`products.${index}.isEdit`, true);
    }, [setFieldValue, index]);

    const isNew = !values.products[index].id;

    return (
        <List dense>
            {!isNew ? (
                <ListItem>
                    <span className={classes.listTitle}>Порядковый номер:</span>
                    <ListItemText>{padZero(values.products[index].number, 2)}</ListItemText>
                </ListItem>
            ) : null}
            <ListItem>
                <span className={classes.listTitle}>Категория:</span>
                <ListItemText>{values.products[index].category_name}</ListItemText>
            </ListItem>
            <ListItem>
                <span className={classes.listTitle}>Продукт:</span>
                <ListItemText>{values.products[index].product_name}</ListItemText>
            </ListItem>
            <ListItem>
                <span className={classes.listTitle}>Описание продукта:</span>
                <ListItemText>{nl2br(values.products[index].description)}</ListItemText>
            </ListItem>
            <ListItem>
                <Button size="small" variant="outlined" onClick={onChangeState}>
                    Редактировать
                </Button>
            </ListItem>
        </List>
    );
};

export const ProductsList: FC = () => {
    const classes = useStyles();
    const { values } = useFormikContext<FormikValues>();
    const products = values?.products || [];

    return (
        <FieldArray name="products">
            {({ remove, push }) => {
                return (
                    <React.Fragment>
                        <div className={products.length ? classes.contentWithItems : classes.contentWithoutItems}>
                            {products.map((product: any, index: number) => {
                                return (
                                    <Paper
                                        elevation={3}
                                        key={product.id || `products-${index}`}
                                        className={classes.listCard}
                                    >
                                        <DividerWithTitle>
                                            {index + 1}
                                            {product.isEdit ? (
                                                <ButtonWithRemove buttonText="" onRemove={() => remove(index)} />
                                            ) : null}
                                        </DividerWithTitle>
                                        {product.isEdit ? (
                                            <ProductEditForm index={index} />
                                        ) : (
                                            <ProductForm index={index} />
                                        )}
                                    </Paper>
                                );
                            })}
                        </div>
                        <Button variant="contained" onClick={() => push({ isEdit: true })}>
                            Добавить продукт
                        </Button>
                    </React.Fragment>
                );
            }}
        </FieldArray>
    );
};

import { FC } from 'react';

import { Button } from '@mui/material';

import { Dialog, DialogProps } from '../Dialog';

interface ConfirmDialogProps extends DialogProps {
    onConfirm: () => void;
    confirmText?: string;
    cancelText?: string;
    confirmColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
    onConfirm,
    onClose,
    confirmText = 'Подтвердить',
    cancelText = 'Отмена',
    confirmColor = 'primary',
    children,
    ...props
}) => {
    const actions = (
        <>
            <Button onClick={onConfirm} variant="contained" color={confirmColor}>
                {confirmText}
            </Button>
            <Button onClick={onClose} variant="text" autoFocus>
                {cancelText}
            </Button>
        </>
    );

    return (
        <Dialog onClose={onClose} actions={actions} {...props}>
            {children}
        </Dialog>
    );
};

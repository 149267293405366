import React, { FC, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Paper, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { InProgress } from '../../pages/InProgress';

export interface PageProps extends PropsWithChildren {
    title?: string;
    rightHeader?: React.ReactElement;
    underTitle?: React.ReactElement;
    withPaper?: boolean;
    inProgress?: boolean;
}

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: 'relative',
        height: '100%',
        overflow: 'auto',
        zIndex: 100,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    devider: {
        marginBottom: 16,
    },
    pageHeader: {
        display: 'flex',
        padding: '8px 0 8px 8px',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: '8px 0',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
    },
    pageTitle: {
        margin: '4px 0',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
        },
    },
}));

export const Page: FC<PageProps> = ({ title, children, rightHeader, underTitle, withPaper = true, inProgress }) => {
    const classes = useStyles();

    const query = useQuery();

    if (inProgress && !query.get('dev')) {
        return <InProgress />;
    }

    return (
        <>
            <div className={classes.pageHeader}>
                <Typography variant="h4" className={classes.pageTitle}>
                    {title}
                </Typography>
                {rightHeader}
            </div>
            <Divider className={classes.devider} />
            {underTitle}
            {withPaper ? (
                <Paper elevation={3} className={classes.root}>
                    <div className={classes.content}>{children}</div>
                </Paper>
            ) : (
                children
            )}
        </>
    );
};

import React, { FC, useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useParams, Link } from 'react-router-dom';
import {
    Table,
    TableContainer,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Tooltip,
    Button,
    Alert,
} from '@mui/material';
import { TableChartOutlined, Search } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Page, PageProps } from '../Page';
import { ColumnsPanel } from '../../ColumnsPanel';
import { Filter } from '../../Filter';
import { SelectWithData, SelectWithDictionary } from '../../Inputs';

import { ColumnProps } from '../../../typings/table';
import { appStore } from '../../../store/AppStore';
import { getStocks } from '../../../services/stocks';
import { getLocalNumber } from '../../../utils/getLocalNumber';
import { stockDetailsStore } from '../../../store/StockDetailsStore';

const useStyles = makeStyles(() => ({
    page: {
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
    },
    table: {
        backgroundColor: '#fff',
    },
    cardLink: {
        textDecoration: 'none',
        '&:hover > div': {
            boxShadow: '0 0 4px 4px rgba(40,40,40,.2)',
        },
    },
    row: {
        textDecoration: 'none',
        '&:hover': {
            background: '#f5f5f5',
        },
    },
    card: {
        margin: 12,
    },
    listItem: {
        paddingTop: 4,
        paddingBottom: 4,
    },
    listTitle: {
        fontWeight: 'bold',
        marginRight: 4,
    },
    lastCell: {
        borderBottom: '1px solid #333',
    },
    filterChanged: {
        backgroundColor: '#eea849',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#f46b45',
        },
    },
}));

/**
 * Состояние склада
 */
export const PageWithStockBalance: FC<PageProps> = observer((props) => {
    const classes = useStyles();
    const {
        getStock,
        current,
        data,
        toggleColumnsPanel,
        stockColumns,
        columnsPanelState,
        toggleColumn,
        loading,
        filterState,
        clearFilter,
        updateStockFilterParams,
        filterParams,
        toggleOpenFilter,
    } = stockDetailsStore;
    // @ts-ignore
    const { id } = useParams();

    const [currentStock, setCurrentStock] = useState(current);
    const { items } = data;

    useEffect(() => {
        if (id) {
            getStock(id);
        }
    }, [getStock, id]);

    useEffect(() => {
        if (!current) {
            getStocks({ filterData: { id } }).then((data) => {
                setCurrentStock(data?.rows?.[0]);
            });
        }
    }, [id, current]);

    const columnsButton = (
        <div style={{ width: 138, display: 'flex', justifyContent: 'space-between' }}>
            <Tooltip title="Настройка столбцов" arrow placement="bottom">
                <Button variant="contained" onClick={toggleColumnsPanel}>
                    <TableChartOutlined />
                </Button>
            </Tooltip>{' '}
            <Tooltip title={filterState.isChanged ? 'Включён фильтр' : 'Фильтр'} arrow placement="top">
                <Button
                    className={clsx({ [classes.filterChanged]: filterState.isChanged })}
                    variant="contained"
                    color="inherit"
                    onClick={toggleOpenFilter}
                >
                    <Search />
                </Button>
            </Tooltip>
        </div>
    );

    const isColumnVisble = (columnField: string) => {
        return !stockColumns.find((column: ColumnProps) => {
            return column.field === columnField;
        })?.hidden;
    };

    const handleToggleColumn = (name: string) => {
        toggleColumn(name, stockColumns);
    };

    const FilterForm: FC = () => {
        const productsFilter = useMemo(
            () => ({
                stocks_balances: {
                    in_stock: true,
                    stock_id: id,
                },
            }),
            [],
        );

        return (
            <>
                <SelectWithData
                    name="product_id"
                    type="products"
                    label="Продукты"
                    filterParams={productsFilter}
                    multiple
                    style={{ width: 340 }}
                />
                <SelectWithDictionary
                    name="products.category_id"
                    type="categories"
                    label="Категория"
                    style={{ width: 340 }}
                />
            </>
        );
    };

    return (
        <Page {...props} title={current?.stock_name} withPaper={false} rightHeader={columnsButton}>
            <ColumnsPanel columns={stockColumns} isVisble={columnsPanelState.isOpen} onChange={handleToggleColumn} />
            <Filter
                isVisble={filterState.isOpen}
                FilterForm={FilterForm}
                filterValues={filterParams.filterData}
                onFilter={(params) => {
                    if (id) {
                        updateStockFilterParams(id, params);
                    }
                }}
                onClear={clearFilter}
            />
            {(!current || !items) && !loading ? (
                <Page {...props} title={currentStock?.name} withPaper={false}>
                    <Alert severity="warning">На складе пусто.</Alert>
                </Page>
            ) : null}
            {!loading ? (
                <TableContainer className={classes.page}>
                    <Table stickyHeader className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {stockColumns.map((column: ColumnProps) => {
                                    if (column.hidden) {
                                        return null;
                                    }

                                    return (
                                        <TableCell key={column.field} align={column.align}>
                                            <span className={classes.listTitle}>{column.title}</span>
                                        </TableCell>
                                    );
                                })}
                                {!isColumnVisble('balance') && <TableCell />}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items?.map((item, itemIndex) => {
                                return (
                                    <React.Fragment key={itemIndex}>
                                        <TableRow>
                                            <TableCell className={classes.lastCell} rowSpan={item.records.length + 1}>
                                                {item.category_name}
                                            </TableCell>
                                        </TableRow>
                                        {item.records.map((record: any, index: number) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <TableRow
                                                        component={Link}
                                                        to={`/dashboard/productbalance?stock=${id}&product=${record.product_id}`}
                                                        target="_blank"
                                                        className={classes.row}
                                                    >
                                                        {isColumnVisble('product_sku') && (
                                                            <TableCell
                                                                className={
                                                                    index === item.records.length - 1
                                                                        ? classes.lastCell
                                                                        : ''
                                                                }
                                                            >
                                                                {record.product_sku}
                                                            </TableCell>
                                                        )}
                                                        {isColumnVisble('product_name') && (
                                                            <TableCell
                                                                className={
                                                                    index === item.records.length - 1
                                                                        ? classes.lastCell
                                                                        : ''
                                                                }
                                                            >
                                                                {record.product_name}
                                                            </TableCell>
                                                        )}
                                                        <TableCell
                                                            className={
                                                                index === item.records.length - 1
                                                                    ? classes.lastCell
                                                                    : ''
                                                            }
                                                            align="right"
                                                        >
                                                            {getLocalNumber(record.balance, {
                                                                isFullDigits: appStore.isFullDigits,
                                                            })}{' '}
                                                            {record.measure_name}
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            );
                                        })}
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <h4>Загрузка данных...</h4>
            )}
        </Page>
    );
});

import { FC, useCallback, useState, useEffect, useMemo, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Button, LinearProgress, IconButton, Tooltip, Stack } from '@mui/material';
import { FindInPage } from '@mui/icons-material';

import { Dialog, DialogProps } from '../Dialog';
import { DialogData } from '../../../typings/table';

export interface EditDialogProps {
    data?: DialogData;
    onClose?: () => void;
    onConfirm?: () => void;
    loading?: boolean;
    disableEdit?: boolean;
    itemPageLink?: string;
    editTemplate?: ReactNode;
    viewTemplate?: ReactNode;
    formButtons?: ReactNode;
    hideSaveButton?: boolean;
    saveButtonText?: ReactNode;
    hideCloseButton?: boolean;
    closeButtonText?: ReactNode;
    title?: string;
    titleField?: string;
    primaryKeyField?: string;
    isOpen?: boolean;
    DialogProps?: Partial<DialogProps>;
}

export const DialogWithEdit: FC<EditDialogProps> = ({
    onConfirm,
    onClose,
    data,
    loading,
    viewTemplate,
    editTemplate,
    itemPageLink,
    formButtons,
    titleField = 'name',
    primaryKeyField = 'id',
    title: customTitle,
    disableEdit,
    hideSaveButton = false,
    saveButtonText = 'Сохранить',
    hideCloseButton = false,
    closeButtonText = 'Закрыть',
    isOpen = false,
    DialogProps,
}) => {
    const primaryKeyId = data?.[primaryKeyField];
    const title = primaryKeyId ? 'Редактировать' : 'Добавить';
    const hasView = Boolean(viewTemplate);
    const currentName = data?.[titleField];
    const [state, changeState] = useState(hasView ? 'view' : 'edit');

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        if (hasView && primaryKeyId) {
            changeState('view');
        } else {
            changeState('edit');
        }
    }, [isOpen, hasView, primaryKeyId]);

    const isEdit = state === 'edit';

    const onChangeState = useCallback(() => {
        changeState(state === 'view' ? 'edit' : 'view');
    }, [state]);

    const currentTitle = useMemo(() => {
        if (!isEdit && primaryKeyId) {
            return customTitle || currentName;
        }
        if (isEdit && primaryKeyId) {
            return `${customTitle || currentName} (Редактирование)`;
        }

        return customTitle || title;
    }, [isEdit, currentName, title, customTitle, primaryKeyId]);

    const watchButon =
        itemPageLink && primaryKeyId ? (
            <Tooltip title="Страница с детальной информацией" arrow placement="top">
                <Link to={`${itemPageLink}/${primaryKeyId}`} target="_blank">
                    <IconButton>
                        <FindInPage fontSize="small" />
                    </IconButton>
                </Link>
            </Tooltip>
        ) : undefined;

    const actions = (
        <Stack direction="row" spacing={1}>
            {watchButon}
            {!isEdit && editTemplate && !disableEdit ? (
                <Button type="submit" variant="contained" color="inherit" onClick={onChangeState} disabled={loading}>
                    Редактировать
                </Button>
            ) : null}
            {isEdit && hasView && primaryKeyId ? (
                <Button type="submit" variant="contained" color="inherit" onClick={onChangeState} disabled={loading}>
                    Просмотр
                </Button>
            ) : null}
            {isEdit && !hideSaveButton ? (
                <Button type="submit" variant="contained" onClick={onConfirm} disabled={loading}>
                    {saveButtonText}
                </Button>
            ) : null}
            {formButtons}
            {!hideCloseButton ? (
                <Button onClick={onClose} variant="text">
                    {closeButtonText}
                </Button>
            ) : null}
        </Stack>
    );

    return (
        <Dialog actions={actions} title={currentTitle} maxWidth="lg" open={isOpen} {...DialogProps}>
            {state === 'view' ? viewTemplate : editTemplate}
            {loading && <LinearProgress />}
        </Dialog>
    );
};

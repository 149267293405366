import React, { FC, useEffect, useState } from 'react';
import * as yup from 'yup';

import { observer } from 'mobx-react';
import { Button } from '@mui/material';
import moment from 'moment';
import { FormikValues, useFormikContext } from 'formik';

import { FormProps } from '../../../typings/table';
import { InputText } from '../../../components/Inputs';
import { StocksManualSelect } from '../../../components/Inputs/Select/StocksManualSelect';
import { EmployeeSelect } from '../../../components/Inputs/Select/EmployeeSelect';
import { completeInventory, downloadUrlInventory, getInventories, getInventory } from '../../../services/inventories';
import { BalancesList } from './BalancesList';
import { ExcelIcon } from '../../../components/Icons/ExcelIcon';
import { BalanceProps } from '../../../store/BalanceStore';
import { AddBalanceDialog } from './AddBalanceDialog';
import { inventoriesStore } from '../../../store/InventoriesStore';

function getBalancesValidationSchema(isCompleted: boolean) {
    const inventoryBalanceSchema = yup.number().nullable();
    return yup.object({
        inventory_balance: isCompleted ? inventoryBalanceSchema.required('Обязательное') : inventoryBalanceSchema,
    });
}

export const validationSchema = yup.object().shape({
    isCompleting: yup.boolean(),
    number: yup.string().required('Номер обязателен'),
    stock_id: yup.number().required('Склад обязателен'),
    responsible_id: yup.number().required('Ответственный обязателен'),
    balances: yup
        .array()
        .when('isCompleting', (isCompleting: boolean, schema) => schema.of(getBalancesValidationSchema(isCompleting))),
});

export const EditForm: FC<FormProps> = observer(({ isEdit, data: initialData }) => {
    const { values: data } = useFormikContext<FormikValues>();
    const balances = data.balances || [];
    const [previous, setPrevious] = useState<any | null>(null);

    useEffect(() => {
        if (!isEdit) {
            getInventories({ order: 'created_at', orderDir: 'desc', limit: 1 }).then((data) => {
                if (data?.rows?.[0]) {
                    setPrevious(data.rows[0]);
                }
            });
        }
    }, [isEdit]);

    const isDisabled = Boolean(initialData?.is_completed);

    return (
        <form>
            <InputText disabled={isEdit} name="number" style={{ minWidth: 300 }} fullWidth required label="Номер" />
            {!isEdit && previous ? (
                <small>
                    Предыдущая инвентаризация: "{previous.number}" от {moment(previous.created_at).format('DD.MM.YYYY')}
                </small>
            ) : null}
            <StocksManualSelect
                disabled={isEdit}
                required
                name="stock_id"
                type="stocks"
                fullWidth
                style={{ minWidth: 300 }}
            />
            <EmployeeSelect disabled={isDisabled} name="responsible_id" fullWidth style={{ minWidth: 300 }} />
            <EmployeeSelect
                disabled={isDisabled}
                name="member_ids"
                label="Члены комиссии"
                fullWidth
                multiple
                style={{ minWidth: 300 }}
            />
            {isEdit ? <BalancesList balances={balances} isCompleted={isDisabled} /> : null}
        </form>
    );
});

export const EditButtons: FC<FormProps> = observer(
    ({ isEdit, data: initialData, openDialog, closeDialogAndReload }) => {
        const formik = useFormikContext<FormikValues>();
        const [addBalance, setAddBalance] = useState(false);

        const { values: data, setFieldValue, setErrors, validateForm } = formik;

        const balances = data.balances || [];
        const stockId = data.stock_id;
        const balancesIds: number[] = balances.map((b: BalanceProps) => b.balance_id);

        const isCompleting = Boolean(data.isCompleting);

        useEffect(() => {
            if (isCompleting) {
                validateForm()
                    .then((errors) => {
                        if (Object.keys(errors).length <= 0) {
                            completeInventory(data.id, data).then((data) => {
                                if (data && data?.errors) {
                                    setErrors(data.errors);
                                } else {
                                    closeDialogAndReload();
                                }
                            });
                        }
                    })
                    .finally(() => setFieldValue('isCompleting', false));
            }
        }, [isCompleting, closeDialogAndReload, data, setFieldValue, validateForm, setErrors]);

        function handleSave() {
            validateForm().then((errors) => {
                if (Object.keys(errors).length <= 0) {
                    inventoriesStore.update(data.id, data).then((data) => {
                        if (!data) {
                            return data;
                        }

                        if (data?.errors) {
                            setErrors(data.errors);
                            return data;
                        }

                        if (!isEdit) {
                            inventoriesStore.loadData();
                            inventoriesStore.setLoading(true);
                            getInventory(data?.id)
                                .then((data) => openDialog(data))
                                .finally(() => inventoriesStore.setLoading(false));
                        } else {
                            closeDialogAndReload();
                        }
                    });
                }
            });
        }

        function handleComplete() {
            setFieldValue('isCompleting', true);
        }

        function downloadExcel() {}

        function handleAddBalance(balance: BalanceProps) {
            if (balance) {
                const newBalance = {
                    balance_id: balance.id,
                    category_id: balance.category_id,
                    category_name: balance.category_name,
                    product_id: balance.product_id,
                    product_sku: balance.product_sku,
                    product_name: balance.product_name,
                    balance_created_at: balance.created_at,
                    balance_manufactured_at: balance.manufactured_at,
                    manufacturer_name: balance.manufacturer_name,
                    balance_mcost: balance.mcost,
                    initial_balance: balance.balance,
                    inventory_balance: null,
                    measure_name: balance.measure_name,
                };

                setFieldValue('balances', [...balances, newBalance]);
                setAddBalance(false);
            }
        }

        const disableAdd = !isEdit || Boolean(initialData?.is_completed);

        return (
            <React.Fragment>
                {!disableAdd ? (
                    <React.Fragment>
                        <AddBalanceDialog
                            open={addBalance}
                            onSelect={handleAddBalance}
                            onClose={() => setAddBalance(false)}
                            stockId={stockId}
                            balancesIds={balancesIds}
                        />
                        <Button variant="outlined" onClick={() => setAddBalance(true)}>
                            Добавить партию
                        </Button>
                    </React.Fragment>
                ) : null}
                {isEdit ? (
                    <Button
                        component="a"
                        download
                        href={downloadUrlInventory(data.id)}
                        startIcon={<ExcelIcon />}
                        onClick={downloadExcel}
                    >
                        Скачать
                    </Button>
                ) : null}
                {!isEdit || (initialData && !initialData.is_completed) ? (
                    <Button onClick={handleSave}>{isEdit ? 'Сохранить' : 'Начать инвентаризацию'}</Button>
                ) : null}
                {isEdit && initialData && !initialData.is_completed ? (
                    <Button color="success" onClick={handleComplete}>
                        Завершить
                    </Button>
                ) : null}
            </React.Fragment>
        );
    },
);

import { FC } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DialogData } from '../../../typings/table';
import { getLocalNumber } from '../../../utils/getLocalNumber';
import { observer } from 'mobx-react';
import { appStore } from '../../../store/AppStore';

interface ViewTransactionProps {
    current?: DialogData;
}

const useStyles = makeStyles(() => ({
    page: {
        height: '100%',
        minWidth: 480,
    },
    listTitle: {
        fontWeight: 'bold',
        marginRight: 4,
    },
}));

export const ViewForm: FC<ViewTransactionProps> = observer(({ current }) => {
    const isFullDigits = appStore.isFullDigits;
    const classes = useStyles();

    if (!current) {
        return null;
    }

    return (
        <div className={classes.page}>
            {Number(current.tran_id) === 4 ? (
                <>
                    <Typography variant="body1">
                        <span className={classes.listTitle}>Тип:</span>{' '}
                        {Number(current.quantity) < 0 ? 'Списание' : 'Поступление'}
                    </Typography>
                    <Typography variant="body1">
                        <span className={classes.listTitle}>Откуда:</span>{' '}
                        {Number(current.quantity) < 0 ? current.stock_name : current.from_stock_name}
                    </Typography>
                    <Typography variant="body1">
                        <span className={classes.listTitle}>Куда:</span>{' '}
                        {Number(current.quantity) < 0 ? current.from_stock_name : current.stock_name}
                    </Typography>
                </>
            ) : null}
            {Number(current.tran_id) !== 4 && (
                <Typography variant="body1">
                    <span className={classes.listTitle}>Склад:</span> {current.stock_name}
                </Typography>
            )}
            <Typography variant="body1">
                <span className={classes.listTitle}>Продукт:</span> {current.product_name || '–'}
            </Typography>
            <Typography variant="body1">
                <span className={classes.listTitle}>Количество:</span>{' '}
                {getLocalNumber(current.quantity, { isFullDigits })} {current.measure_name}
            </Typography>
            <Typography variant="body1">
                <span className={classes.listTitle}>Автор:</span> {current.created_by_name || '–'}
            </Typography>
            <Typography variant="body1">
                <span className={classes.listTitle}>Комментарий:</span> {current.comments || '–'}
            </Typography>
        </div>
    );
});

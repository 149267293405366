import { FC } from 'react';
import { Grid } from '@mui/material';

import { InputDate, InputText, SelectWithDictionary } from '../../components/Inputs';

import { ManufacturePage as Page } from './Manufacture.Page';
import { PageProps } from '../../components/Page';

interface ManufacturePageProps extends PageProps {
    title?: string;
}

const FilterForm: FC = () => (
    <>
        <Grid container alignItems="center">
            <SelectWithDictionary
                name="status_id"
                type="manufactures_statuses"
                label="Транзакция"
                enableSystem
                style={{ width: 250 }}
            />
            <InputText name="filter" label="Продукт" />
        </Grid>
        <InputDate name="manufactured_at_from" label="От" startOfDay />
        <InputDate name="manufactured_at_to" label="До" endOfDay />
    </>
);

export const ManufacturePage: FC<ManufacturePageProps> = ({ title, ...props }) => {
    return (
        <>
            <Page title={title} detailTitle="Производство" FilterForm={FilterForm} {...props} />
        </>
    );
};

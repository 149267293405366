import { useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react';
import {
    Button,
    MenuItem,
    Menu,
    ListItemIcon,
    ListItemText,
    Checkbox,
    FormControlLabel,
    ListItem,
    Divider,
} from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { ExitToApp, AccountCircle } from '@mui/icons-material';
import { blueGrey } from '@mui/material/colors';

import { authenticationApi } from '../../services/authentication';
import { appStore } from '../../store/AppStore';
import { authStore } from '../../store/AuthStore';

const ProfileButton = withStyles((theme: Theme) => ({
    root: {
        color: theme.palette.getContrastText(blueGrey[500]),
        backgroundColor: blueGrey[500],
        '&:hover': {
            backgroundColor: blueGrey[700],
        },
    },
}))(Button);

const useStyles = makeStyles(() => ({
    listItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    navItem: {
        textDecoration: 'none',
        color: '#333',
        whiteSpace: 'nowrap',
    },
}));

export const ProfileMenu = observer(() => {
    const classes = useStyles();
    const buttonRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigate = useNavigate();

    const { isFullDigits, toggleFullDigits } = appStore;

    const handleMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(buttonRef.current);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleLogout = useCallback(() => {
        authenticationApi.logout();
        navigate('/login');
    }, [navigate]);

    return (
        <div ref={buttonRef}>
            <ProfileButton color="primary" startIcon={<AccountCircle />} onClick={handleMenu}>
                {authStore.profileInfo}
            </ProfileButton>
            <Menu
                id="menu-appbar"
                // getContentAnchorEl={null}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                style={{
                    zIndex: 1601,
                }}
            >
                <ListItem className={classes.listItem}>
                    <ListItemText secondary="Точность отображаемых данных" />
                    <FormControlLabel
                        control={<Checkbox checked={isFullDigits} onChange={toggleFullDigits} />}
                        label="Расширенная (8 знаков после запятой)"
                    />
                </ListItem>
                <Divider />
                <MenuItem onClick={handleLogout} className={classes.navItem}>
                    <ListItemIcon>
                        <ExitToApp fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Выйти из системы" />
                </MenuItem>
            </Menu>
        </div>
    );
});

import { InventoryCategoryItem, InventoryProductItem, InventoryProps } from '../store/InventoriesStore';
import { FilterParamsProps } from '../typings/table';
import { getUrl, request } from './request';

import { ServiceBase } from './serviceBase';

class InventoriesService extends ServiceBase {
    complete = (id: string, data: any) => request(`${this.path}/complete/` + id, data, 'POST');
    sign = (id: string) => request(`${this.path}/sign/` + id, null, 'GET');
    downloadUrl = (id: string) => getUrl(`${this.path}/download/` + id);
}

const service = new InventoriesService({ path: 'inventories' });

const { get, getData, create, update, remove, complete, sign, downloadUrl } = service;

const getInventory = async (id: string) => {
    let object = await get(id);

    if (object && object?.data?.members) {
        object.data.member_ids = object.data.members.map((m: any) => m.member_id);
        return object.data;
    }
};

const getInventories = async (params: FilterParamsProps) => {
    const data = await getData(params);
    if (data.rows) {
        data.rows = data.rows.map((item: any) => {
            item.member_ids = item.members.map((m: any) => m.member_id);
            return item;
        });
    }
    return data;
};

function memberIdsToMembers(data: InventoryProps) {
    const members = [];
    if (data.member_ids) {
        for (let member_id of data.member_ids) {
            const member = data.members ? data.members.find((member) => member.member_id === member_id) : undefined;
            if (member) {
                members.push(member);
            } else {
                members.push({ member_id });
            }
        }
    }
    data.members = members;
    return data;
}

export function groupByCategories(balances: InventoryProps['balances']) {
    const categories = new Map<number, InventoryCategoryItem>();

    balances.forEach((balance, index) => {
        const category =
            categories.get(balance.category_id) ??
            categories
                .set(balance.category_id, {
                    name: balance.category_name,
                    products: new Map<number, InventoryProductItem>(),
                })
                .get(balance.category_id);

        const product =
            category!.products.get(balance.product_id) ??
            category!.products
                .set(balance.product_id, {
                    name: balance.product_name,
                    sku: balance.product_sku,
                    balances: [],
                })
                .get(balance.product_id);

        product!.balances.push({ ...balance, index });
    });

    return categories;
}

const createInventory = async (data: InventoryProps) => {
    return await create(memberIdsToMembers(data));
};

const updateInventory = async (id: string, data: InventoryProps) => {
    return await update(id, memberIdsToMembers(data));
};

export {
    getInventory,
    getInventories,
    createInventory,
    updateInventory,
    remove as removeInventory,
    complete as completeInventory,
    sign as signInventory,
    downloadUrl as downloadUrlInventory,
};

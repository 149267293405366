import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Typography, ListItem, ListItemText, ListItemAvatar } from '@mui/material';
import { Image } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { DividerWithTitle } from '../../../components/DividerWithTitle';

import { DialogData, TableItem } from '../../../typings/table';
import { getProducts } from '../../../services/products';
import { nl2br } from '../../../utils/nl2br';

interface ViewProductProps {
    current?: DialogData;
}

const useStyles = makeStyles(() => ({
    link: {
        textDecoration: 'none',
        color: '#333',
    },
    chip: {
        cursor: 'pointer',
        margin: 2,
    },
    page: {
        height: '100%',
        minWidth: 480,
    },
    listTitle: {
        fontWeight: 'bold',
        marginRight: 4,
    },
    listItem: {
        margin: '0',
    },
}));

export const ViewItem: FC<ViewProductProps> = ({ current }) => {
    const classes = useStyles();
    const [products, setProducts] = useState<TableItem[]>([]);
    const currentId = current?.id;

    useEffect(() => {
        setProducts([]);

        if (!currentId) {
            return;
        }

        getProducts({ filterData: { suppliers: { id: currentId } }, order: 'name', orderDir: 'asc' }).then((data) => {
            if (data.rows) {
                setProducts(data.rows);
            }
        });
    }, [setProducts, currentId]);

    if (!current) {
        return null;
    }

    return (
        <div className={classes.page}>
            <DividerWithTitle>Данные</DividerWithTitle>
            <Typography variant="body1">
                <span className={classes.listTitle}>ИНН:</span> {current.inn || '–'}
            </Typography>
            <DividerWithTitle>Контакты</DividerWithTitle>
            <Typography variant="body1">
                <span className={classes.listTitle}>ФИО:</span>{' '}
                {[current.last_name, current.first_name, current.patronymic_name].filter(Boolean).join(' ') || '–'}
            </Typography>
            <Typography variant="body1">
                <span className={classes.listTitle}>Телефон:</span> {current.phone || '–'}
            </Typography>
            <Typography variant="body1">
                <span className={classes.listTitle}>Почта:</span> {current.email || '–'}
            </Typography>
            {products?.length ? <DividerWithTitle>Поставляемые продукты</DividerWithTitle> : ''}
            {products?.map((product: TableItem) => {
                return (
                    <Link
                        key={product.id}
                        target="_blank"
                        to={`/dashboard/product/${product.id}`}
                        className={classes.link}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <ListItem className={classes.listItem} button>
                            <ListItemAvatar>
                                <Avatar variant="rounded" src={`/${product.photo}`}>
                                    <Image />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText>{product.name}</ListItemText>
                        </ListItem>
                    </Link>
                );
            })}
            <DividerWithTitle>Дополнительно</DividerWithTitle>
            <div>{nl2br(current.comments)}</div>
        </div>
    );
};

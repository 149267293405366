import React, { FC, useCallback, useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useFormik, FormikProvider } from 'formik';

import { Alert, TablePagination, Divider, Snackbar } from '@mui/material';

import { ButtonsPanel } from './ButtonsPanel';

import { Filter } from '../../Filter';
import { ColumnsPanel } from '../../ColumnsPanel';
import { ModuleTable } from '../../ModuleTable/ModuleTable';
import { DialogWithEdit, EditDialogProps } from '../../Dialog/withEdit/DialogWithEdit';
import { Page, PageProps } from '../Page';
import { StoreWithTable } from '../../../store';
import { getErrorMessage } from '../../../utils/getErrorMessage';
import { ColumnProps, FormProps, TableItem, DialogData } from '../../../typings/table';
import * as yup from 'yup';

interface ValidationSchemaClosureProps {
    isEdit: boolean;
    data: DialogData;
}

interface PageWithTableProps extends PageProps {
    /** Хранилище */
    store?: StoreWithTable;
    /** Столбцы в таблице */
    columns?: ColumnProps[];
    /** Схема для формы создания/редактирования */
    validationSchema?: yup.AnyObjectSchema | ((props: ValidationSchemaClosureProps) => yup.AnyObjectSchema);
    /** Шаблон формы оздания/редактирования */
    EditForm?: FC<FormProps>;
    /** Кастомные кнопки для формы редактирования объекта */
    EditButtons?: FC<FormProps>;
    /** Пропсы диалога для перезаписи поведения по-умолчанию */
    EditDialogProps?: EditDialogProps | ((props: FormProps) => EditDialogProps);
    /** Шаблон для карточки просотра */
    ViewTemplate?: FC<{ current?: DialogData }>;
    /** Шаблон для фильтра */
    FilterForm?: FC;
    /** Дефолтные поля для карточки создания */
    initialValues?: Record<string, any>;
    /** Есть ли кнопка удаления */
    withRemove?: boolean;
    /** Отключить возможность редактировать */
    disableEdit?: boolean;
    /** Отключить пагинацию */
    disabePaging?: boolean;
    /** Отключить возможность открывать элемент таблицы */
    disabeItemOpen?: boolean;
    /** Какой-то конкретный заголовок детальной карточки */
    detailTitle?: string;
    /** Какое-то конкретное поле для заголовка детальной карточки */
    detailTitleField?: string;
    /** Колбэк на формирование заголовка карточки */
    getModalTitle?: (item?: TableItem) => string;
    isOpenDetail?: boolean;
    /** Отключение панели колонок */
    disableColumnsPanel?: boolean;
    /** Нужна ли кнопка редактирования */
    withEditButton?: boolean;
    justOpenItem?: boolean;
    getRecordKey?: (record: Record<string, any>) => string;
}

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const PageWithTable: FC<PageWithTableProps> = observer(
    ({
        store = {} as StoreWithTable,
        EditForm,
        ViewTemplate,
        FilterForm,
        validationSchema,
        EditButtons,
        initialValues,
        withRemove = true,
        detailTitle,
        detailTitleField,
        disableEdit,
        disabePaging,
        getModalTitle,
        isOpenDetail,
        disableColumnsPanel,
        disabeItemOpen,
        withEditButton,
        EditDialogProps,
        justOpenItem,
        getRecordKey,
        ...props
    }) => {
        const {
            changeOrder,
            itemPageLink,
            data = {},
            selected = [],
            updateSelected,
            changeSelected,
            removeSelected,
            loadData,
            loadItem,
            update,
            loading,
            primaryKeyField,
            filterParams,
            updateFilterParams,
            toggleOpenFilter,
            filterState,
            columnsPanelState,
            clearFilter,
            messageInfo,
            hideMessageInfo,
            toggleColumnsPanel,
            columns,
            toggleColumn,
            getLink,
            getCellLink,
            tableName,
        } = store;
        const { items = [], errors } = data;

        const query = useQuery();
        const navigate = useNavigate();

        const [modalTitle, setModalTitle] = useState('');

        const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
        const [currentDialogData, setCurrentDialogData] = useState<DialogData>(null);
        const [error, setError] = useState<string>('');

        const isEdit = Boolean(currentDialogData);

        const handleChangePage = (event: unknown, newPage: number) => {
            updateFilterParams({ page: newPage });
        };

        const handleToggleFilter = () => {
            toggleOpenFilter();
        };

        const handleToggleColumns = () => {
            toggleColumnsPanel();
        };

        const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
            updateFilterParams({ limit: +event.target.value, page: 0 });
        };

        const currentId = useMemo(() => (currentDialogData as any)?.id, [currentDialogData]);

        useEffect(() => {
            if (loadData) {
                loadData();
            }
        }, [loadData]);

        const openDialog = useCallback(
            (tableData?: TableItem, isCreate?: boolean) => {
                setModalTitle('');
                const primaryKeyId = tableData?.[primaryKeyField];
                if (primaryKeyId || (tableData && isCreate)) {
                    if (isOpenDetail && !withEditButton && !isCreate) {
                        // открываем новую страницу с записью, если указан параметр
                        return window.open(`${itemPageLink}/${primaryKeyId}`);
                    }

                    loadItem(primaryKeyId).then((loadedData: any) => {
                        if (loadedData === undefined) {
                            setCurrentDialogData(tableData);
                            if (!isCreate) {
                                setModalTitle(getModalTitle?.(tableData) || '');
                            }
                            setIsOpenEditDialog(true);
                            return;
                        }

                        if (loadedData?.data) {
                            setCurrentDialogData(loadedData.data);
                            if (!isCreate) {
                                setModalTitle(getModalTitle?.(loadedData.data) || '');
                            }
                            setIsOpenEditDialog(true);
                        }

                        if (loadedData?.error) {
                            setCurrentDialogData(null);
                        }
                    });
                } else {
                    setCurrentDialogData(null);
                    setIsOpenEditDialog(true);
                }
            },
            [getModalTitle, loadItem, primaryKeyField, isOpenDetail, itemPageLink, withEditButton],
        );

        const handleClickItem = useCallback(
            (data?: TableItem, field?: string) => {
                setModalTitle('');

                if (data?.[primaryKeyField]) {
                    if ((isOpenDetail && withEditButton) || getLink || getCellLink) {
                        // открываем новую страницу с записью, если указан параметр
                        if (getCellLink) {
                            const link = getCellLink(data, field);

                            return link && window.open(link);
                        } else if (getLink) {
                            const link = getLink(data);

                            return link && window.open(link);
                        } else {
                            return window.open(`${itemPageLink}/${data?.[primaryKeyField]}`);
                        }
                    }
                }
            },
            [primaryKeyField, isOpenDetail, itemPageLink, withEditButton, getLink, getCellLink],
        );

        const isCreate = query.has('create');
        const dataString = query.get('data');

        // Если есть параметр create, то открываем страницу создания новой записи
        useEffect(() => {
            if (isCreate) {
                let dataObject = {};

                if (dataString) {
                    try {
                        dataObject = JSON.parse(dataString);
                    } catch {}
                }

                openDialog(dataObject as TableItem, true);
            }
        }, [isCreate, openDialog, dataString]);

        const rightHeader = (
            <ButtonsPanel
                handleOpenEditDialog={openDialog}
                handleRemove={removeSelected}
                handleFilter={handleToggleFilter}
                handleColumns={handleToggleColumns}
                withRemoveButton={Boolean(items?.length && withRemove)}
                removeDisabled={!selected.length}
                withFilter={Boolean(FilterForm)}
                filterChanged={filterState.isChanged}
                filterOpen={filterState.isOpen}
                withAdd={Boolean(EditForm)}
                withColumnsPanel={!disableColumnsPanel}
            />
        );

        const cleanupParams = useCallback(() => {
            query.delete('create');
            query.delete('data');
            navigate({ search: query.toString() }, { replace: true });
        }, [query, navigate]);

        const closeDialog = useCallback(() => {
            setIsOpenEditDialog(false);
            setError('');
            cleanupParams();
        }, [cleanupParams, setError, setIsOpenEditDialog]);

        const editFormConfig = useFormik({
            enableReinitialize: true,
            validateOnChange: false,
            initialValues: { ...(currentDialogData || initialValues) },
            validationSchema: validationSchema
                ? typeof validationSchema === 'function'
                    ? validationSchema({ isEdit, data: currentDialogData })
                    : validationSchema
                : undefined,
            onSubmit: async (values: any, { resetForm }) => {
                const data = await update(currentId, values);
                if (data?.errors) {
                    setError(getErrorMessage(data.errors, tableName));
                } else {
                    setIsOpenEditDialog(false);
                    resetForm();
                    loadData();
                }
            },
        });

        const resetForm = editFormConfig.resetForm;

        const closeDialogAndReload = useCallback(() => {
            setIsOpenEditDialog(false);
            resetForm();
            loadData();
        }, [loadData, resetForm, setIsOpenEditDialog]);

        const handleSaveData = useCallback(() => {
            editFormConfig.handleSubmit();
            setError('');
            cleanupParams();
        }, [editFormConfig, cleanupParams]);

        const { page = 0, limit = 25 } = filterParams;

        const underTitle = (
            <>
                {FilterForm ? (
                    <Filter
                        isVisble={filterState.isOpen}
                        FilterForm={FilterForm}
                        filterValues={filterParams.filterData}
                        onFilter={updateFilterParams}
                        onClear={clearFilter}
                    />
                ) : null}
                {!disableColumnsPanel ? (
                    <ColumnsPanel columns={columns} isVisble={columnsPanelState.isOpen} onChange={toggleColumn} />
                ) : null}
            </>
        );

        const formProps: FormProps = {
            isEdit,
            isOpen: isOpenEditDialog,
            setError,
            data: currentDialogData,
            openDialog,
            closeDialog,
            closeDialogAndReload,
            reloadData: loadData,
        };

        const overwriteProps = typeof EditDialogProps === 'function' ? EditDialogProps(formProps) : EditDialogProps;

        return (
            <FormikProvider value={editFormConfig}>
                <Page {...props} rightHeader={rightHeader} underTitle={underTitle}>
                    <Snackbar
                        open={!!messageInfo}
                        onClose={hideMessageInfo}
                        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                    >
                        <Alert onClose={hideMessageInfo} severity="info">
                            {messageInfo}
                        </Alert>
                    </Snackbar>
                    <DialogWithEdit
                        itemPageLink={itemPageLink}
                        data={currentDialogData}
                        onClose={closeDialog}
                        onConfirm={handleSaveData}
                        title={modalTitle || detailTitle}
                        titleField={detailTitleField}
                        disableEdit={disableEdit}
                        primaryKeyField={primaryKeyField}
                        editTemplate={EditForm ? <EditForm {...formProps} /> : null}
                        formButtons={EditButtons ? <EditButtons {...formProps} /> : null}
                        viewTemplate={ViewTemplate ? <ViewTemplate current={currentDialogData} /> : null}
                        loading={loading}
                        isOpen={formProps.isOpen}
                        {...overwriteProps}
                        DialogProps={{
                            error,
                            ...overwriteProps?.DialogProps,
                            TransitionProps: {
                                onExited: () => {
                                    editFormConfig.resetForm();
                                    setCurrentDialogData(null);
                                },
                                ...overwriteProps?.DialogProps?.TransitionProps,
                            },
                        }}
                    />
                    <ModuleTable
                        columns={columns}
                        data={items}
                        orderBy={filterParams.order}
                        orderDirection={filterParams.orderDir}
                        changeOrder={changeOrder}
                        selected={selected}
                        updateSelected={updateSelected}
                        canSelect={Boolean(items?.length && withRemove)}
                        changeSelected={changeSelected}
                        handleEdit={!disabeItemOpen ? openDialog : () => {}}
                        onRowClick={handleClickItem}
                        onCellClick={!!getCellLink ? handleClickItem : undefined}
                        getRecordKey={getRecordKey}
                        page={page}
                        loading={loading}
                        errors={errors}
                        withEditButton={withEditButton}
                        justOpenItem={justOpenItem}
                    />
                    <Divider />
                    {Boolean(items?.length || (!items?.length && page > 0)) && !disabePaging && (
                        <TablePagination
                            style={{ whiteSpace: 'nowrap', minHeight: 52 }}
                            rowsPerPageOptions={[50, 100, 200]}
                            component="div"
                            count={-1}
                            rowsPerPage={limit}
                            page={page}
                            labelRowsPerPage="Записей на страницу:"
                            labelDisplayedRows={({ from, to }) => {
                                const lastIndex = Math.min(items.length + page * limit, to);
                                return from <= lastIndex ? `Записи с ${from} по ${lastIndex}` : 'Данных нет';
                            }}
                            nextIconButtonProps={{
                                disabled: items.length < Number(limit) || items.length === 0,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </Page>
            </FormikProvider>
        );
    },
);

import { FC } from 'react';
import * as yup from 'yup';

import { PageWithTable } from '../../components/Page/withTable/PageWithTable';
import { InputText } from '../../components/Inputs/Text';
import { InputNumber } from '../../components/Inputs/Number';

import { EditForm } from './Forms';
import { ViewItem } from './ViewItem';
import { suppliersStore } from '../../store/SuppliersStore';

interface SuppliersPageProps {
    title?: string;
}

const validationSchema = yup.object({
    first_name: yup.string().nullable(),
    last_name: yup.string().nullable(),
    patronymic_name: yup.string().nullable(),
    name: yup.string().required('Название обязателено'),
    inn: yup.string().nullable(),
    email: yup.string().email('Некорректно указана почта').nullable(),
    phone: yup.string().nullable(),
    comments: yup.string().nullable(),
    categories: yup.array().nullable(),
    products: yup.string().nullable(),
});

const initialValues = {
    name: '',
    email: '',
    first_name: '',
    last_name: '',
    patronymic_name: '',
    inn: '',
    phone: '',
    comments: '',
    categories: '',
    products: '',
};

const FilterForm: FC = () => (
    <>
        <InputText name="filter" label="Название" />
        <InputNumber name="inn" label="ИНН" maxLength={12} placeholder="123456789012" />
        <InputText name="email" label="Почта" />
    </>
);

export const SuppliersPage: FC<SuppliersPageProps> = ({ title }) => {
    return (
        <>
            <PageWithTable
                title={title}
                store={suppliersStore}
                EditForm={EditForm}
                ViewTemplate={ViewItem}
                FilterForm={FilterForm}
                validationSchema={validationSchema}
                initialValues={initialValues}
            />
        </>
    );
};

import { observer } from 'mobx-react';

import { AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import { ProfileMenu } from '../ProfileMenu';
import { DictionariesMenu } from '../DictionariesMenu';

import { appStore } from '../../store/AppStore';

const AppBarStyled = styled(AppBar)(({ theme }) => ({
    background: 'linear-gradient(to right, #f46b45, #eea849);',
    color: '#fff',
    zIndex: theme.zIndex.drawer + 1,
    paddingRight: 16,
}));

const Title = styled(Typography)(() => ({
    flexGrow: 1,
}));

export const Header = observer(() => {
    return (
        <AppBarStyled
            position="fixed"
            style={{
                zIndex: 1600,
            }}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open navigation"
                    onClick={appStore.toggleNavigation}
                    edge="start"
                >
                    <Menu />
                </IconButton>
                <Title variant="h5" noWrap>
                    SLT
                </Title>
                <DictionariesMenu />
                <ProfileMenu />
            </Toolbar>
        </AppBarStyled>
    );
});

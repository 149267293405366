import { FC } from 'react';
import * as yup from 'yup';

import { PageWithTable } from '../../components/Page/withTable/PageWithTable';
import { InputText, Checkbox } from '../../components/Inputs';

import { PageProps } from '../../components/Page';
import { manufacturersStore } from '../../store/ManufacturersStore';

interface ManufacturersPageProps extends PageProps {
    title?: string;
}

const validationSchema = yup.object({
    name: yup.string().required('Наименование обязателено'),
    description: yup.string().nullable(),
});

const initialValues = {
    name: '',
    description: '',
    is_manufacture_default: false,
};

const EditForm: FC = () => (
    <form>
        <InputText name="name" label="Наименование" required fullWidth />
        <Checkbox name="is_own" label="Свой" />
        <Checkbox name="is_manufacture_default" label="По-умолчанию при производстве" />
    </form>
);

export const ManufacturersPage: FC<ManufacturersPageProps> = ({ title, ...props }) => {
    return (
        <PageWithTable
            title={title}
            store={manufacturersStore}
            EditForm={EditForm}
            validationSchema={validationSchema}
            initialValues={initialValues}
            disableColumnsPanel
            {...props}
        />
    );
};

import { FC, useCallback, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { DictionariesType } from '../../../typings/dictionaries';

import { Select, FormSelectProps } from './Select';
import { Autocomplete } from './Autocomplete';
import { AutocompleteMultiple } from './AutocompleteMultiple';
import { FormikValues, useFormikContext } from 'formik';
import { dictionariesStore } from '../../../store/DictionariesStore';

interface FormSelectWithDictionaryProps extends FormSelectProps {
    type: DictionariesType;
    enableSystem?: boolean;
    filterParams?: Record<string, any>;
    selectedCallback?: (data?: any) => void;
    withoutAutocomplete?: boolean;
    multiple?: boolean;
}

export const SelectWithDictionary: FC<FormSelectWithDictionaryProps> = observer(
    ({ type, enableSystem, filterParams, selectedCallback, withoutAutocomplete, multiple, ...props }) => {
        const [isLoading, setLoading] = useState(false);
        const [isLoaded, setLoaded] = useState(true);

        const loadData = useCallback(() => {
            setLoaded(false);
            setLoading(true);

            dictionariesStore.loadDictionaries({ filterData: filterParams }).finally(() => {
                setLoading(false);
                setLoaded(true);
            });
        }, [filterParams]);

        const { values } = useFormikContext<FormikValues>();

        const items = dictionariesStore.dictionaries[type].filter((item: any) => enableSystem || !item.is_system);
        const currentValue = _.get(values, props.name);

        const currentItem = items.find((item) => String(item.id) === String(currentValue)) || null;

        useEffect(() => {
            if (selectedCallback) {
                selectedCallback(currentItem);
            }
        }, [selectedCallback, currentItem]);

        const Component = withoutAutocomplete ? Select : multiple ? AutocompleteMultiple : Autocomplete;

        return (
            <Component
                {...props}
                multiple={multiple}
                items={items}
                onRefresh={loadData}
                isLoading={isLoading}
                isLoaded={isLoaded}
            />
        );
    },
);

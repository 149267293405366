import React from 'react';

export const nl2br = (text: string | undefined): React.ReactNode => {
    if (!text) {
        return undefined;
    }

    const lines = text.split('\n');

    const nodes: React.ReactNode[] = [];

    lines.forEach((v) => {
        nodes.push(v);
        nodes.push(<br />);
    });

    return nodes.length ? nodes.slice(0, nodes.length - 1) : undefined;
};

import { FC, useState, useRef, useEffect, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { FormikValues, FieldArray, useFormikContext } from 'formik';
import { Button, Paper, MenuItem, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ButtonWithRemove } from '../../../components/Button/withRemove/ButtonWithRemove';
import { DividerWithTitle } from '../../../components/DividerWithTitle';
import { InputNumber, InputText, SelectWithData } from '../../../components/Inputs';

import { getBalances } from '../../../services/transactions';
import { BalanceProps } from '../../../store/BalanceStore';
import { observer } from 'mobx-react';
import { BalanceMenuItem } from './BalanceMenuItem';

interface BalanceFormProps {
    index: number;
    stockId: number;
}

interface BalancesByStockProps {
    stockId: number;
}

const useStyles = makeStyles(() => ({
    contentBase: {
        margin: '12px 0',
        maxHeight: '280px',
        overflow: 'auto',
        position: 'relative',
        borderRadius: 6,
        backgroundColor: '#f5f5f5',
    },
    contentWithItems: {
        padding: 12,
        border: '1px solid rgb(200, 200, 200)',
    },
    itemCard: {
        padding: '24px 12px',
        margin: '12px 0',
    },
    menuItem: {
        display: 'block',
        borderBottom: '1px solid rgb(200, 200, 200)',
    },
}));

const getBatches = (items: BalanceProps[], selectedBalanceIds: number[] = []) => {
    return items
        .filter((item: BalanceProps) => {
            return !item.stock_is_manufacture && selectedBalanceIds.indexOf(item.id) === -1;
        })
        .map((item: BalanceProps) => {
            const _item: BalanceProps = {
                ...item,
            };

            return _item;
        }, []);
};

const BalanceForm: FC<BalanceFormProps> = observer(({ index, stockId }) => {
    const classes = useStyles();
    const { values, setFieldValue } = useFormikContext<FormikValues>();
    const [currentMeasure, setCurrentMeasure] = useState('');
    const [balances, setBalances] = useState<BalanceProps[]>([]);
    const [selectedBalance, setSelectedBalance] = useState();

    const { category_id: currentCategoryId, product_id: currentProductId } = values?.items?.[index];

    const categoryFilter = useMemo(
        () => ({
            stocks_balances: {
                stock_id: stockId,
                in_stock: true,
            },
        }),
        [stockId],
    );

    const productsFilter = useMemo(
        () => ({
            category_id: currentCategoryId,
            stocks_balances: {
                stock_id: stockId,
                in_stock: true,
            },
        }),
        [currentCategoryId, stockId],
    );

    const selectedCategoryCallback = useCallback(
        (selected: any) => {
            if (!selected) {
                setFieldValue(`items.${index}.product_id`, null);
            }
        },
        [setFieldValue, index],
    );

    const selectedProductCallback = useCallback(
        (selected: any) => {
            if (selected) {
                setFieldValue(`items.${index}.category_id`, selected?.category_id);
            }
            setFieldValue(`items.${index}.measure_name`, selected?.measure_name);
            setCurrentMeasure(selected?.measure_name);
            setFieldValue(`items.${index}.sku`, selected?.sku);
        },
        [setFieldValue, index],
    );

    const selectedBalanceCallback = (selected: any) => {
        setFieldValue(`items.${index}.lot`, selected?.lot);
        return setSelectedBalance(selected);
    };

    useEffect(() => {
        if (!stockId || !currentProductId) {
            setBalances([]);
            return;
        }

        getBalances({
            filterData: { stock_id: stockId, in_stock: true, product_id: currentProductId },
            orderDir: 'asc',
            order: 'created_at',
        }).then((data) => {
            if (data.rows) {
                setBalances(data.rows);
            }
        });
    }, [stockId, currentProductId]);

    const batches = useMemo(() => {
        const items = values?.items || [];
        // получаем список выбранных партий, но не текущей
        const selectedBalanceIds =
            items?.map((item: any) => item.balance_id).filter((id: number) => items?.[index]?.balance_id !== id) || [];

        return getBatches(balances, selectedBalanceIds);
    }, [balances, values?.items, index]);

    const isBalanceDisabled = !stockId || !currentProductId;

    return (
        <>
            <SelectWithData
                disabled={!stockId}
                name={`items.${index}.category_id`}
                type="categories"
                label="Категория"
                filterParams={categoryFilter}
                selectedCallback={selectedCategoryCallback}
                fullWidth
            />
            <SelectWithData
                disabled={!stockId}
                name={`items.${index}.product_id`}
                type="products"
                label="Продукт"
                filterParams={productsFilter}
                selectedCallback={selectedProductCallback}
                fullWidth
            />
            <InputText name={`items.${index}.sku`} label="УИП" fullWidth disabled />
            <SelectWithData
                disabled={isBalanceDisabled}
                name={`items.${index}.balance_id`}
                data={batches}
                label="Партия"
                fieldName="product_name"
                fieldId="id"
                withoutRefresh
                fullWidth
                selectedCallback={selectedBalanceCallback}
                withoutAutocomplete
                menuItem={(item: BalanceProps) => (
                    <MenuItem className={classes.menuItem} key={item.id} value={item.id}>
                        <BalanceMenuItem item={item} />
                    </MenuItem>
                )}
            />
            <InputText name={`items.${index}.lot`} label="Лот" fullWidth disabled />
            <InputNumber
                name={`items.${index}.quantity`}
                label="Количество"
                measure={currentMeasure}
                isShort
                fullWidth
                withoutRound={true}
                mantissa={3}
                required
            />
            <Button
                color="inherit"
                onClick={() => {
                    // @ts-ignore
                    setFieldValue(`items.${index}.quantity`, selectedBalance?.balance || 0);
                }}
            >
                Указать всё кол-во
            </Button>
        </>
    );
});

export const BalancesByStock: FC<BalancesByStockProps> = ({ stockId }) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const classes = useStyles();
    const { values } = useFormikContext<FormikValues>();
    const items = values?.items || [];

    if (!stockId) {
        return <Alert severity="info">Необходимо выбрать склад</Alert>;
    }

    return (
        <FieldArray name="items">
            {({ remove, push }) => {
                if (!items.length) {
                    push({});
                }

                return (
                    <>
                        <div
                            className={clsx(items.length ? classes.contentWithItems : '', classes.contentBase)}
                            ref={contentRef}
                        >
                            {items.map((component: any, index: number) => {
                                return (
                                    <span ref={index + 1 === items.length ? contentRef : undefined}>
                                        <Paper
                                            elevation={3}
                                            key={component.id || `component-${index}`}
                                            className={classes.itemCard}
                                        >
                                            <DividerWithTitle>
                                                {index + 1}{' '}
                                                {items.length > 1 ? (
                                                    <ButtonWithRemove buttonText="" onRemove={() => remove(index)} />
                                                ) : null}
                                            </DividerWithTitle>
                                            <BalanceForm index={index} stockId={stockId} />
                                        </Paper>
                                    </span>
                                );
                            })}
                        </div>
                        <Button
                            variant="contained"
                            onClick={() => {
                                push({});
                                setTimeout(() => contentRef?.current?.scrollIntoView({ behavior: 'smooth' }), 100);
                            }}
                        >
                            Добавить партию
                        </Button>
                    </>
                );
            }}
        </FieldArray>
    );
};

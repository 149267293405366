import React, { FC } from 'react';
import * as yup from 'yup';

import { Tabs, Tab, Box, FormLabel } from '@mui/material';

import { observer } from 'mobx-react';

import { FormProps } from '../../../typings/table';
import { InputText } from '../../../components/Inputs';
import { AccountSelect } from '../../../components/Inputs/Select/AccountSelect';
import { ProductsList } from './ProductsList';
import { padZero } from '../../../utils/padZero';
import { FormikValues, useFormikContext } from 'formik';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...rest } = props;

    return (
        <div role="tabpanel" hidden={value !== index} {...rest}>
            <Box>{children}</Box>
        </div>
    );
};

yup.addMethod(yup.array, 'unique', function (message, mapper, getPath) {
    return this.test('unique', message, function (list: any = []) {
        const set = Array.from(new Set(list.map(mapper)));
        const isUnique = list.length === set.length;

        if (isUnique) {
            return true;
        }

        // @ts-ignore
        const idx = list.findIndex((l, i) => mapper(l) !== set[i]);
        // @ts-ignore
        return this.createError({
            path: getPath(idx),
            message: message,
        });
    });
});

export const validationSchema = yup.object().shape({
    name: yup.string().required('Название обязательно'),
    developer_id: yup.number().required('Разработчик обязателен'),
    products: yup
        .array()
        .of(
            yup.object().shape({
                category_id: yup.string().required('Обязательное поле'),
                product_id: yup.string().required('Обязательное поле'),
            }),
        )
        //@ts-ignore
        .unique(
            'Продукты должны быть уникальные',
            (a: any) => a.product_id,
            (idx: number) => `products.${idx}.product_id`,
        ),
});

export const EditForm: FC<FormProps> = observer(({ isEdit }) => {
    const [currentTab, setCurrentTab] = React.useState(0);

    const { values } = useFormikContext<FormikValues>();

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
    };

    const projectNumber = values?.number;

    return (
        <form>
            <Tabs variant="fullWidth" value={currentTab} onChange={handleChangeTab} aria-label="nav tabs example">
                <Tab label="Общее" />
                <Tab label="Продукты" />
            </Tabs>
            <TabPanel value={currentTab} index={0}>
                <div style={{ display: 'flex', alignItems: 'center', paddingTop: 15, paddingBottom: 15 }}>
                    <FormLabel>Номер проекта:</FormLabel>
                    <div style={{ paddingLeft: 5 }}>{!isEdit ? '<будет присвоен>' : padZero(projectNumber, 2)}</div>
                </div>
                <InputText name="name" style={{ minWidth: 300 }} fullWidth required label="Название" />
                <AccountSelect name="developer_id" fullWidth style={{ minWidth: 300 }} required label="Разработчик" />
                <InputText name="description" style={{ minWidth: 300 }} multiline fullWidth label="Описание проекта" />
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
                <ProductsList />
            </TabPanel>
        </form>
    );
});

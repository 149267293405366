import { FilterParamsProps } from '../typings/table';
import { getDataRequestParams } from '../utils/getDataRequestParams';
import { request } from './request';
import { ServiceBase } from './serviceBase';

class ProjectsService extends ServiceBase {
    getProducts = (params: FilterParamsProps) => {
        const requestParams = getDataRequestParams(params);
        return request(`${this.path}/products`, requestParams, 'POST');
    };
}

const service = new ProjectsService({ path: 'projects' });

const { get, getData, getProducts, create, update, remove } = service;

export {
    get as getProject,
    getData as getProjects,
    getProducts as getProjectProducts,
    create as createProject,
    update as updateProject,
    remove as removeProject,
};

import { observable, configure } from 'mobx';
import { TableStoreBase, TableData } from './BaseTableStore';

import { getInventories, createInventory, removeInventory, updateInventory } from '../services/inventories';
import { ColumnProps } from '../typings/table';

configure({ enforceActions: 'observed' });

type InventoryMember = {
    id?: number;
    member_id: number;
};

export interface SignRecord {
    id: number;
    created_at: string;
    created_by_id: number;
    created_by_name: string;
}

export interface InventoryProductItem {
    name: string;
    sku: string;
    balances: any[];
}

export interface InventoryCategoryItem {
    name: string;
    products: Map<number, InventoryProductItem>;
}

export type InventoryBalancesMap = Map<number, InventoryCategoryItem>;

export interface InventoryProps {
    [key: string]: any;
    id: number | string;
    number: string;
    is_completed: boolean;
    completed_at: string;
    responsible_id: number;
    responsible_first_name: string;
    responsible_last_name: string;
    responsible_patronymic_name: string;
    stock_name: string;
    member_ids: number[];
    members: InventoryMember[];
    balances: any[];
    signs: SignRecord[];
}

export interface IInventories extends TableData {
    items?: InventoryProps[];
}

export class InventoriesStore extends TableStoreBase {
    constructor() {
        super('Inventories');
        this.getData = getInventories;
        this.removeItem = removeInventory;
        this.updateItem = updateInventory;
        this.createItem = createInventory;
    }

    @observable columns: ColumnProps[] = [
        { field: 'number', title: 'Номер' },
        { field: 'stock_name', title: 'Склад' },
        { field: 'responsible_last_name', title: 'Ответственный', sortable: true, type: 'responsible' },
        { field: 'completed_at', title: 'Дата завершения', type: 'datetime' },
    ];

    @observable data: IInventories = {};
}

export const inventoriesStore = new InventoriesStore();

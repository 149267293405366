import React, { FC, useCallback, useMemo, useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import numbro from 'numbro';
import { FieldArray, FormikValues, useFormikContext } from 'formik';
import { Button, Paper, Typography, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ButtonWithRemove } from '../../../components/Button/withRemove/ButtonWithRemove';
import { DividerWithTitle } from '../../../components/DividerWithTitle';
import { SelectWithData, InputDate, InputNumber, InputText } from '../../../components/Inputs';
import { getLocalNumber } from '../../../utils/getLocalNumber';
import { appStore } from '../../../store/AppStore';
import { observer } from 'mobx-react';

interface ProductFormProps {
    index: number;
}

const useStyles = makeStyles(() => ({
    contentBase: {
        margin: '12px 0',
        maxHeight: '280px',
        overflow: 'auto',
        position: 'relative',
        borderRadius: 6,
        backgroundColor: '#f5f5f5',
    },
    contentWithItems: {
        padding: 12,
        border: '1px solid rgb(200, 200, 200)',
    },
    itemCard: {
        padding: '24px 12px',
        margin: '12px 0',
    },
}));

const ProductForm: FC<ProductFormProps> = observer(({ index }) => {
    const isFullDigits = appStore.isFullDigits;
    const [currentMeasure, setCurrentMeasure] = useState('');

    const { values, setFieldValue, handleChange } = useFormikContext<FormikValues>();

    const currentCategoryId = values.items[index].category_id;
    const currentSupplierId = values.supplier_id;

    const filterCategoryParams = useMemo(
        () => ({
            supplier_id: currentSupplierId,
        }),
        [currentSupplierId],
    );

    const filterProductParams = useMemo(
        () => ({
            category_id: currentCategoryId,
            suppliers: currentSupplierId ? { id: currentSupplierId } : undefined,
        }),
        [currentCategoryId, currentSupplierId],
    );

    const quantityType = values.items[index].quantity_type;
    const mcostType = values.items[index].mcost_type;
    const mcost_temp = values.items[index].mcost_temp;
    const quantity_in_pack = values.items[index].quantity_in_pack;
    const quantity_packs = values.items[index].quantity_packs;

    const selectedProductCallback = useCallback(
        (selected: any) => {
            if (selected?.category_id) {
                setFieldValue(`items.${index}.category_id`, selected.category_id);
            }

            setCurrentMeasure(selected?.measure_name || '');

            if (selected?.supplier_id) {
                setFieldValue(`items.${index}.supplier_id`, selected.supplier_id);
            }
            setFieldValue(`items.${index}.sku`, selected?.sku || '');
        },
        [setFieldValue, index],
    );

    const selectedCategoryCallback = useCallback(
        (selected: any) => {
            if (!selected) {
                setFieldValue(`items.${index}.product_id`, undefined);
            }
        },
        [setFieldValue, index],
    );

    useEffect(() => {
        setFieldValue(`items.${index}.quantity_type`, 1);
        setFieldValue(`items.${index}.mcost_temp`, 0);
    }, [setFieldValue, index]);

    useEffect(() => {
        setFieldValue(`items.${index}.supplier_id`, values.supplier_id);
    }, [setFieldValue, index, values.supplier_id]);

    useEffect(() => {
        if (quantityType === 1) {
            setFieldValue(`items.${index}.mcost_type`, 1);
        }

        setFieldValue(`items.${index}.quantity`, 1);
        setFieldValue(`items.${index}.quantity_packs`, 1);
        setFieldValue(`items.${index}.quantity_in_pack`, 1);
    }, [quantityType, setFieldValue, index]);

    useEffect(() => {
        let currentMcost = 0;
        let currentQuantity = 1;

        if (+mcostType === 2 && quantity_in_pack) {
            currentMcost = +mcost_temp / +quantity_in_pack;
            currentQuantity = +quantity_in_pack * +quantity_packs;
        }

        if (+mcostType === 3 && quantity_in_pack && quantity_packs) {
            currentMcost = +mcost_temp / (+quantity_in_pack * +quantity_packs);
            currentQuantity = +quantity_in_pack * +quantity_packs;
        }

        setFieldValue(
            `items.${index}.mcost`,
            numbro(currentMcost).format({
                mantissa: 4,
                trimMantissa: true,
                optionalMantissa: true,
            }),
        );

        setFieldValue(
            `items.${index}.mcost_total`,
            numbro(currentMcost * currentQuantity).format({
                mantissa: 4,
                trimMantissa: true,
                optionalMantissa: true,
            }),
        );

        setFieldValue(`items.${index}.quantity`, currentQuantity);
    }, [setFieldValue, index, mcost_temp, quantity_in_pack, quantity_packs, mcostType]);

    const quantity = values.items[index].quantity;
    const mcost = values.items[index].mcost;

    function handleMcostChange(name: string, e: React.ChangeEvent<HTMLInputElement>) {
        handleChange(e);

        const value = Number(e.target.value);

        switch (name) {
            case 'mcost':
                setFieldValue(`items.${index}.mcost_total`, value * quantity);
                break;
            case 'mcost_total':
                setFieldValue(`items.${index}.mcost`, value / quantity);
                break;
            case 'quantity':
                setFieldValue(`items.${index}.mcost_total`, mcost * value);
                break;
        }
    }

    return (
        <>
            <SelectWithData
                name={`items.${index}.category_id`}
                type="categories"
                label="Категория"
                filterParams={filterCategoryParams}
                selectedCallback={selectedCategoryCallback}
                fullWidth
            />
            <SelectWithData
                name={`items.${index}.product_id`}
                type="products"
                label="Продукт"
                filterParams={filterProductParams}
                selectedCallback={selectedProductCallback}
                fullWidth
            />
            <InputText name={`items.${index}.sku`} label="УИП" fullWidth disabled />
            <SelectWithData
                name={`items.${index}.manufacturer_id`}
                type="manufacturers"
                label="Производитель"
                fullWidth
            />
            <InputText name={`items.${index}.lot`} label="Лот" fullWidth />
            <InputDate id="manufactured_at" name={`items.${index}.manufactured_at`} label="Произведено" />
            <InputText name={`items.${index}.description`} label="Описание" fullWidth multiline rows={3} />
            <SelectWithData
                name={`items.${index}.quantity_type`}
                data={[
                    {
                        id: 1,
                        name: 'Единица товара',
                    },
                    {
                        id: 2,
                        name: 'Упаковка товара',
                    },
                ]}
                disableEmpty
                label="Тип партии"
                withoutRefresh
                fullWidth
            />
            {quantityType === 2 ? (
                <InputNumber
                    name={`items.${index}.quantity_packs`}
                    label="Кол-во упаковок"
                    measure="шт."
                    withoutDecimal
                    style={{
                        width: 220,
                    }}
                    fullWidth
                />
            ) : null}
            {quantityType === 2 ? (
                <InputNumber
                    name={`items.${index}.quantity_in_pack`}
                    label="Кол-во продукта в упаковке"
                    measure={currentMeasure}
                    placeholder="1"
                    style={{
                        width: 220,
                    }}
                    fullWidth
                />
            ) : null}
            {quantityType === 1 ? (
                <InputNumber
                    name={`items.${index}.quantity`}
                    label="Количество"
                    measure={currentMeasure}
                    style={{
                        width: 220,
                    }}
                    fullWidth
                    onChange={(e) => handleMcostChange('quantity', e)}
                />
            ) : null}
            {quantityType === 2 ? (
                <SelectWithData
                    name={`items.${index}.mcost_type`}
                    data={[
                        {
                            id: 1,
                            name: 'Единица товара',
                        },
                        {
                            id: 2,
                            name: 'Упаковка товара',
                        },
                        {
                            id: 3,
                            name: 'Общая стоимость',
                        },
                    ]}
                    label="Указать стоимость за..."
                    withoutRefresh
                    disableEmpty
                    fullWidth
                />
            ) : null}
            {+quantityType === 1 || +mcostType === 1 ? (
                <>
                    <InputNumber
                        name={`items.${index}.mcost`}
                        label="Себестоимость единицы"
                        style={{
                            width: 220,
                        }}
                        measure="р."
                        mantissa={3}
                        fullWidth
                        onChange={(e) => handleMcostChange('mcost', e)}
                    />
                    <InputNumber
                        name={`items.${index}.mcost_total`}
                        label="Себестоимость партии"
                        style={{
                            width: 220,
                        }}
                        measure="р."
                        mantissa={3}
                        fullWidth
                        onChange={(e) => handleMcostChange('mcost_total', e)}
                    />
                </>
            ) : null}
            {+mcostType === 2 ? (
                <InputNumber
                    name={`items.${index}.mcost_temp`}
                    label="Стоимость упаковки"
                    style={{
                        width: 220,
                    }}
                    measure="р."
                    fullWidth
                />
            ) : null}
            {+mcostType === 3 ? (
                <InputNumber
                    name={`items.${index}.mcost_temp`}
                    label="Общая стоимость"
                    style={{
                        width: 220,
                    }}
                    measure="р."
                    fullWidth
                />
            ) : null}
            {Number(mcostType) > 1 ? <Divider style={{ margin: 8 }} /> : null}
            {Number(mcostType) === 3 ? (
                <Typography variant="body1">{`Стоимость упаковки: ${getLocalNumber(+mcost_temp / +quantity_packs, {
                    isFullDigits,
                })} р.`}</Typography>
            ) : null}
            {Number(mcostType) === 2 ? (
                <Typography variant="body1">{`Общая стоимость партии: ${getLocalNumber(+mcost_temp * +quantity_packs, {
                    isFullDigits,
                })} р.`}</Typography>
            ) : null}
            {Number(mcostType) > 1 ? (
                <Typography variant="body1">{`Стоимость единицы товара: ${getLocalNumber(values.items[index].mcost, {
                    isFullDigits,
                })} р.`}</Typography>
            ) : null}
            {Number(mcostType) > 1 ? (
                <Typography variant="body1">{`Общее количество товара: ${getLocalNumber(
                    +quantity_in_pack * +quantity_packs,
                    { isFullDigits },
                )} ${currentMeasure}`}</Typography>
            ) : null}
        </>
    );
});

export const ProductsForm: FC = () => {
    const contentRef = useRef<HTMLSpanElement>(null);
    const classes = useStyles();
    const { values } = useFormikContext<FormikValues>();
    const items = values?.items || [];

    return (
        <FieldArray name="items">
            {({ remove, push }) => {
                if (!items.length) {
                    push({});
                }

                return (
                    <>
                        <div className={clsx(items.length ? classes.contentWithItems : '', classes.contentBase)}>
                            {items.map((component: any, index: number) => (
                                <span ref={index + 1 === items.length ? contentRef : undefined}>
                                    <Paper
                                        elevation={3}
                                        key={component.id || `component-${index}`}
                                        className={classes.itemCard}
                                    >
                                        <DividerWithTitle>
                                            {index + 1}{' '}
                                            {items.length > 1 ? (
                                                <ButtonWithRemove buttonText="" onRemove={() => remove(index)} />
                                            ) : null}
                                        </DividerWithTitle>
                                        <ProductForm index={index} />
                                    </Paper>
                                </span>
                            ))}
                        </div>
                        <Button
                            variant="contained"
                            onClick={() => {
                                push({});
                                setTimeout(() => contentRef?.current?.scrollIntoView({ behavior: 'smooth' }), 100);
                            }}
                        >
                            Добавить продукт
                        </Button>
                    </>
                );
            }}
        </FieldArray>
    );
};

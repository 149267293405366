import { FC, useMemo } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { Button, Tooltip, Stack } from '@mui/material';
import { Search, TableChartOutlined } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) => ({
    filterChanged: {
        backgroundColor: '#eea849',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#f46b45',
        },
    },
}));

interface ButtonsPanelProps {
    handleOpenEditDialog: (data?: any) => void;
    handleFilter: () => void;
    handleColumns?: () => void;
    filterChanged: boolean;
    filterOpen: boolean;
}

export const ButtonsPanel: FC<ButtonsPanelProps> = ({
    handleOpenEditDialog,
    handleFilter,
    handleColumns,
    filterChanged,
    filterOpen,
}) => {
    const classes = useStyles();

    const filterTooltipProps: { open?: boolean } = useMemo(
        () => ({
            open: !filterOpen && filterChanged,
        }),
        [filterOpen, filterChanged],
    );

    return (
        <div>
            <Stack direction="row" spacing={1}>
                <Tooltip title="Настройка столбцов" arrow placement="bottom">
                    <Button variant="contained" color="inherit" onClick={handleColumns}>
                        <TableChartOutlined />
                    </Button>
                </Tooltip>
                <Tooltip
                    title={filterChanged ? 'Включён фильтр' : 'Фильтр'}
                    arrow
                    placement="top"
                    {...filterTooltipProps}
                >
                    <Button
                        className={clsx({ [classes.filterChanged]: filterChanged })}
                        variant="contained"
                        color="inherit"
                        onClick={handleFilter}
                    >
                        <Search />
                    </Button>
                </Tooltip>
                <Button variant="contained" color="primary" onClick={handleOpenEditDialog}>
                    Запланировать
                </Button>
            </Stack>
        </div>
    );
};

import { observable, configure, runInAction } from 'mobx';
import { TableStoreBase, TableData } from './BaseTableStore';

import { getManufactures, createManufacture, removeManufacture, updateManufacture } from '../services/manufacture';
import { ColumnProps, FilterParamsProps } from '../typings/table';

configure({ enforceActions: 'observed' });

export interface ManufactureProps {
    [key: string]: any;
    id: number | string;
    first_name?: string;
    patronymic_name?: string;
    last_name?: string;
    phone: string;
    email: string;
    job: string;
    photo?: string;
    status_id: number;
    status_name: string;
}

export interface IManufactures extends TableData {
    items?: ManufactureProps[];
}

export class ManufactureStore extends TableStoreBase {
    constructor() {
        super('manufacture');

        this.getData = this.getDataWithMcost;
        this.removeItem = removeManufacture;
        this.updateItem = updateManufacture;
        this.createItem = createManufacture;

        this.itemPageLink = '/dashboard/manufacture';
    }

    getDataWithMcost = (params: FilterParamsProps) => {
        return getManufactures(params).then((data) => {
            if (data.rows) {
                runInAction(() => {
                    this.data.items = data.rows.map((item: any) => {
                        const mcost =
                            +(item.manufacture_price || 0) +
                            item.components.reduce(
                                (res: number, component: any) =>
                                    (res += Number(
                                        (component.balance_mcost * component.quantity) / item.quantity || 0,
                                    )),
                                0,
                            );

                        item.mcost = +mcost || 0;

                        return item;
                    }, []);
                });
            }
        });
    };

    @observable columns: ColumnProps[] = [
        { field: 'created_at', title: 'Дата начала', sortable: true, type: 'date' },
        { field: 'completed_at', title: 'Дата окончания', sortable: true, type: 'date' },
        { field: 'category_name', title: 'Категория', sortable: true },
        { field: 'product_sku', title: 'УИП', sortable: true },
        { field: 'product_name', title: 'Продукт', sortable: true },
        { field: 'quantity', title: 'Количество', sortable: true, type: 'quantity' },
        { field: 'mcost', title: 'Себестоимость единицы', type: 'withDashAndPrice' },
        { field: 'status_id', title: 'Статус', sortable: true, type: 'manufactureStatus' },
        { field: 'employee_last_name', title: 'Ответственный', sortable: true, type: 'employee' },
        { field: 'comments', title: 'Комментарий', sortable: true },
    ];

    @observable data: IManufactures = {};
}

export const manufactureStore = new ManufactureStore();

import React, { useState, FC } from 'react';

import { Paper, Typography, Avatar, Box, Link, Container } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { LoginForm } from './LoginForm';
import { ReminderForm } from './ReminderForm';
import { Copyright } from '../../components/Copyright';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        height: '100vh',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    loginIcon: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
}));

export const Login: FC = () => {
    const classes = useStyles();
    const [loginAction, setLoginAction] = useState('login');

    return (
        <div className={classes.root}>
            <Container maxWidth="xs">
                <Paper className={classes.paper}>
                    <Avatar className={classes.loginIcon}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h4">
                        SLT
                    </Typography>
                    <Typography variant="body1" color="primary" paragraph>
                        Тест-системы
                    </Typography>
                    {loginAction === 'login' && (
                        <>
                            <LoginForm />
                            <Box mt={1} mb={1}>
                                <Link component="button" onClick={() => setLoginAction('reminder')} variant="body2">
                                    Забыли пароль?
                                </Link>
                            </Box>
                        </>
                    )}
                    {loginAction === 'reminder' && (
                        <>
                            <ReminderForm />
                            <Box mt={1} mb={1}>
                                <Link component="button" onClick={() => setLoginAction('login')} variant="body2">
                                    Войти в систему
                                </Link>
                            </Box>
                        </>
                    )}
                </Paper>
                <Box mt={8} style={{ textAlign: 'center' }}>
                    <Copyright />
                </Box>
            </Container>
        </div>
    );
};

import axios, { AxiosError } from 'axios';

import { getAuthHeader } from '../utils/getAuthHeader';

import { logout } from './authentication';

type MethodType = 'GET' | 'POST' | 'DELETE' | 'PATCH' | 'PUT' | 'HEAD' | 'OPTIONS';

interface RequesOptions {
    method?: MethodType;
    headers?: any;
    data?: any;
    mode?: 'cors' | 'no-cors' | 'same-origin';
    credentials?: 'omit' | 'same-origin' | 'include';
    cache?: 'default' | 'no-store' | 'reload' | 'no-cache' | 'force-cache' | 'only-if-cached';
    redirect?: 'follow' | 'error' | 'manual';
    referrer?: string;
    integrity?: any;
    onUploadProgress?: any;
}

export const getUrl = (action: string) => {
    return encodeURI(process.env.PUBLIC_URL + '/api/' + action);
};

const requestCatch = (e: AxiosError, action: string, method: string) => {
    const status = e.response?.status;

    console.error(`API action: ${action} (${method}) failed: ${e.message}`);

    if (status === 401) {
        logout();

        try {
            // записываем текущее состояние перед выбросом на страницу залогина
            window.history.pushState({ from: { ...window.location } }, '');
        } catch {}

        // редиректим на страницу залогина
        window.location.assign('/login');

        return {
            errors: 'Истёк срок авторизации. Войдите в систему заново.',
        };
    }

    if (status && (status < 100 || status >= 300)) {
        return {
            errors: 'К сожалению, произошла сетевая ошибка',
        };
    }

    return {
        errors: `API action: ${action} (${method}) failed: ${e.message}`,
    };
};

export const upload = (file: any, onUploadProgress: any) => {
    const formData = new FormData();

    const requestUrl = getUrl('upload');

    formData.append('file', file);

    const headers = { Accept: 'application/json', ...getAuthHeader() };

    return axios
        .post(requestUrl, formData, { headers, onUploadProgress })
        .then((res) => {
            return res.data;
        })
        .catch((e: AxiosError) => requestCatch(e, requestUrl, 'POST'));
};

export const request = async (action: string, data?: any, method: MethodType = 'POST') => {
    const requestUrl = getUrl(action);

    const requestOptions: RequesOptions = {
        method,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    };

    if (action !== 'login' && action !== 'password-reset') {
        requestOptions.headers = { ...requestOptions.headers, ...getAuthHeader() };
    }

    if (data) {
        requestOptions.data = JSON.stringify(data);
    }

    return axios(requestUrl, requestOptions)
        .then((res) => {
            return res.data;
        })
        .catch((e: AxiosError) => requestCatch(e, action, method));
};

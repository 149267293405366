import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Alert, Button, CircularProgress, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import * as api from '../../services/passwordReset';
import { getErrorMessage } from '../../utils/getErrorMessage';

interface RemindFormData {
    login: string;
    code?: string;
    password?: string;
    confirmPassword?: string;
}

const validationSchema = yup.object({
    login: yup.string().required('Логин обязателен'),
});

const validationSchemaFull = yup.object({
    login: yup.string().required('Логин обязателен'),
    code: yup.string().required('Код не может быть пустым'),
    password: yup
        .string()
        .min(8, 'Пароль должен состоять минимум из 8 символов')
        .required('Пароль не может быть пустым'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Пароли не совпадают')
        .required('Подтверждение пароля не может быть пустым'),
});

const useStyles = makeStyles((theme: Theme) => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function ReminderForm() {
    const classes = useStyles();
    const [mainError, setMainError] = useState('');
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [isPasswordChanged, setIsPasswordChanged] = useState(false);
    const [processing, setProcessing] = useState(false);

    function handlePasswordReset(data: RemindFormData) {
        const somethingWrong = 'Что-то пошло не так, попробуйте позже';

        if (!isCodeSent) {
            setMainError('');
            setProcessing(true);

            api.passwordReset(data.login)
                .then((r: any) => {
                    if (r) {
                        if (r.codeSent) {
                            setIsCodeSent(true);
                        } else if (r.errors) {
                            setMainError(getErrorMessage(r.errors));
                        }
                    } else {
                        setMainError(somethingWrong);
                    }
                })
                .finally(() => setProcessing(false));
        } else if (!isPasswordChanged) {
            setMainError('');
            setProcessing(true);

            api.passwordReset(data.login, data.code, data.password)
                .then((r: any) => {
                    if (r) {
                        if (r.passwordChanged) {
                            setIsPasswordChanged(true);
                        } else if (r.errors) {
                            setMainError(getErrorMessage(r.errors));
                        }
                    } else {
                        setMainError(somethingWrong);
                    }
                })
                .finally(() => setProcessing(false));
        }
    }

    let isBtnDisabled = false;

    if (processing) {
        isBtnDisabled = true;
    }

    const formik = useFormik({
        initialValues: {
            login: '',
            code: '',
            password: '',
            confirmPassword: '',
        },
        validationSchema: isCodeSent ? validationSchemaFull : validationSchema,
        onSubmit: (values: RemindFormData) => {
            handlePasswordReset(values);
        },
    });

    if (isPasswordChanged) {
        return (
            // @ts-ignore
            <Alert severity="success">Пароль успешно изменён, теперь вы можете войти в систему.</Alert>
        );
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <TextField
                name="login"
                id="login"
                value={formik.values.login}
                onChange={formik.handleChange}
                error={formik.touched.login && Boolean(formik.errors.login)}
                helperText={formik.touched.login && formik.errors.login}
                label="Введите ваш логин или e-mail"
                required
                autoFocus
                fullWidth
                autoComplete="username"
                disabled={isCodeSent}
            />
            {isCodeSent && (
                <>
                    <TextField
                        name="code"
                        id="code"
                        fullWidth
                        value={formik.values.code}
                        onChange={formik.handleChange}
                        error={formik.touched.code && Boolean(formik.errors.code)}
                        helperText={formik.touched.code && formik.errors.code}
                        label="Код, полученный по e-mail"
                        autoFocus
                        autoComplete="off"
                    />
                    <TextField
                        name="password"
                        id="password"
                        fullWidth
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        label="Новый пароль"
                        type="password"
                        autoComplete="new-password"
                    />
                    <TextField
                        name="confirmPassword"
                        id="confirmPassword"
                        fullWidth
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                        label="Новый пароль ещё раз"
                        type="password"
                        autoComplete="new-password"
                    />
                </>
            )}
            <Button
                type="submit"
                fullWidth
                className={classes.submit}
                disabled={isBtnDisabled}
                startIcon={processing && <CircularProgress size={20} />}
            >
                {isCodeSent ? 'Сменить пароль' : 'Получить код'}
            </Button>
            {/* @ts-ignore */}
            {mainError && <Alert severity="error">{mainError}</Alert>}
        </form>
    );
}

export { ReminderForm };

import { FC, useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';

import { Button, LinearProgress, IconButton, Tooltip, Stack } from '@mui/material';
import { FindInPage } from '@mui/icons-material';

import { DialogData } from '../../../typings/table';
import { getManufacture, updateManufacture, createManufacture } from '../../../services/manufacture';

import { Dialog, DialogProps } from '../../../components/Dialog/Dialog';
import { ConfirmDialog } from '../../../components/Dialog/withConfirm/DialogWithConfirm';
import { NewForm, InPlanForm, InProgressForm } from '../Forms';
import { InputText } from '../../../components/Inputs';

import { newSchema, inPlanSchema, inProgressSchema, cancelSchema } from '../validateSchemas';

enum Status {
    InPlan = 1,
    InProgress = 2,
    Finished = 3,
    Canceled = 4,
}

interface EditDialogProps extends DialogProps {
    loading?: boolean;
    disableEdit?: boolean;
    id?: string;
    itemPageLink?: string;
    onConfirm: () => void;
    onError: (errors: any) => void;
    primaryKeyField?: string;
}

const initialValues = {
    manufactured_at: new Date(),
    stock_id: '',
    product_id: '',
    comments: '',
    quantity: 1,
    components: [],
    quantity_type: 1,
};

const getValidateSchema = (statusId?: number) => {
    if (statusId === Status.InPlan) {
        return inPlanSchema;
    }

    if (statusId === Status.InProgress) {
        return inProgressSchema;
    }

    return newSchema;
};

export const DialogWithEdit: FC<EditDialogProps> = ({
    onConfirm,
    onClose,
    loading,
    children,
    itemPageLink,
    id,
    disableEdit,
    onError,
    ...props
}) => {
    const [current, setCurrent] = useState<DialogData>(null);
    const [currentStatusId, setCurrentStatusId] = useState<number>();
    const [needConfirmation, setNeedConfirmation] = useState(false);

    useEffect(() => {
        if (id) {
            getManufacture(id).then((data) => {
                setCurrent(data.data);

                setCurrentStatusId(data.data.status_id);
            });
        }
    }, [id]);

    const currentProduct = current?.product_name;
    const currentSKU = current?.product_sku || '';
    const currentStatus = current?.status_name;
    const currentTitle = currentProduct ? `${currentSKU} – ${currentProduct} (${currentStatus})` : 'Планирование';

    const onSubmitForm = (values: any, id?: string) => {
        if (id) {
            return updateManufacture(id, values);
        }

        return createManufacture(values);
    };

    const editFormConfig = useFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validationSchema: getValidateSchema(currentStatusId),
        initialValues: {
            ...(current || initialValues),
            quantity_type: 1,
            write_off: {
                comments: '',
            },
            invoice: '',
            manufacture_price: '',
        },
        onSubmit: (values: any, { resetForm }) => {
            if (!values.write_off?.comments && !values.write_off?.quantity) {
                delete values.write_off;
            }

            return onSubmitForm(values, id).then((data: any) => {
                if (data?.errors) {
                    onError(data.errors);
                } else {
                    onConfirm();
                    onClose?.();
                    resetForm();
                }
            });
        },
    });

    const { setFieldValue, handleSubmit, validateForm } = editFormConfig;

    const handleInProgress = useCallback(() => {
        setFieldValue('status_id', Status.InProgress).then(() => {
            handleSubmit();
        });
    }, [setFieldValue, handleSubmit]);

    const handleSave = useCallback(() => {
        handleSubmit();
    }, [handleSubmit]);

    const handleFinished = useCallback(() => {
        setFieldValue('status_id', Status.Finished).then(() => {
            handleSubmit();
        });
    }, [setFieldValue, handleSubmit]);

    const handleCancelClick = useCallback(() => {
        setNeedConfirmation(true);
    }, []);

    const handleCancel = useCallback(
        (commentsCancel: string) => {
            setFieldValue('comments', commentsCancel).then(() => {
                setFieldValue('status_id', Status.Canceled).then(() => {
                    validateForm().then((data: any) => console.log('>>>>', data));
                    handleSubmit();
                });
            });
        },
        [setFieldValue, handleSubmit, validateForm],
    );

    const cancelFormConfig = useFormik({
        enableReinitialize: true,
        validateOnChange: false,
        initialValues: {
            comments_cancel: '',
        },
        validationSchema: cancelSchema,
        onSubmit: (values: any, { resetForm }) => {
            handleCancel(values.comments_cancel);
            handleCloseCancelDialog();
            resetForm();
        },
    });

    const { handleSubmit: handleCancelSubmit, handleReset: handleCancelReset } = cancelFormConfig;

    const handleCloseCancelDialog = useCallback(() => {
        setNeedConfirmation(false);
        handleCancelReset(null);
    }, [handleCancelReset]);

    const watchButon =
        itemPageLink && id ? (
            <Tooltip title="Страница с детальной информацией" arrow placement="top">
                <Link to={`${itemPageLink}/${id}`} target="_blank">
                    <IconButton>
                        <FindInPage fontSize="small" />
                    </IconButton>
                </Link>
            </Tooltip>
        ) : undefined;

    const actions = (
        <Stack direction="row" spacing={1}>
            {watchButon}
            {!currentStatusId && (
                <Button type="submit" variant="contained" onClick={handleSave} disabled={loading}>
                    Добавить в план
                </Button>
            )}
            {currentStatusId === Status.InPlan && (
                <Button type="submit" variant="contained" color="success" onClick={handleInProgress} disabled={loading}>
                    Принять в работу
                </Button>
            )}
            {currentStatusId === Status.InPlan && (
                <Button type="submit" variant="contained" onClick={handleSave} disabled={loading}>
                    Сохранить
                </Button>
            )}
            {currentStatusId === Status.InProgress && (
                <Button type="submit" variant="contained" onClick={handleFinished} disabled={loading}>
                    Завершить
                </Button>
            )}
            {!!currentStatusId && currentStatusId !== Status.Finished && currentStatusId !== Status.Canceled && (
                <FormikProvider value={cancelFormConfig}>
                    <ConfirmDialog
                        open={needConfirmation}
                        title="Отмена производства"
                        message="Вы уверены, что хотите отменить производство?"
                        onConfirm={handleCancelSubmit}
                        onClose={handleCloseCancelDialog}
                        confirmText="Подтвердить отмену производства"
                        cancelText="Вернуться"
                        confirmColor="error"
                    >
                        <form>
                            <InputText name="comments_cancel" rows={3} multiline label="Причина отмены" fullWidth />
                        </form>
                    </ConfirmDialog>
                    <Button type="submit" onClick={handleCancelClick} variant="contained" color="error">
                        Отменить
                    </Button>
                </FormikProvider>
            )}
            <Button onClick={onClose} variant="text">
                Закрыть
            </Button>
        </Stack>
    );

    return (
        <FormikProvider value={editFormConfig}>
            <Dialog
                actions={actions}
                title={currentTitle}
                maxWidth={currentStatusId ? 'lg' : 'md'}
                fullWidth={!!currentStatusId}
                {...props}
                TransitionProps={{
                    onExited: () => {
                        editFormConfig.resetForm();
                        setCurrent(null);
                        setCurrentStatusId(undefined);
                    },
                }}
            >
                {!currentStatusId && <NewForm />}
                {currentStatusId === Status.InPlan && <InPlanForm />}
                {currentStatusId === Status.InProgress && <InProgressForm />}
                {loading && <LinearProgress />}
            </Dialog>
        </FormikProvider>
    );
};

import React, { FC, useCallback } from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CommonTablePartProps } from '../typings';

import { CellWithCheckbox } from '../CellWithCheckbox/CellWithCheckbox';

import { ColumnProps } from '../../../typings/table';

interface TableHeadProps extends CommonTablePartProps {
    orderBy?: string;
    direction?: 'asc' | 'desc';
    changeOrder?: (column: ColumnProps) => void;
    onSelectAll?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isAllSelected?: boolean;
    canSelect?: boolean;
    withEditButton?: boolean;
}

const useStyles = makeStyles((theme) => ({
    headItem: {
        color: '#333',
        fontWeight: 'bold',
    },
    sortMarker: {
        color: 'rgb(235, 110, 56) !important',
    },
}));

export const ModuleTableHead: FC<TableHeadProps> = ({
    columns = [],
    orderBy,
    direction,
    changeOrder,
    onSelectAll,
    isAllSelected = false,
    canSelect,
    withEditButton,
}) => {
    const classes = useStyles();

    const createSortHandler = useCallback(
        (column: ColumnProps) => {
            return (event: React.MouseEvent) => {
                changeOrder?.(column);
            };
        },
        [changeOrder],
    );

    return (
        <TableHead>
            <TableRow>
                {columns.map((column, index) => {
                    const isImage = column.type === 'img';

                    return (
                        <TableCell
                            key={column.field || index}
                            className={classes.headItem}
                            width={column.width}
                            align={isImage ? 'center' : 'left'}
                        >
                            {column.sortable ? (
                                <TableSortLabel
                                    className={classes.headItem}
                                    classes={{
                                        icon: classes.sortMarker,
                                    }}
                                    active={orderBy === column.field}
                                    direction={orderBy === column.field ? direction : 'asc'}
                                    onClick={createSortHandler(column)}
                                >
                                    {column.title}
                                </TableSortLabel>
                            ) : (
                                column.title
                            )}
                        </TableCell>
                    );
                })}
                {withEditButton ? <TableCell width="40" style={{ padding: 0 }} /> : null}
                <CellWithCheckbox enable={canSelect} isSelected={isAllSelected} onChange={onSelectAll} />
            </TableRow>
        </TableHead>
    );
};

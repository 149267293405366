import { FC } from 'react';
import * as yup from 'yup';

import { PageWithTable } from '../../components/Page/withTable/PageWithTable';

import { FormProps } from '../../typings/table';
import { Checkbox, InputText } from '../../components/Inputs';
import { FormikValues, useFormikContext } from 'formik';
import { categoriesStore } from '../../store/CategoriesStore';
import { Box } from '@mui/material';

interface CategoriesPageProps {
    title?: string;
}

const validationSchema = yup.object({
    name: yup.string().required('Наименование обязательно'),
    code: yup.string().required('Код обязателен').length(2, 'Код должен быть 2 символа'),
});

const initialValues = {
    name: '',
};

const EditForm: FC<FormProps> = () => {
    const { errors, handleChange } = useFormikContext<FormikValues>();

    return (
        <form>
            <InputText name="name" id="name" label="Наименование" style={{ minWidth: 300 }} fullWidth required />
            <InputText
                name="code"
                id="code"
                label="Код"
                style={{ minWidth: 50 }}
                required
                helperText={errors.code ? String(errors.code) : 'Допустимые символы: A-Z, 0-9'}
            />
            <Box>
                <Checkbox
                    id="is_manufacture"
                    name="is_manufacture"
                    onChange={handleChange}
                    color="primary"
                    label="Производится"
                />
                <Checkbox
                    id="is_ready"
                    name="is_ready"
                    onChange={handleChange}
                    color="primary"
                    label="Готовая продукция"
                />
            </Box>
        </form>
    );
};

export const CategoriesPage: FC<CategoriesPageProps> = ({ title }) => {
    return (
        <>
            <PageWithTable
                title={title}
                store={categoriesStore}
                EditForm={EditForm}
                validationSchema={validationSchema}
                initialValues={initialValues}
                disableColumnsPanel
            />
        </>
    );
};

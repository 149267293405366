import { ChangeEvent, FC } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

import { Checkbox } from '../Inputs';

import { ColumnProps } from '../../typings/table';
import { FormikProvider, useFormik } from 'formik';

interface ColumnsPanelProps {
    columns: Record<string, any>;
    onChange: (columnName: string) => void;
    isVisble: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: grey[50],
        transition: theme.transitions.create('all', {
            duration: theme.transitions.duration.standard,
        }),
        maxHeight: 0,
        maxWidth: '100%',
        opacity: 0,
        padding: '0 24px',
        pointerEvents: 'none',
    },
    isOpen: {
        maxHeight: '600px',
        marginBottom: 24,
        padding: 24,
        opacity: 1,
        pointerEvents: 'auto',
    },
}));

export const ColumnsPanel: FC<ColumnsPanelProps> = observer(({ columns, isVisble, onChange }) => {
    const classes = useStyles();

    const formik = useFormik({ initialValues: {}, onSubmit: () => {} });

    return (
        <FormikProvider value={formik}>
            <Paper
                className={clsx(
                    {
                        [classes.isOpen]: isVisble,
                    },
                    classes.root,
                )}
            >
                {columns.map((column: ColumnProps) => {
                    const handleChange = (e: ChangeEvent) => {
                        return onChange(column.field);
                    };

                    if (column.disable) {
                        return null;
                    }

                    return (
                        <Checkbox
                            key={column.field}
                            label={column.title}
                            name={column.field}
                            checked={!column.hidden}
                            onChange={handleChange}
                        />
                    );
                })}
            </Paper>
        </FormikProvider>
    );
});

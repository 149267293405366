import { FC, useCallback, useMemo } from 'react';
import { TableRow, IconButton, TableCell } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { orange, blue } from '@mui/material/colors';

import { CommonTablePartProps } from '../typings';
import { CellWithCheckbox } from '../CellWithCheckbox/CellWithCheckbox';
import { CellConfigurator } from '../Cell';

import { TableItem } from '../../../typings/table';

interface TableBodyProps extends CommonTablePartProps {
    data: TableItem[];
    onRowClick?: (data: TableItem) => void;
    onCellClick?: (data: TableItem, field?: string) => void;
    handleEdit?: (data: TableItem) => void;
    canSelect?: boolean;
    withEditButton?: boolean;
    justOpenItem?: boolean;
    primaryKeyField?: string;
    getRecordKey?: (record: Record<string, any>) => string;
}

const useStyles = makeStyles(() => ({
    photo: {
        margin: 'auto',
    },
    row: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: orange[100],
        },
    },
    cell: {
        borderRight: '1px solid #f5f5f5',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: blue[100],
        },
    },
    cellBase: {
        borderRight: '1px solid #f5f5f5',
    },
}));

export const TableBaseContent: FC<TableBodyProps> = ({
    columns = [],
    data = [],
    changeSelected = () => {},
    isSelected = () => false,
    onRowClick,
    onCellClick,
    handleEdit = () => {},
    canSelect,
    withEditButton,
    justOpenItem,
    primaryKeyField = 'id',
    getRecordKey,
}) => {
    const classes = useStyles();

    const createSelectRow = useCallback(
        (id: string | number) => {
            return () => {
                changeSelected(String(id));
            };
        },
        [changeSelected],
    );

    const createClickRow = useCallback(
        (data: TableItem) => {
            return () => {
                onRowClick?.(data);
            };
        },
        [onRowClick],
    );

    const createClickCell = useCallback(
        (data: TableItem, field: string) => {
            return () => {
                onCellClick?.(data, field);
            };
        },
        [onCellClick],
    );

    const createHandleEdit = useCallback(
        (data: TableItem) => {
            return (event: any) => {
                event.stopPropagation();

                handleEdit(data);
            };
        },
        [handleEdit],
    );

    const getRow = useCallback(
        (record: any, group_field?: string) => {
            const getRecordId = (record: Record<string, any>) => record?.[primaryKeyField];

            const recordId = getRecordId(record);
            const recordKey = getRecordKey ? getRecordKey(record) : recordId;
            const isItemSelected = isSelected(String(recordId));

            const handleItemClick = createClickRow(record);
            const handleItemEdit = createHandleEdit(record);

            return (
                <TableRow
                    className={onCellClick ? undefined : classes.row}
                    key={recordKey}
                    selected={isItemSelected}
                    onClick={(withEditButton || justOpenItem) && !onCellClick ? handleItemClick : handleItemEdit}
                >
                    {columns
                        .filter((column) => column.field !== group_field)
                        .map((column) => {
                            const field = (column.field as string) || '';
                            // TODO: костыль только для Состояния Вкладов
                            const founded = record.stocks?.find((stock: any) => column.title === stock.stock_name);

                            const handleCellClick =
                                onCellClick && justOpenItem && Number(founded?.balance) > 0
                                    ? createClickCell(record, founded.stock_id)
                                    : undefined;

                            return (
                                <CellConfigurator
                                    className={handleCellClick ? classes.cell : classes.cellBase}
                                    key={recordId + field}
                                    value={record[field]}
                                    type={column.type}
                                    item={record}
                                    column={column}
                                    onClick={handleCellClick}
                                    sx={{ maxWidth: 300 }}
                                    {...column.dataParams}
                                />
                            );
                        })}
                    {withEditButton ? (
                        <TableCell style={{ padding: 0 }}>
                            <IconButton onClick={handleItemEdit} size="small">
                                <Edit fontSize="small" />
                            </IconButton>
                        </TableCell>
                    ) : null}
                    <CellWithCheckbox
                        enable={canSelect}
                        isSelected={isItemSelected}
                        onChange={createSelectRow(recordId)}
                    />
                </TableRow>
            );
        },
        [
            isSelected,
            primaryKeyField,
            createClickRow,
            createHandleEdit,
            classes.row,
            classes.cell,
            classes.cellBase,
            withEditButton,
            justOpenItem,
            onCellClick,
            columns,
            canSelect,
            createSelectRow,
            getRecordKey,
            createClickCell,
        ],
    );

    const items = useMemo(() => {
        return data.map((item: TableItem) => {
            if (item.records) {
                return (
                    <>
                        <TableRow className={onCellClick ? undefined : classes.row}>
                            <TableCell rowSpan={item.records.length + 1}>{item.group_name}</TableCell>
                        </TableRow>
                        {item.records.map((record: any) => getRow(record, item.group_field))}
                    </>
                );
            }

            return getRow(item);
        });
    }, [data, getRow, onCellClick, classes.row]);

    return <>{items}</>;
};

import React, { FC } from 'react';

import { DialogProps as MaterialDialogProps } from '@mui/material';

import {
    Alert,
    Dialog as DialogMaterial,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    alert: {
        whiteSpace: 'pre-wrap',
    },
}));

export interface DialogProps extends MaterialDialogProps {
    title?: string;
    message?: string;
    actions?: React.ReactElement;
    titleContent?: React.ReactElement;
    error?: string;
    onClose?: () => void;
}

export const Dialog: FC<DialogProps> = ({
    title,
    titleContent,
    message,
    open = false,
    actions,
    onClose,
    error,
    children,
    ...props
}) => {
    const classes = useStyles();
    return (
        <DialogMaterial open={open} onClose={onClose} {...props} style={{ marginTop: 50 }}>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>{title}</div>
                <div>{titleContent}</div>
            </DialogTitle>
            <DialogContent>
                {message && <DialogContentText>{message}</DialogContentText>}
                {children}
            </DialogContent>
            {error && (
                <Alert severity="error" className={classes.alert}>
                    {error}
                </Alert>
            )}
            <DialogActions>{actions}</DialogActions>
        </DialogMaterial>
    );
};

import { observable, configure } from 'mobx';
import { TableStoreBase, TableData } from './BaseTableStore';

import { getProducts, createProduct, removeProducts, updateProduct } from '../services/products';
import { ColumnProps } from '../typings/table';

configure({ enforceActions: 'observed' });

export interface ProductProps {
    [key: string]: any;
    id: number | string;
    first_name?: string;
    patronymic_name?: string;
    last_name?: string;
    phone: string;
    email: string;
    job: string;
    photo?: string;
}

export interface IProducts extends TableData {
    items?: ProductProps[];
}

export class ProductsStore extends TableStoreBase {
    constructor() {
        super('products');

        this.filterParams.order = 'name';
        this.filterParams.orderDir = 'asc';
        this.itemPageLink = '/dashboard/product';

        this.getData = getProducts;
        this.removeItem = removeProducts;
        this.updateItem = updateProduct;
        this.createItem = createProduct;
    }

    @observable columns: ColumnProps[] = [
        {
            title: 'Фотография',
            field: 'photo',
            type: 'photo',
        },
        { field: 'category_name', title: 'Категория', sortable: true },
        { field: 'sku', title: 'УИП', sortable: true },
        { field: 'name', title: 'Название', sortable: true },
        { field: 'measure_name', title: 'Единица учета', sortable: true },
        {
            title: 'Поставщик(и)',
            field: 'suppliers',
            type: 'suppliers',
        },
        {
            title: 'Состав',
            field: 'components',
            type: 'components',
        },
    ];

    @observable data: IProducts = {};
}

export const productsStore = new ProductsStore();

import { FC, useRef, useMemo } from 'react';
import clsx from 'clsx';
import { FormikValues, FieldArray, useFormikContext } from 'formik';
import { Paper, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DividerWithTitle } from '../../../components/DividerWithTitle';
import { SelectWithData, InputNumber } from '../../../components/Inputs';
import { ButtonWithRemove } from '../../../components/Button/withRemove/ButtonWithRemove';

import { TableItem } from '../../../typings/table';

const useStyles = makeStyles(() => ({
    title: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 18,
    },
    titleIcon: {
        marginLeft: 4,
        width: 18,
        height: 18,
    },
    contentBase: {
        margin: '12px 0',
        maxHeight: '430px',
        overflow: 'auto',
        position: 'relative',
        borderRadius: 6,
        backgroundColor: '#f5f5f5',
    },
    contentWithItems: {
        padding: 12,
        border: '1px solid rgb(200, 200, 200)',
    },
    itemCard: {
        padding: '24px 12px',
        margin: '12px 0',
    },
    list: {
        padding: 0,
    },
    menuItem: {
        display: 'block',
    },
    listItem: {
        display: 'block',
        paddingTop: 4,
        paddingBottom: 4,
    },
    listTitle: {
        fontWeight: 'bold',
        marginRight: 4,
    },
}));

interface StockFromProps {
    index: number;
}

const StockForm: FC<StockFromProps> = ({ index }) => {
    const { values, setFieldValue } = useFormikContext<FormikValues>();
    const { measure_name } = values;

    const stocksFilter = useMemo(
        () => ({
            order: 'is_ready_default',
            orderDir: 'desc',
            is_manufacture: 0,
        }),
        [],
    );

    return (
        <>
            <SelectWithData
                name={`transactions.${index}.stock_id`}
                type="stocks"
                label="Склад"
                filterParams={stocksFilter}
                order="is_ready_default"
                orderDir="desc"
                selectFirst
                fullWidth
            />
            <InputNumber
                name={`transactions.${index}.quantity`}
                label="Количество"
                measure={measure_name}
                isShort
                fullWidth
                withoutRound
                required
            />
            <Button
                color="inherit"
                onClick={() => {
                    // @ts-ignore
                    setFieldValue(`transactions.${index}.quantity`, values.quantity || 0);
                }}
            >
                Указать всё количество
            </Button>
        </>
    );
};

// Распределение по складам после производства
export const StocksForm: FC = () => {
    const contentRef = useRef<HTMLDivElement>(null);
    const classes = useStyles();

    const { values } = useFormikContext<FormikValues>();

    return (
        <>
            <FieldArray name="transactions">
                {({ remove, push }) => {
                    return (
                        <>
                            <div
                                className={clsx(
                                    values.transactions?.length ? classes.contentWithItems : '',
                                    classes.contentBase,
                                )}
                                ref={contentRef}
                            >
                                {(values.transactions || []).map((stock: TableItem, index: number) => {
                                    return (
                                        <Paper elevation={3} key={stock.id} className={classes.itemCard}>
                                            <DividerWithTitle>
                                                {index + 1}{' '}
                                                <ButtonWithRemove buttonText="" onRemove={() => remove(index)} />
                                            </DividerWithTitle>
                                            <StockForm index={index} />
                                        </Paper>
                                    );
                                })}
                            </div>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    push({ isEdit: true });
                                    setTimeout(
                                        () => contentRef?.current?.scrollTo({ top: contentRef?.current?.scrollHeight }),
                                        100,
                                    );
                                }}
                            >
                                Добавить новый склад
                            </Button>
                        </>
                    );
                }}
            </FieldArray>
        </>
    );
};

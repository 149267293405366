import { observable, configure } from 'mobx';
import { TableStoreBase, TableData } from './BaseTableStore';

import { getStocks, createStock, updateStock, removeStock } from '../services/stocks';
import { ColumnProps } from '../typings/table';
configure({ enforceActions: 'observed' });

export interface StockProps {
    [key: string]: any;
    name: string;
}

export interface StocksDataProps extends TableData {
    items?: StockProps[];
}

export class StocksStore extends TableStoreBase {
    constructor() {
        super('stocks');

        this.itemPageLink = '/dashboard/stock';

        this.getData = getStocks;
        this.removeItem = removeStock;
        this.updateItem = updateStock;
        this.createItem = createStock;
    }

    protected CAN_NOT_REMOVE =
        'Нельзя удалить некоторые склады, так как они не пустые или участвовавали в транзакциях.';

    @observable data: StocksDataProps = {};

    @observable columns: ColumnProps[] = [
        { field: 'name', title: 'Наименование', sortable: true, width: '30%' },
        { field: 'description', title: 'Комментарий', width: '70%' },
        {
            field: 'is_manufacture_default',
            title: '',
            type: 'boolean',
            dataParams: {
                trueProps: {
                    borderRadius: 6,
                    backgroundColor: 'rgba(10, 30, 230, .1)',
                    border: '0',
                    color: '#23d',
                    fontWeight: 600,
                },
                trueValue: 'Для производства',
                falseValue: '',
            },
        },
        {
            field: 'is_ready_default',
            title: '',
            type: 'boolean',
            dataParams: {
                trueProps: {
                    borderRadius: 6,
                    backgroundColor: 'rgba(20, 130, 30, .1)',
                    border: '0',
                    color: '#3a3',
                    fontWeight: 600,
                },
                trueValue: 'Для готовой продукции',
                falseValue: '',
            },
        },
        {
            field: 'is_contractor',
            title: '',
            type: 'boolean',
            dataParams: {
                trueProps: {
                    borderRadius: 6,
                    backgroundColor: 'rgba(10, 130, 130, .1)',
                    border: '0',
                    color: '#29a',
                    fontWeight: 600,
                },
                trueValue: 'Подрядчик',
                falseValue: '',
            },
        },
    ];

    @observable pageLimit: number = 1;
}

export const stocksStore = new StocksStore();
